import React, {ChangeEvent, useEffect, useState} from 'react'
import styled from 'styled-components'
import AddressWithButton from "../signup/component/AddressWithButton"
import SignupModal from "../signup/component/SignupModal"
import {FormProvider, useForm} from "react-hook-form"
import {SignUpRequest} from "../../api/signup/signUp"
import {
    RequiredRulesAndValidationBusinessNumber,
    RequiredRulesAndValidationTelNumber2,
    RequiredRulesWithoutValidation
} from "../../components/form/BaseFormType"
import IdCheck from "../signup/component/IdCheck"
import {ErrorInput} from "../../components/style/input/ErrorInput";
import FixedWeightText from "../../components/style/text/FixedWeightText";
import Input from "../../components/style/input/Input";
import Button from "../../components/style/button/Button";
import {useHistory} from 'react-router-dom'
import {LINK_HOME} from "../../resources/LinkConstants";
import {RowDiv} from "../signup/component/RowDiv"
import Select from "../../components/style/select/Select";
import BasicCheckBox from "../../components/style/button/BasicCheckBox";
import {SignUpForm} from "../../api/signup/getSignUpForm";
import RedDot from "../../components/style/input/RedDot";
import useNumber from "../../hooks/useNumber";
import {Member} from "../../api/member/Member";
import {changeHeaderTitle} from "../../redux/HeaderTitleModule";
import {useDispatch} from "react-redux";
import {InfoHeadText} from "../../components/style/text/InfoHeadText";
import MatchHorizontalSpace from "../../components/MatchHorizontalSpace";
import {InputTitle} from "../../components/style/text/InputTitle";
import {ItemDiv} from "../../components/style/div/ItemDiv";
import {InfoDiv} from "../../components/style/div/InfoDiv";
import ThinCheckBox from "../../components/style/button/ThinCheckBox";

interface UserUpdateScreenComponentProps {
    form? : SignUpForm
    isUpdate? : Member
    updateMember: (...payload1: any[]) => Promise<boolean | undefined>
}

type SignUpScreenData = SignUpRequest & {
    passwordConfirm?: string
}

function UserUpdateScreen(props: UserUpdateScreenComponentProps) {
    const methods = useForm<SignUpScreenData>({
        mode: "onBlur",
        criteriaMode: 'all',
    })

    const history = useHistory()
    const dispatch = useDispatch()
    const {watch} = methods
    const {password} = watch()
    const {isValid} = methods.formState

    const [request, setRequest] = useState<SignUpScreenData>()
    const [idChecked, setIdChecked] = useState(false)
    const [showModal, setShowModal] = useState(false)

    const [check, setCheck] = useState(false)
    const [isReadOnly, setIsReadOnly] = useState(true)

    function onSubmit(data: SignUpScreenData){


        removeEmptyField(data)
        setRequest(data)

        console.log(data)
        if(props.isUpdate){
            dispatch(changeHeaderTitle({userName: data.companyName}))
            props.updateMember(data)
        } else {
            setShowModal(true)
        }




    }

    function removeEmptyField(data : any) {
        Object.keys(data).forEach(key => {
            if(data[key] instanceof Object) {
                const emptyObjectCandidateKeys = Object.keys(data[key])
                for(let i=0; i<emptyObjectCandidateKeys.length; i++) {
                    if (data[key][emptyObjectCandidateKeys[i]] === '' || data[key][emptyObjectCandidateKeys[i]] == null) {
                        delete data[key]
                        break
                    }
                }
            }
        });

        console.log(data, "after")
    }

    function handleCheck(event: React.ChangeEvent<HTMLInputElement>){
        setCheck(event.target.checked)

        if(event.target.checked){
            methods.setValue("deliveryAddress", methods.watch().address,{
                shouldValidate : true
            })
            methods.setValue("deliveryAddressDetail", methods.watch().addressDetail)
        }
    }



    function handleDomain(event: ChangeEvent<HTMLInputElement>){
        methods.setValue("email.domain", event.target.value, {
            shouldValidate : true
        })
    }

    useEffect(()=>{
        methods.setValue("email.domain", "naver.com", {
            shouldValidate : true
        })
    },[])

    useNumber(methods, "businessNumber")
    useNumber(methods, "tel.number1")
    useNumber(methods, "tel.number2")
    useNumber(methods, "mobile1.number1")
    useNumber(methods, "mobile1.number2")
    useNumber(methods, "mobile2.number1")
    useNumber(methods, "mobile2.number2")
    useNumber(methods, "mobile3.number1")
    useNumber(methods, "mobile3.number2")
    useNumber(methods, "fax.number1")
    useNumber(methods, "fax.number2")



    useEffect(() => {
        if(props.isUpdate){
            console.log("props isupdate" , props.isUpdate)
            setIdChecked(true)
            methods.setValue("userId",props.isUpdate.userId,{shouldValidate: true})
            methods.setValue("companyName",props.isUpdate.companyName,{shouldValidate: true})
            methods.setValue("businessNumber",props.isUpdate.businessNumber,{shouldValidate: true})
            methods.setValue("ownerName",props.isUpdate.ownerName,{shouldValidate: true})
            methods.setValue("tel.localNumber",props.isUpdate.tel.localNumber,{shouldValidate: true})
            methods.setValue("tel.number1",props.isUpdate.tel.number1,{shouldValidate: true})
            methods.setValue("tel.number2",props.isUpdate.tel.number2,{shouldValidate: true})
            methods.setValue("mobile1.name",props.isUpdate.mobile1.name,{shouldValidate: true})
            methods.setValue("mobile1.carrier",props.isUpdate.mobile1.carrier,{shouldValidate: true})
            methods.setValue("mobile1.number1",props.isUpdate.mobile1.number1,{shouldValidate: true})
            methods.setValue("mobile1.number2",props.isUpdate.mobile1.number2,{shouldValidate: true})
            methods.setValue("mobile2.name",props.isUpdate.mobile2?.name,{shouldValidate: true})
            methods.setValue("mobile2.carrier",props.isUpdate.mobile2?.carrier ? props.isUpdate.mobile2?.carrier : "",{shouldValidate: true})
            methods.setValue("mobile2.number1",props.isUpdate.mobile2?.number1,{shouldValidate: true})
            methods.setValue("mobile2.number2",props.isUpdate.mobile2?.number2,{shouldValidate: true})
            methods.setValue("mobile3.name",props.isUpdate.mobile3?.name,{shouldValidate: true})
            methods.setValue("mobile3.carrier",props.isUpdate.mobile3?.carrier ? props.isUpdate.mobile3?.carrier : "",{shouldValidate: true})
            methods.setValue("mobile3.number1",props.isUpdate.mobile3?.number1,{shouldValidate: true})
            methods.setValue("mobile3.number2",props.isUpdate.mobile3?.number2,{shouldValidate: true})
            methods.setValue("fax.localNumber",props.isUpdate.fax?.localNumber,{shouldValidate: true})
            methods.setValue("fax.number1",props.isUpdate.fax?.number1,{shouldValidate: true})
            methods.setValue("fax.number2",props.isUpdate.fax?.number2,{shouldValidate: true})
            methods.setValue("email.name",props.isUpdate.email.name, {shouldValidate: true})
            methods.setValue("email.email",props.isUpdate.email.email, {shouldValidate: true})
            methods.setValue("email.domain",props.isUpdate.email.domain, {shouldValidate: true})
            methods.setValue("domain",props.form?.memberEmailDomainType.find(type => type.title === props.isUpdate?.email.domain)?.id,{shouldValidate: true})
            methods.setValue("address",props.isUpdate.address, {shouldValidate: true})
            methods.setValue("addressDetail",props.isUpdate.addressDetail ? props.isUpdate.addressDetail : "",{shouldValidate: true})
            methods.setValue("deliveryAddress",props.isUpdate.deliveryAddress, {shouldValidate: true})
            methods.setValue("deliveryAddressDetail",props.isUpdate.deliveryAddressDetail ? props.isUpdate.deliveryAddressDetail : "",{shouldValidate: true})




        }
    }, [props.isUpdate])

    useEffect(()=>{
        if(watch("domain")){
            const number = watch("domain")
            const domain = props.form?.memberEmailDomainType.filter(it => it.id === number)
            if(domain){
                if(domain[0].title == "직접입력"){
                    setIsReadOnly(false)
                } else {
                    setIsReadOnly(true)
                    methods.setValue("email.domain", domain[0].title)
                }
            }
        }
    },[watch("domain")])

    function onNextButtonClick(){
        console.log(isValid)
        if(isValid){
            setShowModal(true)
            console.log(showModal)
        } else {
            alert(isValid)
        }
    }

    return (
        <FormProvider {...methods} >
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <CenterDiv>
                    <InfoDiv>
                        <InfoHeadText>계정정보</InfoHeadText>
                        <MatchHorizontalSpace marginBottom={"1.875rem"}/>

                        <ItemDiv>
                            <InputTitle>아이디<RedDot/></InputTitle>
                            <InputField
                                name="userId"
                                ref={methods.register({
                                    required: true
                                })}
                                readOnly={true}
                            />
                        </ItemDiv>
                    </InfoDiv>

                    <InfoDiv>
                        <InfoHeadText>기본정보</InfoHeadText>
                        <MatchHorizontalSpace marginBottom={"1.875rem"}/>

                        <ItemDiv>
                            <InputTitle>회사명<RedDot/></InputTitle>
                            <InputField
                                name="companyName"
                                ref={methods.register(RequiredRulesWithoutValidation)}
                            />
                        </ItemDiv>

                        <ItemDiv>
                            <InputTitle>사업자번호<RedDot/></InputTitle>
                            <InputField
                                name="businessNumber"
                                ref={methods.register(RequiredRulesAndValidationBusinessNumber)}
                                error={methods.errors.businessNumber?.types?.minLength === true || methods.errors.businessNumber?.types?.maxLength === true}
                                maxLength={10}
                            />

                            <GuideText error={methods.errors.businessNumber?.types?.pattern === true}>
                                (-)을 제외하고 입력해주세요.
                            </GuideText>
                        </ItemDiv>

                        <ItemDiv>
                            <InputTitle>대표자명<RedDot/></InputTitle>
                            <InputField
                                name="ownerName"
                                ref={methods.register(RequiredRulesWithoutValidation)}
                            />
                        </ItemDiv>

                        <ItemDiv>
                            <InputTitle>회사 전화번호<RedDot/></InputTitle>
                            <RowDiv>
                                <TelSelectBox
                                    menuItems={props.form?.memberTelLocalType}
                                    defaultValue={1}
                                    name={"tel.localNumber"}
                                    reference={methods.register({required: true})}
                                />
                                <TelField
                                    name={"tel.number1"}
                                    ref={methods.register({required: true})}
                                    maxLength={4}
                                />
                                <TelField
                                    name={"tel.number2"}
                                    ref={methods.register({required: true})}
                                    maxLength={4}
                                />
                            </RowDiv>
                        </ItemDiv>

                        <ItemDiv>

                            <InputTitle>핸드폰 번호<RedDot/></InputTitle>

                            <GuideText marginTop={"0"}>
                                sms 연락받을번호(최소 1개 입력)
                            </GuideText>

                            <Mobile>
                                <NameAndBodyInputField
                                    name="mobile1.name"
                                    ref={methods.register(RequiredRulesWithoutValidation)}
                                    placeholder={"담당자명"}
                                />
                                <MobileSelectBox
                                    menuItems={props.form?.memberPhoneCarrierType}
                                    defaultValue={1}
                                    name={"mobile1.carrier"}
                                    reference={methods.register}
                                />
                                <MobileField
                                    name={"mobile1.number1"}
                                    ref={methods.register(RequiredRulesAndValidationTelNumber2)}
                                    maxLength={4}
                                />
                                <MobileField
                                    name={"mobile1.number2"}
                                    ref={methods.register(RequiredRulesAndValidationTelNumber2)}
                                    maxLength={4}
                                />
                            </Mobile>

                            <Mobile>
                                <NameAndBodyInputField
                                    name={"mobile2.name"}
                                    ref={methods.register}
                                    placeholder={"담당자명"}
                                />
                                <MobileSelectBox
                                    menuItems={props.form?.memberPhoneCarrierType}
                                    defaultValue={1}
                                    name={"mobile2.carrier"}
                                    reference={methods.register}
                                />
                                <MobileField
                                    name={"mobile2.number1"}
                                    ref={methods.register}
                                    maxLength={4}
                                />
                                <MobileField
                                    name={"mobile2.number2"}
                                    ref={methods.register}
                                    maxLength={4}
                                />
                            </Mobile>

                            <Mobile>
                                <NameAndBodyInputField
                                    name={"mobile3.name"}
                                    ref={methods.register}
                                    placeholder={"담당자명"}

                                />
                                <MobileSelectBox
                                    menuItems={props.form?.memberPhoneCarrierType}
                                    defaultValue={1}
                                    name={"mobile3.carrier"}
                                    reference={methods.register}
                                />

                                <MobileField
                                    name="mobile3.number1"
                                    ref={methods.register}
                                    maxLength={4}
                                />
                                <MobileField
                                    name="mobile3.number2"
                                    ref={methods.register}
                                    maxLength={4}
                                />
                            </Mobile>
                        </ItemDiv>


                        <ItemDiv>
                            <InputTitle>팩스번호</InputTitle>

                            <RowDiv>
                                <TelSelectBox
                                    menuItems={props.form?.memberTelLocalType}
                                    defaultValue={1}
                                    name={"fax.localNumber"}
                                    reference={methods.register}
                                />
                                <TelField
                                    name={"fax.number1"}
                                    ref={methods.register}
                                    maxLength={4}
                                />
                                <TelField
                                    name={"fax.number2"}
                                    ref={methods.register}
                                    maxLength={4}
                                />
                            </RowDiv>
                        </ItemDiv>

                        <ItemDiv>

                            <InputTitle>이메일<RedDot/></InputTitle>

                            <Email>
                                <MailNameInputField
                                    name={"email.name"}
                                    ref={methods.register}
                                    placeholder={"담당자명"}
                                />
                                <MailField
                                    name={"email.email"}
                                    ref={methods.register(RequiredRulesWithoutValidation)}
                                />
                                <AtDiv>@</AtDiv>
                                <MailField
                                    name={"email.domain"}
                                    ref={methods.register(RequiredRulesWithoutValidation)}
                                    readOnly={isReadOnly}
                                    onChange={handleDomain}
                                />
                                <MailSelectBox
                                    name={"domain"}
                                    reference={methods.register}
                                    menuItems={props.form?.memberEmailDomainType}
                                    defaultValue={1}
                                />
                            </Email>
                        </ItemDiv>

                        <ItemDiv>
                            <InputTitle>회사 주소<RedDot/></InputTitle>

                            <AddressWithButton
                                name={"address"}
                                rules={RequiredRulesWithoutValidation}
                            />

                            <AddressDetailInput
                                name={"addressDetail"}
                                ref={methods.register}
                                placeholder={"상세주소"}
                            />
                        </ItemDiv>

                        <ItemDiv>
                            <InputTitle>배송 주소<RedDot/></InputTitle>

                            <AddressWithButton
                                name={"deliveryAddress"}
                                rules={RequiredRulesWithoutValidation}
                            />

                            <AddressDetailInput
                                name={"deliveryAddressDetail"}
                                ref={methods.register}
                                placeholder={"상세주소"}
                                readOnly={check}
                            />

                            <CheckDiv>
                                <ThinCheckBox checked={check} onChange={handleCheck}/>
                                <SameAddressText>회사주소와 동일</SameAddressText>
                            </CheckDiv>
                        </ItemDiv>


                        <NextButton
                            disabled={!isValid || !idChecked}
                            type={"submit"}
                        >
                            <FixedWeightText fontSize={"0.75rem"} color={"var(--white)"}>수정하기</FixedWeightText>
                        </NextButton>
                    </InfoDiv>



                </CenterDiv>
            </form>
        </FormProvider>
    )
}

const CenterDiv = styled.div`
  display: flex;
  flex-direction : column;
  justify-content: center;
  

  width: 35rem;
  margin-top: 2.5rem;
  margin-bottom: 5rem;
  
`

const AddressCheckBox = styled(BasicCheckBox)`
  padding : 0px;
  margin-left: -2px;
  border-width: 1px;
`
const AccountInfoDiv = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 484px;
  height: auto;
  border-radius: 4px;
  padding: 32px;
  border: solid 1px var(--gray-4);
  background-color: var(--white);
  margin-top: 52px;
`

const CheckDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
`
const BasicInfoDiv = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 484px;
  padding : 32px;
  border-radius: 4px;
  border: solid 1px var(--gray-4);
  background-color: var(--white);
  margin-top : 48px;
  margin-bottom : 200px;
`

const HeaderText = styled(FixedWeightText).attrs({
    fontWeight: 600
})`
  font-size: 36px;
  margin-top: 64px;
`

const HeaderSmallText = styled(FixedWeightText)`
  display: flex;
  flex : 1;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
`



type GuideTextProps = {
    error?: boolean
    marginTop?: string
}

const GuideText = styled(FixedWeightText).attrs({
    fontSize: "0.75rem",
    fontWeight: "normal"
})<GuideTextProps>`
  margin-top: ${(props:GuideTextProps) => props.marginTop || "0.5rem"};
  margin-left: 0.125rem;
  height: 1rem;
  color: ${(props) => (props.error && "var(--red)") || "var(--gray-4)"};
`

const MobileTitle = styled(FixedWeightText).attrs({
    fontSize: "14px",
    fontWeight: 500
})`
  margin-top: 24px;
  height: 20px;
`

const Mobile = styled.div`
  display: flex;
  margin-top : 0.625rem;
  justify-content: space-between;
`

const MarginMobile = styled(Mobile)`
  margin-top: 0.625rem;
`
const Email = styled.div`
  display: flex;  
  justify-content: space-between;
`

type InputFieldProps = {
    error?: boolean
}

const ErrorInputFieldWithGuide = styled(Input).attrs((props: InputFieldProps) => ({
    focusBorderColor: (props.error && "var(--red)") || "var(--blue)"
}))<InputFieldProps>`
  border-color: ${(props) => (props.error && "var(--red)") || "var(--gray-4)"};
`

const InputField = styled(ErrorInputFieldWithGuide)`
  width : 35rem;
  height: 2.5rem;
`

const TelSelectBox = styled(Select).attrs({
    width: "11.25rem",
    height: "2.5rem",
})``

const TelField = styled(ErrorInputFieldWithGuide)`
  width: 11.25rem;
  height: 2.5rem;
  margin-left: 0.625rem;
`

const MailNameInputField = styled(Input)`
  display: flex;
  width: 8.375rem;
  height: 2.5rem;
  margin-right: 0.625rem;
`

const MailSelectBox = styled(Select).attrs({
    width: "7.8rem",
    height: "2.5rem",
})``

const MailField = styled(ErrorInputFieldWithGuide)`
  width: 7.8rem;
  height: 2.5rem;
  margin-right: 0.625rem;
`

const AtDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
`


const MobileSelectBox = styled(Select).attrs({
    width: "8.25rem",
    height: "2.5rem",
})``

const MobileField = styled(InputField)`
  width: 8.25rem;
  margin-left: 0.625rem;
`

const ErrorInputField = styled(ErrorInput)`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  width: 420px;
  height: 48px;
`

const NameAndBodyInputField = styled(Input)`
  display: flex;
  width: 8.375rem;
  height: 2.5rem;
  margin-right: 0.625rem;
`

const AddressDetailInput = styled(Input)`
 display: flex;
 box-sizing: border-box;
 width: 35rem;
 height: 2.5rem;
 margin-top : 0.625rem;
`

const SameAddressText = styled.text`
  font-size: 0.75rem;
  margin-left: 0.5rem;
  font-weight: 300;
`

const NextButton = styled(Button)`
  width: 35rem;
  height: 3rem;
  margin-top : 1.875rem;
  color: var(--white);
`

export default UserUpdateScreen;