import React, {useState} from 'react';
import styled from 'styled-components';
import {Link} from "react-router-dom";
import BaseText from "../../components/style/text/BaseText";
import home_mo_picture from "../../assets/images/home/mo_background.png";
import home_mo_picture2x from "../../assets/images/home/mo_background@2x.png";
import home_mo_picture3x from "../../assets/images/home/mo_background@3x.png";
import {LINK_SIGN_UP} from "../../resources/LinkConstants";
import {DividerDiv} from "../../components/style/div/Divider";
import phone_picture from "../../assets/images/common/phone.png";
import phone_picture2x from "../../assets/images/common/phone@2x.png";
import phone_picture3x from "../../assets/images/common/phone@3x.png";
import Input from "../../components/style/input/Input";
import {SignInRequest} from "../../api/signIn";
import {useForm} from "react-hook-form";
import Checkbox from "@material-ui/core/Checkbox";
import ProgressButton from "../../components/style/button/ProgressButton";
import FixedWeightText from "../../components/style/text/FixedWeightText";



interface HomeScreenMobileProps {
    onConfirmClick: (request: SignInRequest) => (void)
    loading: boolean
}

function LoginScreenMobile(props: HomeScreenMobileProps) {

    const {register, handleSubmit, formState} = useForm<SignInRequest>(
        {
            mode: "onChange"
        }
    )

    const onSubmit = (data: SignInRequest) => {
        data.autoLogin = autoLogin
        props.onConfirmClick(data)
    }

    const {isValid} = formState
    const [autoLogin, setAutoLogin] = useState(true)

    function handleCheck(event: React.ChangeEvent<HTMLInputElement>){
        setAutoLogin(event.target.checked)
        /*
                if(event.target.checked){
                    methods.setValue("deliveryAddress", methods.watch().address,{
                        shouldValidate : true
                    })
                    methods.setValue("deliveryAddressDetail", methods.watch().addressDetail)
                }

         */
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Cover>
                    <PictureImage src={home_mo_picture} srcSet={`${home_mo_picture2x} 2x,${home_mo_picture3x} 3x`}/>

                    <LoginDiv>
                        <TextDiv>
                            <TitleText>
                                TONGSORI
                            </TitleText>

                            <DetailText>
                                Dalim Hydrogen Residual Measurement System
                            </DetailText>
                        </TextDiv>

                        <InputDiv>

                            <EditText
                                name="userId"
                                ref={register({required: true, minLength: 1})}
                                placeholder={"아이디"}/>


                            <EditText
                                name="password"
                                ref={register({required: true, minLength: 1})}
                                placeholder={"비밀번호(4~16자)"}
                                type={"password"}
                                maxLength={16}/>

                            <AutoLoginDiv>
                                <AutoLoginCheckBox checked={autoLogin} onChange={handleCheck} size="small"/>
                                <AutoLoginText>자동 로그인</AutoLoginText>
                            </AutoLoginDiv>
                        </InputDiv>


                        <ButtonDiv>
                            <NextButton
                                disabled={!isValid}
                                type={"submit"}
                                isLoading={props.loading}>
                                <NextButtonText>로그인</NextButtonText>
                            </NextButton>

                            {/*<BottomLink>*/}
                                {/*<BottomItemText fontSize={"14px"} color={"var(--gray-3)"}>아이디 찾기</BottomItemText>*/}
                                {/*<BottomBar/>*/}
                                {/*<BottomItemText fontSize={"14px"} color={"var(--gray-3)"}>비밀번호 찾기</BottomItemText>*/}
                                {/*<BottomBar/>*/}
                                {/*<Link to={LINK_SIGN_UP} style={{textDecoration: 'none'}}>*/}
                                {/*    <BottomItemText fontSize={"14px"} color={"var(--gray-3)"}>회원가입</BottomItemText>*/}
                                {/*</Link>*/}
                            {/*</BottomLink>*/}

                            <FootDiv>
                                {/*<DividerDiv/>*/}
                                <CallDiv>
                                    <CallImage src={phone_picture} srcSet={`${phone_picture2x} 2x,${phone_picture3x} 3x`}/>
                                    <CallSpan>031-355-3329</CallSpan>
                                </CallDiv>
                            </FootDiv>
                        </ButtonDiv>
                    </LoginDiv>

                </Cover>
            </form>

        </>
    )
}



export const Cover = styled.div`
  width: 100%;  
  height: 100vh;
  display: flex;
  justify-content: center;  
  overflow: hidden; 
  
`

const PictureImage = styled.img`
  width: 100%;
  position: relative;
  z-index: 1;
`

const TextDiv = styled.div`
  font-family: Silka;  
  width: 17.5rem;

`

const TitleText = styled.div`
  width: 9.75rem;
  height: 1.625rem;
  margin-bottom : 0.625rem;
  font-family: Silka;
  font-size: 1.625rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 2.6px;
  text-align: left;
  color: #fff;
  
`

const DetailText = styled.div`
  width: 13.25rem;
  height: 0.563rem;
  margin: 0.625rem 0 0 0.063rem;
  opacity: 0.8;
  font-family: Silka;
  font-size: 0.563rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
`

const LoginDiv = styled.div`
        
  display: flex;
  flex-direction: column;
  justify-content: center;  
  align-items: center;
  position: absolute;
  box-sizing: border-box;  
  width: 17.5rem;
  
  flex-grow: 0;  
  margin: 6.5rem 0 0 0;
  
  z-index: 2;
   
`

const EditText = styled(Input)`
  width: 17.5rem;
  height: 2.188rem;
  margin: 0 0 0.563rem 0;    
  background-color: transparent;
  border:none;
  border-bottom: solid 1px #ffffff;
  border-radius: 0;
  padding-left: 0;
`

const InputDiv = styled.div`
  margin: 3.125rem 0 1.5rem 0;
`

const AutoLoginDiv = styled.div`
  width: 8.813rem;
  height: 1rem; 
  margin-top: 0.938rem;
`

const AutoLoginCheckBox = styled(Checkbox)`  
  padding: 0;
  object-fit: contain;
  &.Mui-checked{
    color: var(--blue-2);
  }
`

const AutoLoginText = styled.text`
  margin: 0.125rem 0 0.125rem 0.375rem;  
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
`

const ButtonDiv = styled.div`

  flex-direction: column;
  flex-grow: 0;  
  
`

const NextButton = styled(ProgressButton)`
  width: 17.5rem;  
  height: 2.5rem;
  background-color: #4a90e2;
`

const NextButtonText = styled.text`
  font-size: 0.875rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  margin-right: 3.125rem;
  color: #fff;
`

const BottomLink = styled.div`
  
  height: 0.75rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  gap: 10px;
  margin: 0rem 5.75rem 2.5rem 5.813rem;
  padding: 0;
`

const BottomItemText = styled(FixedWeightText)`
  
  flex-grow: 0;  
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #999;      
        
  
`

const FootDiv = styled.div`
  
  height: 1.438rem;  
`

const CallDiv = styled.div`
  
  width: 6.2rem;
  margin: 0.625rem 0 0 0;
  float: right;
  
`

const CallImage = styled.img`
  width: 0.75rem;
  height: 0.75rem;
  margin: 0 0.25rem 0 0;
`

const CallSpan = styled.span`
  width: 5rem;
  height: 0.75rem;
  margin: 0 0 0 0rem;
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: right;
  color: #1473e6;
`


export default LoginScreenMobile