import {
  ColumnApi,
  GridApi,
  GridOptions,
  GridReadyEvent,
  IDatasource,
  IGetRowsParams,
  ModuleRegistry,
} from '@ag-grid-community/core'
import { InfiniteRowModelModule } from '@ag-grid-community/infinite-row-model'
import { AgGridColumn, AgGridReact } from '@ag-grid-community/react'
import React, { useCallback, useMemo, useState, useEffect } from 'react'
import {TerminalRowData} from "../../api/terminal/getTerminal";
import {SituationRoomModalData} from "../../api/situationroom/getSituationRoomModalDataList";
import {ValueGetterParams} from "ag-grid-community";
import { isMobile } from 'react-device-detect';

ModuleRegistry.registerModules([InfiniteRowModelModule])


interface TerminalTableProps {
    rowData?: SituationRoomModalData[]
}

function TerminalTable(props: TerminalTableProps) {
  const [gridApi, setGridApi] = useState<GridApi>()
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi>()
  let test = JSON.stringify(props.rowData)  // props test

  React.useEffect(() => {
    if(gridApi && props.rowData) {
        const updateData = (data:any) => {
            const dataSource: IDatasource = {
                getRows: (params: IGetRowsParams) => {
                    setTimeout(function () {
                        if(data){
                            const rowsThisPage = data?.slice(params.startRow, params.endRow)
                            let lastRow = -1
                            if (data?.length <= params.endRow) {
                                lastRow = data.length
                            }
                            params.successCallback(rowsThisPage, lastRow)
                        }
                    }, 500)
                },
            }
            gridApi.setDatasource(dataSource)
        }
        updateData(props.rowData)
    }
  },[gridApi, props.rowData])

  function onGridReady(event: GridReadyEvent){
    setGridApi(event.api)
    setGridColumnApi(event.columnApi)


    ////////////////////////////////////////////////////////////////////////////////////

    const data = [{"sendDateTime":"2142","gasLevel":"353","tankPressure":"731"},
                  {"sendDateTime":"3535","gasLevel":"312","tankPressure":"245"},
                  {"sendDateTime":"3443","gasLevel":"773","tankPressure":"935"}]

    //updateData(data)                //  table표현, data표현 잘 됨
         //  table만 표현되고(column name까지) data값이 안 들어감

    ////////////////////////////////////////////////////////////////////////////////////

  }

  const loadingRenderer = useCallback((params) => {
    if (params.value !== undefined) {
      return params.value
    } else {
      return '<img src="https://raw.githubusercontent.com/ag-grid/ag-grid/master/grid-packages/ag-grid-docs/src/images/loading.gif">'
    }
  }, [])

  const gridOptions = useMemo<GridOptions>(
    () => ({
      cacheBlockSize: 20,
      components: { loadingRenderer },
      defaultColDef: {
        resizable: true,
      },
      infiniteInitialRowCount: 1,
      onGridReady,
      paginationPageSize: 20,
      rowBuffer: 0,
      rowModelType: 'infinite',
      suppressHorizontalScroll: true,
      suppressNoRowsOverlay: true

    }),
    []
  )

  const gridClassName = isMobile ? 'ag-theme-alpine custom-grid-header' : 'ag-theme-alpine';

  return (
  <>

    <div className={gridClassName} style={{ height: '100%', width: isMobile ? '100%' : '55%', marginRight: isMobile ? '0' : '0.625rem', borderRadius: isMobile ? '0' : '4px' }}>
      <AgGridReact gridOptions={gridOptions} modules={[InfiniteRowModelModule]}>

        <AgGridColumn field="sendDateTime" headerName="시간" flex={isMobile ? 50 : 201} valueGetter={params => params.data?.sendDateTime?.split('T')[1]}/>
        <AgGridColumn field="gasLevel" headerName="잔량(%)" cellStyle={{color: '#14b3bd'}} flex={isMobile ? 50 : 128}/>
        <AgGridColumn field="tankPressure" headerName="압력(Mpa)" cellStyle={{color: '#4a90e2'}} flex={isMobile ? 50 : 128}/>
        <AgGridColumn field="gasLeakage" headerName="가스감지" flex={isMobile ? 50 : 128}/>

      </AgGridReact>

    </div>

  </>
  )
}

export default TerminalTable