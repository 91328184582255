import axios from 'axios';
import {BaseResponse} from "./BaseResponse";

export async function signIn(signInRequest : SignInRequest) {
    console.log("signIn API 진입")
    const response = await axios.post<BaseResponse<TerminalControlUser>>(`/signIn`, signInRequest)
    console.log("signIn API response")
    return response.data
}

interface ErpToken {
    accessToken : string,
    refreshToken : string
}

export interface SignInRequest {
    userId: string,
    password: string,
    autoLogin: boolean
}

export interface TerminalControlUser {
    companyName: string,
    token: ErpToken,
    userId: string,
    autoLogin: boolean
}