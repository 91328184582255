import React from 'react'
/*
import {
  ColumnApi,
  GridApi,
  GridOptions,
  GridReadyEvent,
  IDatasource,
  IGetRowsParams,
  ModuleRegistry,
} from '@ag-grid-community/core'
import { AgGridColumn, AgGridReact } from '@ag-grid-community/react'
*/
import {Vihecle} from "../../api/vihecle/getVihecleList";
import VihecleListTable from "./components/VihecleListTable";
import {VihecleForm} from "../../api/vihecle/getVihecleForm";
import MatchHorizontalSpace from "../../components/MatchHorizontalSpace";
import FixedWeightText from "../../components/style/text/FixedWeightText";
import styled from "styled-components";
//import Button from "../../components/style/button/Button";
import {Link} from "react-router-dom";
import {LINK_VIHECLE_REGIST} from "../../resources/LinkConstants";
import ExcelDownload from "./components/ExcelDownload";
import Nouislider from "nouislider-react";
import Slider from "./UiSlider";
import UiSlider from "./UiSlider";
import Button from '@material-ui/core/Button';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import {useHistory} from 'react-router-dom';


interface VihecleListScreenProps {
    vihecleList?: Vihecle[]
    form? : VihecleForm
}

function VihecleListScreen(props: VihecleListScreenProps) {
/*
    const [gridApi, setGridApi] = useState<GridApi>()
    const [gridColumnApi, setGridColumnApi] = useState<ColumnApi>()

    function onGridReady(event: GridReadyEvent){
        setGridApi(event.api)
        setGridColumnApi(event.columnApi)
    }

    const loadingRenderer = useCallback((params) => {
        if (params.value !== undefined) {
          return params.value
        } else {
          return '<img src="https://raw.githubusercontent.com/ag-grid/ag-grid/master/grid-packages/ag-grid-docs/src/images/loading.gif">'
        }
      }, [])

    const gridOptions = useMemo<GridOptions>(
        () => ({
            cacheBlockSize: 20,
            components: { loadingRenderer },
            defaultColDef: {
                resizable: true,
            },
            infiniteInitialRowCount: 1,
            onGridReady,
            paginationPageSize: 20,
            rowBuffer: 0,
            rowModelType: 'infinite',
        }),
        []
    )

*/
    const history = useHistory()

    function buttonClicked(){
        history.push("/vihecle/regist")
    }


    return (
        <>
            <ListScreen>
                {/*<MatchHorizontalSpace height={"1px"}/>*/}
                <SubTitle>
                    <ResultCountSection>
                        <SubtitleText>차량리스트</SubtitleText>
                        <ResultText>(전체</ResultText>
                        <ResultCount>{props.vihecleList?.length}</ResultCount>
                        <ResultText>건)</ResultText>
                    </ResultCountSection>
                    <ButtonSection>
                        <ExcelDownload
                            data = {props.vihecleList}
                            form = {props.form}
                        />

                        <RegistrationButton
                            startIcon={<AddOutlinedIcon style={{ fontSize: '0.875rem', marginRight: '-0.35rem' }} />}
                            onClick={buttonClicked}
                        >차량 등록</RegistrationButton>


                    </ButtonSection>
                </SubTitle>

                <VihecleListTable
                    data={props.vihecleList}
                    form={props.form}
                />

            </ListScreen>


            {/*
            {
                props.vihecleList &&
                props.vihecleList.map(Vihecle => {
                    return (
                        <div>
                            <div>uid : {Vihecle.uid}</div>
                            <div>vihecleName : {Vihecle.vihecleName}</div>
                            <div>vihecleType : {Vihecle.vihecleType}</div>
                            <div>tankProductorName : {Vihecle.tankProductorName}</div>
                            <div>tankGasType : {Vihecle.tankGasType}</div>
                            <div>tankWeight : {Vihecle.tankWeight.type},{Vihecle.tankWeight.value}</div>
                            <div>tankSerialNo : {Vihecle.tankSerialNo}</div>
                            <div>productDate : {Vihecle.productDate}</div>
                            <div>createdAt : {Vihecle.createdAt}</div>
                            <div>terminalSerialNo : {Vihecle?.terminalSerialNo}</div>
                            <br/><br/>
                        </div>
                    )
                })
            }
            */}




            {/*
            <div className="ag-theme-alpine" style={{ height: 400, width: 1080 }}>
                <AgGridReact gridOptions={gridOptions}>
                    <AgGridColumn field="serialNumber" headerName="No" width={70}></AgGridColumn>
                    <AgGridColumn field="gasLevel" headerName="수정" width={100}></AgGridColumn>
                    <AgGridColumn field="viheclename" headerName="차명" width={100}></AgGridColumn>
                    <AgGridColumn field="vihecletype" headerName="종류" width={100}></AgGridColumn>
                    <AgGridColumn field="tankproductor_name" headerName="제조사" width={100}></AgGridColumn>
                    <AgGridColumn field="tankgastype" headerName="가스" width={100}></AgGridColumn>
                    <AgGridColumn field="tankweight" headerName="용량" width={100}></AgGridColumn>
                    <AgGridColumn field="tankserialno" headerName="탱크시리얼번호" width={100}></AgGridColumn>
                    <AgGridColumn field="productdate" headerName="제조년월일" width={100}></AgGridColumn>
                    <AgGridColumn field="terminalserialno" headerName="단말시리얼번호" width={100}></AgGridColumn>
                    <AgGridColumn field="registdate" headerName="등록일" width={100}></AgGridColumn>
                </AgGridReact>
            </div>
            */}

        </>
    )
}

const ListScreen = styled.div`
  display: flex;
  width: 87.5rem;
  flex-direction: column;
  align-items: center;
  margin-bottom: 6.688rem;
  
  
`

const SubTitle = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  justify-content: space-between;
`

const ResultCountSection = styled.div`
  display: flex;
  width: 320px;
`

const ButtonSection = styled.div`
  display: flex;
  width: 14.5rem;
  justify-content: space-between;
`

const SubtitleText = styled(FixedWeightText)`
  font-size: 1.125rem;
  font-weight: bold;
  margin-right: 0.375rem;
`

const ResultText = styled(FixedWeightText)`
  font-size: 0.875rem;
  font-weight: normal;
`

const ResultCount = styled(FixedWeightText)`
  font-size: 0.875rem;
  font-weight: 600;
  color : #4a90e2;
  margin-left: 0.25rem;
`

const RegistrationButton = styled(Button)`  
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.14;
  padding: 0.5rem 1.125rem;
  border-radius: 4px;  
  color : #333;
  background-color: #ffffff;
  border : solid 1px #333;

  transition: 0.3s;
 
`

export default VihecleListScreen