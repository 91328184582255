import React from 'react';
import styled from 'styled-components';
import FixedWeightText from "../text/FixedWeightText";
import Input from "./Input";
import {isMobile} from "react-device-detect";

type InputWithVihecleCountProps = {
    name?: string
    type?: string
    reference?: any
    maxLength?: any
    errorMessage?: string
    placeholder?: string
    alertText?: string
}

export const InputWithVihecleCount = styled.div.attrs((props: InputWithVihecleCountProps) => ({
    children:
        <>
            <StyledInput ref={props.reference}{...props}/>
            <AlertText>
                {props.alertText || "결과 없음"}
            </AlertText>
        </>
}))<InputWithVihecleCountProps>`
  display: flex;
  border-radius: 4px;
  border: solid 1px;
  border-color: var(--gray-4);
/*
  &:focus-within{
    outline: none !important;
    border-width: 2px;   
    border-color: var(--blue);
  }
*/
  
`
const StyledInput = styled(Input)`
  flex: 1;
  box-sizing: border-box;
  
  border-radius: 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-size: 0.875rem;
  border : transparent;
  outline : none;
  &:focus{
    outline: none !important;
    border: none;
  }
  ${isMobile && `
    font-size: 0.7rem;
  `}
`

const AlertText = styled(FixedWeightText).attrs({
    fontSize: "0.875rem",
    fontWeight: 500
})`
  display: flex;
  align-self: center;
  margin-right: 12px;
  color : var(--black);
  ${isMobile && `
    font-size: 0.7rem;
  `}
`

