import React, {useEffect} from 'react';
import VihecleRegistScreen from './VihecleRegistScreen'
import {getVihecleForm} from "../../../api/vihecle/getVihecleForm";
import {getVihecle} from "../../../api/vihecle/getVihecle";
import {useAsync} from "../../../hooks/useAsync";
import {VihecleRequest, postVihecle} from "../../../api/vihecle/postVihecle";
import {updateVihecle} from "../../../api/vihecle/updateVihecle";
import {useHistory, useParams} from "react-router-dom";
import {LINK_VIHECLE} from "../../../resources/LinkConstants";
import {getSerialNoList} from "../../../api/terminal/getSerialNoList";

interface VihecleRegistContainerProps {

}

function VihecleRegistContainer(props: VihecleRegistContainerProps) {



    const {data: form} = useAsync(getVihecleForm, true)
    const {execute: postVihecleExecute, status: postStatus} = useAsync(postVihecle)
    const {data: SerialNoList} = useAsync(getSerialNoList, true)

    const history = useHistory()

    useEffect(() => {
        if (postStatus === 'success') {
            alert("차량이 등록되었습니다")
            history.replace(LINK_VIHECLE)
        }
    }, [postStatus])

    return (
        <>
            <VihecleRegistScreen
                form={form}
                postVihecle={postVihecleExecute}
                SerialNoList={SerialNoList}
            />
        </>
    )
}

export default VihecleRegistContainer