import React, {useState} from 'react'
import styled from "styled-components"
import DefaultWrapperDiv from "../../components/style/div/DefaultWrapperDiv"
import DefaultOuterDiv from "../../components/style/div/DefaultOuterDiv"
import {useForm} from "react-hook-form"
import {SignInRequest} from "../../api/signIn"
import FixedWeightText from "../../components/style/text/FixedWeightText"
import ProgressButton from "../../components/style/button/ProgressButton";
import Input from "../../components/style/input/Input";
import home_picture from "../../assets/images/login/pc_background2.png";
import home_picture2x from "../../assets/images/login/pc_background2@2x.png";
import home_picture3x from "../../assets/images/login/pc_background2@3x.png";
import BasicCheckBox from "../../components/style/button/BasicCheckBox";
import Checkbox from "@material-ui/core/Checkbox";
import phone_picture from "../../assets/images/common/phone.png";
import phone_picture2x from "../../assets/images/common/phone@2x.png";
import phone_picture3x from "../../assets/images/common/phone@3x.png";
import {DividerDiv} from "../../components/style/div/Divider";
import {LINK_SIGN_UP} from "../../resources/LinkConstants";
import {Link} from "react-router-dom";

interface LoginComponentProps {
    onConfirmClick: (request: SignInRequest) => (void)
    loading: boolean
}

function LoginScreen(props: LoginComponentProps) {
    const {register, handleSubmit, formState} = useForm<SignInRequest>(
        {
            mode: "onChange"
        }
    )

    const onSubmit = (data: SignInRequest) => {
        data.autoLogin = autoLogin
        console.log("자동로그인이왜안돼  :  "+data.autoLogin)
        props.onConfirmClick(data)
    }

    const {isValid} = formState
    const [autoLogin, setAutoLogin] = useState(true)

    function handleCheck(event: React.ChangeEvent<HTMLInputElement>){
        setAutoLogin(event.target.checked)
/*
        if(event.target.checked){
            methods.setValue("deliveryAddress", methods.watch().address,{
                shouldValidate : true
            })
            methods.setValue("deliveryAddressDetail", methods.watch().addressDetail)
        }

 */
    }

    return (

        <ScreenDiv>
            <form onSubmit={handleSubmit(onSubmit)}>

                <CenterDiv>
                    <ImageDiv>
                        <PictureImage src={home_picture} srcSet={`${home_picture2x} 2x,${home_picture3x} 3x`}/>
                    </ImageDiv>
                    <LoginDiv>
                        <HeaderDiv>
                            <HeaderText>
                                TONGSORI
                            </HeaderText>
                            <HeaderSmallText>
                            가스 원격 관제 시스템
                            </HeaderSmallText>
                        </HeaderDiv>

                        <ContentDiv>
                            <InputDiv>
                                <SubTitle>아이디</SubTitle>
                                <EditText
                                    name="userId"
                                    ref={register({required: true, minLength: 1})}
                                    placeholder={"아이디"}/>
                            </InputDiv>
                           <InputDiv2>
                            <SubTitle>비밀번호</SubTitle>
                            <EditText
                                name="password"
                                ref={register({required: true, minLength: 1})}
                                placeholder={"비밀번호(4~16자)"}
                                type={"password"}
                                maxLength={16}/>
                            </InputDiv2>
                            <AutoLoginDiv>
                                <AutoLoginCheckBox checked={autoLogin} onChange={handleCheck} size="small"/>
                                <AutoLoginText>자동 로그인</AutoLoginText>
                            </AutoLoginDiv>

                        </ContentDiv>
                        <ButtonDiv>
                            <NextButton
                                disabled={!isValid}
                                type={"submit"}
                                isLoading={props.loading}>
                                <NextButtonText>로그인</NextButtonText>
                            </NextButton>

                            <BottomLink>
                                {/*<BottomItemText fontSize={"14px"} color={"var(--gray-3)"}>아이디 찾기</BottomItemText>*/}
                                {/*<BottomBar/>*/}
                                {/*<BottomItemText fontSize={"14px"} color={"var(--gray-3)"}>비밀번호 찾기</BottomItemText>*/}
                                {/*<BottomBar/>*/}
                                <Link to={LINK_SIGN_UP} style={{textDecoration: 'none'}}>
                                    <BottomItemText fontSize={"14px"} color={"var(--gray-3)"}>회원가입</BottomItemText>
                                </Link>
                            </BottomLink>

                            <FootDiv>
                                <DividerDiv/>
                                <CallDiv>
                                    <CallImage src={phone_picture} srcSet={`${phone_picture2x} 2x,${phone_picture3x} 3x`}/>
                                    <CallSpan>031-355-3329</CallSpan>
                                </CallDiv>
                            </FootDiv>
                        </ButtonDiv>
                    </LoginDiv>
                </CenterDiv>

            </form>
        </ScreenDiv>

    );
}

const ScreenDiv = styled.div`
  width: 100%;
  
`

const CenterDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction : column;
  align-items: center;
  margin-top: -1.2rem;    
`

const LoginDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;  
  align-items: center;
  position: absolute;
  box-sizing: border-box;  
  width: 29.375rem;
  height: 34.375rem;
  flex-grow: 0;
  padding: 3.75rem 2.5rem;
  border-radius: 4px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  margin-top: 11.063rem;
  background-color: #fff;
   
`

const ImageDiv = styled.div`
  position: relative;
  width:100%;
  
    
`

const ContentDiv = styled.div`
      display: flex;
  width: 24.375rem;
  height: 10.563rem;
  flex-grow: 0;
  margin: 0 0 2rem;
  flex-direction: column;     
`

const InputDiv = styled.div`
  width: 24.375rem;
  height: 4rem;
  margin: 0 0 0.875rem 0;
  
`

const InputDiv2 = styled.div`
  width: 24.375rem;
  height: 4rem;
  margin: 0 0 0.688rem 0;
  
`

const ButtonDiv = styled.div`
  width: 24.375rem;
  height: 8.938rem;
  flex-grow: 0;  
`

const EditText = styled(Input)`
  width: 24.375rem;
  height: 2.5rem;
  margin: 0.25rem 0 0;
  padding: 0.625rem 1rem 0.625rem 1rem;
  border-radius: 4px;
  background-color: #e8f0fe;
  border:none;
`
const AutoLoginText = styled.text`
  margin: 0.125rem 0 0.125rem 0.375rem;  
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #666;
`


const NextButtonText = styled.text`
  font-size: 0.875rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  margin-right: 3.125rem;
  color: #fff;
`
const NextButton = styled(ProgressButton)`
  width: 24.375rem;  
  height: 3rem;
  margin: 0 0 1.25rem;  
  border-radius: 4px;
  background-color: #4a90e2;
`

const HeaderDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction : column;
  align-items: center;
  width: 12.875rem;
  height: 3.5rem;
  flex-grow: 0;
  margin: 0 5.75rem 1.875rem;
`

const AutoLoginDiv = styled.div`
  width: 8.813rem;
  height: 1rem; 
`

const FootDiv = styled.div`
  width: 24.375rem;
  height: 1.438rem;  
`

const CallDiv = styled.div`
  
  width: 6.2rem;
  margin: 0.625rem 0 0 0;
  float: right;
  
`

const HeaderText = styled.text`
  display: flex;
  
  align-items: center;
  justify-content: center;
  margin-top: 64px;
  font-size: 36px;

  
  margin: 0 0 0.375rem;
  
  font-size: 2.125rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.88;
  letter-spacing: 3.4px;
  text-align: center;
  color: #333;
  
  


`

const HeaderSmallText = styled(FixedWeightText)`
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: #333;
`

const Title = styled(FixedWeightText).attrs({
    fontWeight: 500
})`
  font-size: 36px;
  color: var(--black);
`

const SubTitle = styled.text`

  
  font-size: 0.875rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #333;
  
`

const BottomLink = styled.div`
  width: 12.813rem;
  height: 0.75rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  gap: 10px;
  margin: 0rem 5.75rem 2.5rem 5.813rem;
  padding: 0;
`

const BottomItemText = styled(FixedWeightText)`
  
  flex-grow: 0;  
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #999;      
        
  
`
const BottomBar = styled.div`
  width: 0.063rem;
  height: 0.75rem;
  flex-grow: 0;
  background-color: #eee;
`

const PictureImage = styled.img`
  width: 100%;
`

const CallImage = styled.img`
  width: 0.75rem;
  height: 0.75rem;
  margin: 0 0.25rem 0 0;
`

const CallSpan = styled.span`
  width: 5rem;
  height: 0.75rem;
  margin: 0 0 0 0rem;
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: right;
  color: #1473e6;
`


const AutoLoginCheckBox = styled(Checkbox)`  
  padding: 0;
  object-fit: contain;
  &.Mui-checked{
    color: var(--blue-2);
  }
`

export default LoginScreen