import React from 'react';
import styled from 'styled-components';
import Close from "../../assets/images/signup/ic-48-close-black2@3x.png";
import DefaultImage from "../style/image/DefaultImage";
import FixedWeightText from "../style/text/FixedWeightText";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

interface ModalHeaderMobileProps {
    title?: string | null
    onCancel?: () => void
}

function ModalHeaderMobile (props : ModalHeaderMobileProps) {
    function handleClick() {
        if (props.onCancel) {
            props.onCancel()
        }
    }

    return (
        <Header>
            <StyledArrowBackIosIcon onClick={handleClick}/>

            <HeaderText>
                {props.title}
            </HeaderText>
            <ModifyText>
                수정
            </ModifyText>
            {/*<DefaultImage src={Close} width={"1.74rem"} height={"1.74rem"} onClick={handleClick}/>*/}
        </Header>
    );
}

const Header = styled.div`
    display: flex;
    height: 3.5rem;    
    background-color: #fff;
    justify-content: space-between;
    align-items: center;   
    
  padding-left : 1.1rem;
  padding-right : 1.288rem;  
`

const HeaderText = styled(FixedWeightText)`
  font-size: 1rem;
  line-height: 1;
  font-weight: bold;  
  color : #333333;
`

const ModifyText = styled.div`
  font-size: 0.875rem; 
  line-height: 1;
  text-align: center;
  color: #333;
`

const StyledArrowBackIosIcon = styled(ArrowBackIosIcon)`
  width: 1.2rem;
  height: 1.2rem;
`

export default ModalHeaderMobile