import axios from "axios";
import {BaseResponse} from "../BaseResponse";

export async function getVihecleList() {
    const response = await axios.get<BaseResponse<Array<Vihecle>>>(`/vihecle`)

    return response.data
}

interface TankWeight {
    type: string
    value: number
}

interface gasLeakageAlarm {
    alarm : boolean
    max : number
}

interface tankPressureAlarm {
    alarm : boolean
    max : number
    min : number
}

interface gasLevelAlarm {
    alarm : boolean
    max : number
    min : number
}

interface terminal {
    serialNo: string
}

export interface Vihecle {
    uid: string,
    vihecleName: string,
    vihecleType: string,
    tankProductorName: string,
    tankGasType: string,
    tankWeight: TankWeight,
    tankSerialNo: string,
    productDate: string,
    createdAt: string,
    terminalSerialNo?: string,
    gasLeakageAlarm?: gasLeakageAlarm,
    tankPressureAlarm?: tankPressureAlarm,
    gasLevelAlarm?: gasLevelAlarm,
    terminal?: terminal
}

