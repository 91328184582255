import {createGlobalStyle} from "styled-components";
import reset from 'styled-reset';
import PretendardBoldWoff2 from '../fonts/Pretendard/woff2/Pretendard-Bold.woff2'
import PretendardBlackWoff2 from '../fonts/Pretendard/woff2/Pretendard-Black.woff2'
import PretendardExtraBoldWoff2 from '../fonts/Pretendard/woff2/Pretendard-ExtraBold.woff2'
import PretendardSemiBoldWoff2 from '../fonts/Pretendard/woff2/Pretendard-SemiBold.woff2'
import PretendardMediumWoff2 from '../fonts/Pretendard/woff2/Pretendard-Medium.woff2'
import PretendardRegularWoff2 from '../fonts/Pretendard/woff2/Pretendard-Regular.woff2'
import PretendardLightWoff2 from '../fonts/Pretendard/woff2/Pretendard-Light.woff2'
import PretendardExtraLightWoff2 from '../fonts/Pretendard/woff2/Pretendard-ExtraLight.woff2'
import PretendardThinWoff2 from '../fonts/Pretendard/woff2/Pretendard-Thin.woff2'

import PretendardBoldWoff from '../fonts/Pretendard/woff/Pretendard-Bold.woff'
import PretendardBlackWoff from '../fonts/Pretendard/woff/Pretendard-Black.woff'
import PretendardExtraBoldWoff from '../fonts/Pretendard/woff/Pretendard-ExtraBold.woff'
import PretendardSemiBoldWoff from '../fonts/Pretendard/woff/Pretendard-SemiBold.woff'
import PretendardMediumWoff from '../fonts/Pretendard/woff/Pretendard-Medium.woff'
import PretendardRegularWoff from '../fonts/Pretendard/woff/Pretendard-Regular.woff'
import PretendardLightWoff from '../fonts/Pretendard/woff/Pretendard-Light.woff'
import PretendardExtraLightWoff from '../fonts/Pretendard/woff/Pretendard-ExtraLight.woff'
import PretendardThinWoff from '../fonts/Pretendard/woff/Pretendard-Thin.woff'

export const GlobalStyle = createGlobalStyle`
   ${reset}
   ;
   * > body,
   * > body * {
      font-family: Pretendard, sens-serif;
   }

   @font-face {
      font-family: 'Pretendard';
      font-weight: 900;
      font-display: swap;
      src: local('Pretendard Black'), url(${PretendardBlackWoff2}) format('woff2'), url(${PretendardBlackWoff}) format('woff');
   }

   @font-face {
      font-family: 'Pretendard';
      font-weight: 800;
      font-display: swap;
      src: local('Pretendard ExtraBold'), url(${PretendardExtraBoldWoff2}) format('woff2'), url(${PretendardExtraBoldWoff}) format('woff');
   }

   @font-face {
      font-family: 'Pretendard';
      font-weight: 700;
      font-display: swap;
      src: local('Pretendard Bold'), url(${PretendardBoldWoff2}) format('woff2'), url(${PretendardBoldWoff}) format('woff');
   }

   @font-face {
      font-family: 'Pretendard';
      font-weight: 600;
      font-display: swap;
      src: local('Pretendard SemiBold'), url(${PretendardSemiBoldWoff2}) format('woff2'), url(${PretendardSemiBoldWoff}) format('woff');
   }

   @font-face {
      font-family: 'Pretendard';
      font-weight: 500;
      font-display: swap;
      src: local('Pretendard Medium'), url(${PretendardMediumWoff2}) format('woff2'), url(${PretendardMediumWoff}) format('woff');
   }

   @font-face {
      font-family: 'Pretendard';
      font-weight: 400;
      font-display: swap;
      src: local('Pretendard Regular'), url(${PretendardRegularWoff2}) format('woff2'), url(${PretendardRegularWoff}) format('woff');
   }

   @font-face {
      font-family: 'Pretendard';
      font-weight: 300;
      font-display: swap;
      src: local('Pretendard Light'), url(${PretendardLightWoff2}) format('woff2'), url(${PretendardLightWoff}) format('woff');
   }

   @font-face {
      font-family: 'Pretendard';
      font-weight: 200;
      font-display: swap;
      src: local('Pretendard ExtraLight'), url(${PretendardExtraLightWoff2}) format('woff2'), url(${PretendardExtraLightWoff}) format('woff');
   }

   @font-face {
      font-family: 'Pretendard';
      font-weight: 100;
      font-display: swap;
      src: local('Pretendard Thin'), url(${PretendardThinWoff2}) format('woff2'), url(${PretendardThinWoff}) format('woff');
   }

`