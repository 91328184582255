import axios from 'axios';
import {BaseResponse} from "../BaseResponse";


export async function updateMemberPw(request: UpdatePwRequest) {
    const response = await axios.put<BaseResponse<boolean>>(`/member/updatePw`, request)

    return response.data
}

export interface UpdatePwRequest {
    passwordCheck: string,
    password: string,
    passwordConfirm: string
}