import React, {useEffect} from 'react';
import {getVihecleForm} from "../../../api/vihecle/getVihecleForm";
import {getVihecle} from "../../../api/vihecle/getVihecle";
import {useAsync} from "../../../hooks/useAsync";
import {VihecleRequest, postVihecle} from "../../../api/vihecle/postVihecle";
import {updateVihecle} from "../../../api/vihecle/updateVihecle";
import {useHistory, useParams} from "react-router-dom";
import {LINK_VIHECLE} from "../../../resources/LinkConstants";
import {getSerialNoList} from "../../../api/terminal/getSerialNoList";
import VihecleModifyScreen from "./VihecleModifyScreen";
import {deleteVihecle} from "../../../api/vihecle/deleteVihecle";

interface VihecleModifyContainerProps {

}

interface MatchType {
    vihecleId: string
}

function VihecleModifyContainer(props: VihecleModifyContainerProps) {

    const {vihecleId} = useParams<MatchType>()

    const {data: form} = useAsync(getVihecleForm, true)
    const {execute: postVihecleExecute, status: postStatus} = useAsync(postVihecle)
    const {execute: updateVihecleExecute, status: updateStatus} = useAsync(updateVihecle)
    const {execute: deleteVihecleExecute, status: deleteStatus} = useAsync(deleteVihecle)
    const {data: getVihecleData} = useAsync(getVihecle, vihecleId !== undefined, [vihecleId])
    const {data: SerialNoList} = useAsync(getSerialNoList, true, [vihecleId])

    const history = useHistory()

    useEffect(() => {
        if (deleteStatus === 'success') {
            alert("차량이 삭제되었습니다")
            history.replace(LINK_VIHECLE)
        }
        if (updateStatus === 'success') {
            alert("수정되었습니다")
            history.replace(LINK_VIHECLE)
        }
    }, [deleteStatus, updateStatus])



    return (
        <>
            <VihecleModifyScreen
                form={form}
                postVihecle={postVihecleExecute}
                updateVihecle={updateVihecleExecute}
                deleteVihecle={deleteVihecleExecute}
                isUpdate={getVihecleData}
                SerialNoList={SerialNoList}
            />
        </>
    )
}

export default VihecleModifyContainer