import React,{useState, useEffect} from "react";
import Datepicker from "../../../components/style/datepicker/Datepicker";
import styled from "styled-components";
import Moment from "moment";
import {RequiredRulesAndValidationTankProductDate} from "../../../components/form/BaseFormType";
import {useForm} from "react-hook-form";
import {SituationRoomModalData} from "../../../api/situationroom/getSituationRoomModalDataList";
import {useHistory, useLocation} from "react-router-dom";
import TerminalTable from "../../../components/table/TerminalTable";
import GasLevelChart from "./GasLevelChart";
import TankPressureChart from "./TankPressureChart";
import {SituationRoomVihecle} from "../../../api/situationroom/getSituationRoomVihecleList";
import {Card, Chip} from "@material-ui/core";
import FixedWeightText from "../../../components/style/text/FixedWeightText";
import {LINK_SITUATIONROOM} from "../../../resources/LinkConstants";
import {markerIcon} from "../../../components/style/div/MarkerIcon";
import {ModalVihecleCard} from "../../../components/style/div/ModalVihecleCard";
import {StateCircle} from "../../../components/style/div/StateCircle";

interface MapModalProps {
    getModalDataList?: (vihecleName: string, modalDate: string) => void
    situationRoomModalDataList?: SituationRoomModalData[]
//    vihecleList?: SituationRoomVihecle[]
    vihecleList?: Map<string, SituationRoomVihecle>
    activeVihecleSerialNo?: string|null
}

interface ChartData {
    gasLevel: number,
    sendDateTime?: string
}


type SearchModalDataByDate = {
    ModalDate: string
}



function MapModal(props: MapModalProps) {

    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const history = useHistory()

//    const chartData : ChartData[] = []

    const mapStyle = {

        width: '100%',
        height: 'calc(85vh - 25rem)',
        minHeight: '22rem',
        zIndex: '1',



    };

    const [modalMap, setModalMap] = useState<naver.maps.Map>()
    const [viheclePath, setViheclePath] = useState<naver.maps.LatLng[]>([])
    const [polyLine, setPolyLine] = useState<naver.maps.Polyline>()
    const [modalMarker, setmodalMarker] = useState<naver.maps.Marker>()

    const methods = useForm<SearchModalDataByDate>({
        mode: "onBlur",
        criteriaMode: 'all',
    })
    const {watch} = methods
    const {ModalDate} = watch()

    function VihecleCardClick(data: SituationRoomVihecle) {
        history.push(LINK_SITUATIONROOM + "?vn=" + data.vihecleName + "&tw=" + data.tankWeight.value + data.tankWeight.type)
    }

    useEffect(() => {
        if (location.search !== "" && props.getModalDataList && ModalDate !== undefined) {
            props.getModalDataList(params.get("vn")!!, ModalDate)
        }
    }, [location.search, ModalDate])

    useEffect(() => {
        setModalMap(new naver.maps.Map("modalmap", {
            center: new naver.maps.LatLng(37.3187965288894, 126.833797790159),
            zoom: 13
        }))
    }, [])

    useEffect(() => {
        setViheclePath([])
        polyLine?.setMap(null)
        modalMarker?.setMap(null)
        if (props.situationRoomModalDataList) {
            if (props?.situationRoomModalDataList[0]?.gpsData) {
                const startGps = props?.situationRoomModalDataList[0]?.gpsData?.split(',')
                //    modalMap?.morph(new naver.maps.LatLng(+startGps[0], +startGps[1]), 13)
                modalMap?.setCenter(new naver.maps.LatLng(+startGps[0], +startGps[1]))
                modalMap?.setZoom(13)
                setmodalMarker(new naver.maps.Marker({
                    position: new naver.maps.LatLng(+startGps[0], +startGps[1]),
                    map: modalMap,
                    title: params?.get("vn")!!,
                    icon: markerIcon(true, false, true, params?.get("vn")!!, params.get("tw")!!),

                }))
            }
            props.situationRoomModalDataList.map((SituationRoomModalData) => {
                if (SituationRoomModalData.gpsData) {
                    const gpsAry = SituationRoomModalData.gpsData.split(',')
                    viheclePath.push(new naver.maps.LatLng(+gpsAry[0], +gpsAry[1]))
                    var bounds = modalMap?.getBounds()
                    bounds?.extend(new naver.maps.LatLng(+gpsAry[0], +gpsAry[1]))
                    modalMap?.fitBounds(bounds)
                }


            })
        }

        setPolyLine(new naver.maps.Polyline({
            path: viheclePath,      //선 위치 변수배열
            strokeColor: '#05264c', //선 색 빨강 #빨강,초록,파랑
            strokeOpacity: 1, //선 투명도 0 ~ 1
            strokeWeight: 3,   //선 두께
            map: modalMap           //오버레이할 지도
        }))
    }, [props.situationRoomModalDataList])

    function markerContent(vihecleName: string, tankWeight: string) {
        var markerContent = [
            '<div style="position:absolute;">',
            '<div id=',
            vihecleName,
            ' style="display:',
            'block',
            ';position:absolute;',
            'color: #000000;',
            'width:110px;',
            'height:17px;',
            'top:-26px;',
            'left:-46px;',
            'background-color:rgba(0,0,0,0.8);',
            'border-radius: 0.2em;',

            'text-align: center;',
            'vertical-align: middle;',
            'z-index:1;',
            'border:1px solid black;',
            'margin:0;padding:3px;">',

            '<div style="width:25%;',
            'height:13px;',
            'width: 27px;',
            'top:-26px;',
            'left:-40px;',
            'color: #000000;',
            'font-size:0.7em;',
            'background-color:#FFFFFF;',
            'border-radius: 0.2em;',
            'text-align:center;',
            'vertical-align: middle;',
            'float:left;padding:1.5px;">',
            tankWeight,
            '</div>',

            '<div style="overflow:hidden;',
            'text-overflow: ellipsis;',
            'height:13px;',
            'width: 68%;',
            'white-space: nowrap;',
            'color: #FFFFFF;',
            'font-size:0.8em;',
            'float: right;padding:1.5px;',
            'vertical-align: middle;">',
            vihecleName,
            '</div>',
            '</div>',
            '<div class="pin_s" style="cursor: pointer; width: 22px; height: 30px;">',

            '<img src="marker1.png" alt="" style="margin: 0px; padding: 0px; border: 0px solid transparent; display: block; max-width: none; max-height: none; -webkit-user-select: none; position: absolute; width: 30px; height: 30px; left: 0px; top: 0px;">',

            '</div>',
            '</div>',
            '</div>'
        ].join('')

        return markerContent
    }

    function modalVihecleCardList(){
        const result : JSX.Element[] = []
        props.vihecleList?.forEach((situationRoomVihecle, key)=>{
            result.push(
                <ModalVihecleCard active={params.get("vn") === situationRoomVihecle.vihecleName} danger={+situationRoomVihecle.gasLeakage >= 50} power={situationRoomVihecle.power!=="2"} onClick={() => {
                    VihecleCardClick(situationRoomVihecle)
                }}>
                    {/*<ItemDiv*/}
                    {/*    style={params.get("vn") === situationRoomVihecle.vihecleName ? {backgroundColor: 'var(--gray-5)'} : {backgroundColor: 'var(--white)'}}>*/}
                    {/*    {*/}
                    {/*        situationRoomVihecle.power === "1" &&*/}
                    {/*        <Chip label={"ON"} style={{*/}
                    {/*            backgroundColor: 'var(--green)',*/}
                    {/*            color: 'var(--white)',*/}
                    {/*            fontSize: '5px',*/}
                    {/*            marginLeft: '5px',*/}
                    {/*            width: '31px'*/}
                    {/*        }} size={'small'}/>*/}
                    {/*    }*/}

                    {/*    {*/}
                    {/*        situationRoomVihecle.power === "2" &&*/}
                    {/*        <Chip label={"Off"} style={{*/}
                    {/*            backgroundColor: 'var(--gray-3)',*/}
                    {/*            color: 'var(--white)',*/}
                    {/*            fontSize: '5px',*/}
                    {/*            marginLeft: '5px',*/}
                    {/*            width: '31px'*/}
                    {/*        }} size={'small'}/>*/}
                    {/*    }*/}

                    {/*    {*/}
                    {/*        !situationRoomVihecle.power &&*/}
                    {/*        <Chip label={"???"} style={{*/}
                    {/*            backgroundColor: 'var(--red)',*/}
                    {/*            color: 'var(--white)',*/}
                    {/*            fontSize: '5px',*/}
                    {/*            marginLeft: '5px',*/}
                    {/*            width: '31px'*/}
                    {/*        }} size={'small'}/>*/}
                    {/*    }*/}
                    <ItemDiv>
                        <StateCircle danger={+situationRoomVihecle.gasLeakage >= 50}/>

                        <VihecleNameText>
                            {situationRoomVihecle.vihecleName}
                        </VihecleNameText>
                    </ItemDiv>
                </ModalVihecleCard>

            )
        })

        return result
    }

    return (
        <>
            <ModalDiv>
                <ContentDiv>
                    <div id="modalmap" style={mapStyle}>
                        <div style={{position: "relative", zIndex: "2", float: "left"}}>
                            <DatePicker
                                defaultValue={Moment().format('YYYY-MM-DD')}
                                name={"ModalDate"}
                                reference={methods.register({required: true})}
                            />
                        </div>
                    </div>

                    <DataDiv>
                        <TerminalTable
                            rowData={props.situationRoomModalDataList}
                        />
                        <ChartDiv>
                            <GasLevelChart
                                data={props.situationRoomModalDataList}
                                title={"잔량(%)"}
                                today={ModalDate}
                            />
                            <TankPressureChart
                                data={props.situationRoomModalDataList}
                                title={"압력(MPa)"}
                                today={ModalDate}
                            />
                        </ChartDiv>
                    </DataDiv>


                </ContentDiv>

                <CardDiv>
                    {
                        modalVihecleCardList()

                        // props.vihecleList &&
                        // Array.from(props.vihecleList, ([key, value])=>({value})).map(data => {
                        //     return (
                        //         <Card style={{
                        //             margin: '2px',
                        //             marginTop: '5px',
                        //             marginBottom: '5px',
                        //             marginRight: '3px'
                        //         }} onClick={() => {
                        //             VihecleCardClick(data.value)
                        //         }}>
                        //             <ItemDiv
                        //                 style={params.get("vn") === data.value.vihecleName ? {backgroundColor: 'var(--gray-5)'} : {backgroundColor: 'var(--white)'}}>
                        //                 {
                        //                     data.value.power === "1" &&
                        //                     <Chip label={"ON"} style={{
                        //                         backgroundColor: 'var(--green)',
                        //                         color: 'var(--white)',
                        //                         fontSize: '5px',
                        //                         marginLeft: '5px',
                        //                         width: '31px'
                        //                     }} size={'small'}/>
                        //                 }
                        //
                        //                 {
                        //                     data.value.power === "2" &&
                        //                     <Chip label={"Off"} style={{
                        //                         backgroundColor: 'var(--gray-3)',
                        //                         color: 'var(--white)',
                        //                         fontSize: '5px',
                        //                         marginLeft: '5px',
                        //                         width: '31px'
                        //                     }} size={'small'}/>
                        //                 }
                        //
                        //                 {
                        //                     !data.value.power &&
                        //                     <Chip label={"???"} style={{
                        //                         backgroundColor: 'var(--red)',
                        //                         color: 'var(--white)',
                        //                         fontSize: '5px',
                        //                         marginLeft: '5px',
                        //                         width: '31px'
                        //                     }} size={'small'}/>
                        //                 }
                        //
                        //                 <VihecleNameText>
                        //                     {data.value.vihecleName}
                        //                 </VihecleNameText>
                        //             </ItemDiv>
                        //         </Card>
                        //
                        //     )
                        // })


                    }

                </CardDiv>

            </ModalDiv>

            {/*
            {
                props.situationRoomModalDataList &&
                props.situationRoomModalDataList.map(SituationRoomModalData => {
                    return (
                        <div>
                            <div>tankPressure : {SituationRoomModalData.tankPressure}</div>
                            <div>gasLevel : {SituationRoomModalData.gasLevel}</div>
                            <div>gasLeakage : {SituationRoomModalData.gasLeakage}</div>
                            <div>gpsData : {SituationRoomModalData.gpsData}</div>
                            <div>sendDateTime : {SituationRoomModalData.sendDateTime}</div>
                            <br />
                        </div>
                    )
                })
            }
            */}


        </>
    )

}

const ModalDiv = styled.div`
  display : flex;
  
  box-sizing: border-box;
  width: 100%;
  height: calc(100% - 3.313rem);

  

 
  
`

const VihecleNameText = styled(FixedWeightText)`
  
  
  flex-grow: 0;  
  font-size: 0.875rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;  
  color: #333;
  
  

`

const ItemDiv = styled.div`
  display: flex;
  flex : 1;
  align-items: center;
  cursor: pointer;
`

const ContentDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #cfd6e0;
  
  width: calc(100% - 23.75rem);
  
`

const CardDiv = styled.div`
  width: 23.75rem;
  height: 100%;
  padding: 1.25rem;
  box-sizing: border-box;
  
  overflow-y: scroll;
  
  

  &::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--blue-2);
    border-radius: 0.3rem;
  }
`


const DataDiv = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 100rem;
  height: 25rem;  
  padding: 1.25rem;
  box-sizing: border-box;
  background-color: #cfd6e0;
`

const ChartDiv = styled.div`

  width: 45%;
  height: 100%;
  box-sizing: border-box;
  
  
`


const DatePicker = styled(Datepicker)`
  margin-top : 12px;
  margin-left : 12px;      
  width: 150px;
  height: 38px;
  background-color: white;
`

export default MapModal