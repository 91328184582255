import React, {CSSProperties, useEffect, useState} from 'react';
import NavButton from "../../style/button/NavButton";
import styled from "styled-components";
import {Link, useLocation} from "react-router-dom";
import DefaultWrapperDiv from "../../style/div/DefaultWrapperDiv";
import {Tab, Tabs, withStyles} from "@material-ui/core";

export interface NavInfoProps {
    to: string,
    style: CSSProperties,
    text: string
}

interface NavProps {
    navProps: NavInfoProps[],
    activePoint: number,
}

interface NavBarTabsProps {
    value: number;
    onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
}

const NavBarTabs = withStyles({
    indicator: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        height: '0.188rem',
        '& > span': {
            maxWidth: '2.938rem',
            width: '100%',
            backgroundColor: '#4a90e2',
        },
    },
})((props: NavBarTabsProps) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

function NavBar({
                    navProps,
                    activePoint
                }: NavProps) {

    const [active, setActive] = useState(activePoint);

    function setMenu(index: number) {
        setActive(index)
    }

    function handleClick(index: number) {
        setMenu(index)
    }

    const location = useLocation();
    let defaultValue = navProps.findIndex(navItem => navItem.to === location.pathname);

    console.log("////////////"+defaultValue)



    const [value, setValue] = useState<number>(defaultValue);

    useEffect(() => {
        let index = navProps.findIndex((navItem) => navItem.to === location.pathname);
        setValue(index);
    }, [location]);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };



    function makeLinks(navProps: NavInfoProps[]): JSX.Element[] {
        return (
            navProps.map((prop, index) =>
                <Link to={prop.to} style={prop.style} key={index}>
                    {/*<NavButton index={index} isSelected={index === active} click={handleClick} text={prop.text}/>*/}
                    <Tab label={prop.text}/>
                </Link>
            )
        )
    }



    return (
        <DivWithStyle>
            <DefaultWrapperDiv direction={"row"} justifyContent={"center"}>
                {/*{makeLinks(navProps)}*/}

                <NavBarTabs
                    value={value}


                    onChange={handleChange}
                    aria-label="disabled tabs example"


                >
                    {navProps.map((prop, index) =>(

                        <Tab disableRipple label={prop.text} component={Link} to={prop.to} style={{
                            fontSize: '1.125rem',
                            fontWeight: 'bold',
                            fontStretch: 'normal',
                            fontStyle: 'normal',
                            lineHeight: '1.33',
                            letterSpacing: 'normal',
                            textAlign: 'left',
                            color: '#333'
                        }}/>

                        )
                    )}

                </NavBarTabs>

            </DefaultWrapperDiv>
        </DivWithStyle>
    );
}

const DivWithStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 3.4rem;   // 원래 7rem
  // border-bottom: solid 1px var(--gray-7);
  ;
//  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
//  background-image: linear-gradient(to top, #ffffff, #f5f5f5);
`






export default NavBar;

