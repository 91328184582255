import React from 'react';
import {Redirect, Route} from "react-router-dom";
import getLocalStorageItemOrNull, {getLocalStorageJsonItemOrNull} from "../../lib/getLocalStorageItemOrNull";
import {LOCAL_STORAGE_KEY_ERP_USER_NAME, LOCAL_STORAGE_KEY_TOKEN} from "../../resources/LocalStorageConstants";
import {RouteProps} from "react-router";
import {LINK_HOME, LINK_SIGN_IN} from "../../resources/LinkConstants";
import getSessionStorageItemOrNull, {getSessionStorageJsonItemOrNull} from "../../lib/getSessionStorageItemOrNull";

interface MainContainerProps extends RouteProps {
}

function AuthRoute(props: MainContainerProps) {

    const userName = getLocalStorageItemOrNull(LOCAL_STORAGE_KEY_ERP_USER_NAME) || getSessionStorageItemOrNull(LOCAL_STORAGE_KEY_ERP_USER_NAME)
    const token = getLocalStorageJsonItemOrNull(LOCAL_STORAGE_KEY_TOKEN) || getSessionStorageJsonItemOrNull(LOCAL_STORAGE_KEY_TOKEN)

    if (userName == null || token == null) {
        return (<Redirect to={{
            pathname: LINK_HOME,
            state: {referrer: props.location}
        }}/>)
    }

    return (
        <Route {...props}/>
    );
}

export default AuthRoute