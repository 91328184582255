import React from 'react'
import Button from '@material-ui/core/Button';
import styled from "styled-components";
import {utils, writeFile} from 'xlsx';
import {VihecleForm} from "../../../api/vihecle/getVihecleForm";

interface ExcelDownloadProps {
    data?: VihecleExcel[]
    form? : VihecleForm
}

interface TankWeight {
    type: string
    value: number
}

interface terminal {
    serialNo: string
}

interface VihecleExcel {
    uid?: string,
    vihecleName: string,
    vihecleType: string,
    tankProductorName: string,
    tankGasType: string,
    tankWeight?: TankWeight,
    tankWeightExcel?: string,
    tankSerialNo: string,
    productDate: string,
    createdAt: string,
    terminalSerialNo?: string,
    terminal?: terminal
}

// EXCEL 추출
function ExcelDownload(props: ExcelDownloadProps){
    function onClick(){
        if(props.data){
            console.log(props.data)
            // DB 데이터 변환
            props.data.map((data:VihecleExcel)=>{
                delete data.uid;
                data.vihecleType = props.form?.vihecleType.find(type => type.id === parseInt(data.vihecleType))?.title as string
                data.tankProductorName = props.form?.vihecleTankProductorNameType.find(type => type.id === parseInt(data.tankProductorName))?.title as string
                data.tankGasType = props.form?.vihecleTankGasType.find(type => type.id === parseInt(data.tankGasType))?.title as string
                data.tankWeightExcel = data.tankWeight?.value.toString().concat(props.form?.vihecleTankWeightType.find(type => type.id === parseInt(data.tankWeight?.type as string))?.title as string )
                data.terminalSerialNo = data.terminal?.serialNo
                delete data.terminal
                delete data.tankWeight
                console.log(data)
                return data
            })

            // 변환 데이터로 시트 생성 & header 에서 데이터 나열 순서 결정
            const ws = utils.json_to_sheet(props.data, {
                header: ['vihecleName', 'vihecleType', 'tankProductorName', 'tankGasType', 'tankWeightExcel', 'tankSerialNo', 'productDate', 'terminalSerialNo', 'createdAt']
            });
            const wb = utils.book_new();

            // 데이터 header 한글로 변환
            ['차명', '종류', '제조사', '가스', '용량', '탱크시리얼번호', '제조년월일', '단말시리얼번호', '등록일'].forEach((x, idx) => {
                const cellAdd = utils.encode_cell({c:idx, r:0});
                ws[cellAdd].v = x;
            })

            // EXCEL 각 셀 크기
            var wscols = [
                {wch:16},{wch:16},{wch:16},{wch:16},{wch:16},{wch:16},{wch:16},{wch:16},{wch:16}
            ];
            ws['!cols'] = wscols;

            // 셀에 합친 후 파일 다운로드
            utils.book_append_sheet(wb, ws, "Sheet1");
            writeFile(wb, `차량리스트_${Date.now()}.xlsx`);
        }
    }

    return (
        <ExcelButton onClick={onClick}>
           엑셀 다운로드
        </ExcelButton>
    )
}

const ExcelButton = styled(Button)`
  
  font-size: 0.875rem;
  font-weight : 600;
  padding: 0.5rem 1.125rem;
  border-radius: 4px;
  background-color: #fff;
  color : #45a16f;
  border: solid 1px #45a16f;
  line-height: 1.14;
  
`
export default ExcelDownload