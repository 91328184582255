import axios from "axios";
import {BaseResponse} from "../BaseResponse";
import Moment from 'moment';

export async function getSituationRoomModalDataList(vihecleName : string, modalDate: string = Moment().format("YYYY-MM-DD")) {
    const response = await axios.get<BaseResponse<Array<SituationRoomModalData>>>(`/situationRoom/${vihecleName}`, {
        params: {
            modalDate: modalDate
        }
    })
    return response.data
}

export interface SituationRoomModalData {
    tankPressure: number,
    gasLevel: number,
    gasLeakage: number,
    gpsData: string,
    sendDateTime: string
}
