import React, {ReactNode} from 'react';
import Header from "./component/Header";
import Footer from "./component/Footer";
import DefaultWrapperDiv from "../style/div/DefaultWrapperDiv";
import DefaultOuterDiv from "../style/div/DefaultOuterDiv";
import LoginFooter from "./component/LoginFooter";
import NavBar, {NavInfoProps} from "./component/NavBar";
import {
    LINK_VIHECLE, LINK_SITUATIONROOM, LINK_TEST
} from "../../resources/LinkConstants";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux";

interface ComponentProps {
    children: ReactNode;
    width?: number;
    height?: string;
}

function Index(props: ComponentProps) {
    const userName = useSelector((state: RootState) => state.headerTitleReducer.userName)
    const navProps: NavInfoProps[] = [

        {
            to: LINK_VIHECLE,
            style: {textDecoration: 'none'},
            text: "차량관리"
        },
        {
            to: LINK_SITUATIONROOM,
            style: {textDecoration: 'none'},
            text: "상황실"
        }

        // ,
        // {
        //     to: LINK_TEST,
        //     style: {textDecoration: 'none'},
        //     text: "테스트"
        // }
    ]

    return (
        <div style={{width: props.width, height: props.height}}>
            <Header/>
            {userName&&<NavBar navProps={navProps} activePoint={-1}/>}
            <DefaultOuterDiv>
                <DefaultWrapperDiv>
                    {props.children}
                </DefaultWrapperDiv>
            </DefaultOuterDiv>
            {/*<LoginFooter/>*/}
            {/*<Footer/>*/}
        </div>
    );
}

export default Index;