import React, {useState} from 'react';
import FixedWeightText from "../../components/style/text/FixedWeightText";
import IdCheck from "../signup/component/IdCheck";
import RedDot from "../../components/style/input/RedDot";
import styled from "styled-components";
import {ErrorInput} from "../../components/style/input/ErrorInput";
import {FormProvider, useForm} from "react-hook-form";
import {SignUpRequest} from "../../api/signup/signUp";
import {RequiredRulesWithoutValidation} from "../../components/form/BaseFormType";
import Input from "../../components/style/input/Input";
import Button from "../../components/style/button/Button";
import {UpdatePwRequest} from "../../api/member/updateMemberPw";
import MatchHorizontalSpace from "../../components/MatchHorizontalSpace";
import {InfoHeadText} from "../../components/style/text/InfoHeadText";
import {InputTitle} from "../../components/style/text/InputTitle";
import {ItemDiv} from "../../components/style/div/ItemDiv";

interface PwUpdateScreenProps {
    updateMemberPw: (...payload1: any[]) => Promise<boolean | undefined>

}



function PwUpdateScreen(props: PwUpdateScreenProps){

    const methods = useForm<UpdatePwRequest>({
        mode: "onBlur",
        criteriaMode: 'all',
    })
    const {watch} = methods
    const {password} = watch()
    const {isValid} = methods.formState

    function onSubmit(data: UpdatePwRequest){
        props.updateMemberPw(data)
    }

    return(
        <>

            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <CenterDiv>

                    <InfoHeadText>비밀번호</InfoHeadText>
                    <MatchHorizontalSpace marginBottom={"1.875rem"}/>
                    <ItemDiv>
                        <InputTitle>현재 비밀번호<RedDot/></InputTitle>
                        <InputField
                            name="passwordCheck"
                            ref={methods.register(RequiredRulesWithoutValidation)}
                        />
                    </ItemDiv>
                    <ItemDiv>
                        <InputTitle>새 비밀번호<RedDot/></InputTitle>
                        <ErrorInputField
                            name="password"
                            reference={methods.register({
                                required: true,
                                pattern: /^([A-Za-z0-9$@$!%*#?&]){4,16}$/
                            })}
                            type={"password"}
                            error={methods.errors.password?.type === "pattern"}
                            errorMessage={"비밀번호를 확인해주세요."}
                            placeholder={"4~16자 사이 영문, 숫자, 특수문자 로 만들어주세요"}
                        />

                    </ItemDiv>
                    <ItemDiv>
                        <InputTitle>새 비밀번호 확인<RedDot/></InputTitle>
                        <ErrorInputField
                            name="passwordConfirm"
                            reference={methods.register({
                                required: true,
                                validate: value => value === password
                            })}
                            type={"password"}
                            placeholder={"비밀번호 재입력"}
                            error={methods.errors.passwordConfirm?.type == "validate"}
                            errorMessage={"비밀번호가 일치하지 않습니다."}
                        />
                    </ItemDiv>

                    <NextButton
                        disabled={!isValid}
                        type={"submit"}
                    >
                        <FixedWeightText fontSize={"12px"} color={"var(--white)"}>변경하기</FixedWeightText>
                    </NextButton>


                </CenterDiv>
            </form>

        </>




    )

}

type GuideTextProps = {
    error?: boolean
}

const CenterDiv = styled.div`
  display: flex;
  flex-direction : column;
  justify-content: center;
  
  width: 35rem; 
  margin-top: 2.5rem;
`

const GuideText = styled(FixedWeightText).attrs({
    fontSize: "12px",
    fontWeight: "normal"
})<GuideTextProps>`
  margin-top: 8px;
  margin-left: 2px;
  height: 18px;
  color: ${(props) => (props.error && "var(--red)") || "var(--gray-4)"};
`

const ErrorInputField = styled(ErrorInput)`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  width: 35rem;
  height: 2.5rem;
`

const AccountInfoDiv = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 484px;
  height: 550px;
  border-radius: 4px;
  padding: 32px;
  border: solid 1px var(--gray-4);
  background-color: var(--white);
  margin-top: 52px;
`

const ErrorInputFieldWithGuide = styled(Input).attrs((props: InputFieldProps) => ({
    focusBorderColor: (props.error && "var(--red)") || "var(--blue)"
}))<InputFieldProps>`
  border-color: ${(props) => (props.error && "var(--red)") || "var(--gray-4)"};
`

const InputField = styled(ErrorInputFieldWithGuide)`
  width : 35rem;
  height: 2.5rem;
`

type InputFieldProps = {
    error?: boolean
}

const NextButton = styled(Button)`
  width: 35rem;
  height: 3rem;
  margin-top : 1.875rem;
  color: var(--white);
`

export default PwUpdateScreen;