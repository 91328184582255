import React, {ReactNode} from 'react';
import DefaultWrapperDiv from "../style/div/DefaultWrapperDiv";
import styled from "styled-components";
import AppBarMobile from "./component/AppBarMobile";
import Index from "./index";

interface IndexMobileProps{
    children: ReactNode;
}

// 모달이 바를 덮게하기위해 absoulte 로 하면 클릭이 안되는 현상있음
function IndexMobile(props: IndexMobileProps){
    return(
        <>
            <ContentDiv>
                {props.children}
            </ContentDiv>
            <BarDiv>
                <AppBarMobile />
            </BarDiv>
        </>
    );
}

const ContentDiv = styled.div`
  z-index: 20;
  position: relative;
  height: calc(100vh - 7.5rem);
  
  
`

const BarDiv = styled.div`  
  z-index: 10;
  

`

export default IndexMobile;