import React, {useState} from 'react';
import styled from 'styled-components';
import {LINK_SIGN_IN, LINK_SITUATIONROOM, LINK_USER} from "../../../resources/LinkConstants";
import { NavLink } from "react-router-dom";
import SidebarItem from "./SidebarItem";
import SettingsIcon from '@material-ui/icons/Settings';

interface SideBarProps {


}



function SideBar(props: SideBarProps){

    const menus = [
        { name: "회원정보수정", path: "/user" },
        { name: "비밀번호변경", path: "/user/pwUpdate" },
        { name: "알람설정", path: "/user/alarmUpdate" }

    ]
    return(
        <>
            <Side>
                <Menu>
                    <HeadDiv>
                        <SetIcon/><HeadText>설정하기</HeadText>
                    </HeadDiv>
                    {menus.map((menu, index) => {
                        return (
                            <NavLink
                                exact
                                style={{color: "gray", textDecoration: "none"}}
                                to={menu.path}
                                key={index}
                                activeStyle={{color: "black", fontWeight: "bold"}}
                            >
                                <SidebarItem
                                    name={menu.name}
                                />
                            </NavLink>
                        );
                    })}
                </Menu>
            </Side>
        </>




    )

}

const HeadDiv = styled.div`
  display: flex;  
`

const SetIcon = styled(SettingsIcon)`
  margin-right: 0.688rem;
  font-size: 1.625rem;
  ;
`

const Side = styled.div`
  display: flex;     
  flex-direction: column;  
  justify-content: flex-start;
  width: 12.5rem;
  margin-right: 2.5rem;
  margin-top: 2.5rem;
`

const Menu = styled.div`
  
  
  display: flex;
  flex-direction: column;
`

const HeadText = styled.div`
  font-size: 1.625rem;
  font-weight: bold;
  margin-bottom: 3.75rem;
`

export default SideBar;