import React from 'react';
import './style/Global.scss';
import Root from "./routes/Root"
import {GlobalStyle} from "./resources/Constants";
import {StylesProvider} from "@material-ui/styles"

function App() {
  return (
      <>
        <StylesProvider injectFirst>
          <GlobalStyle/>
          <Root/>
        </StylesProvider>
      </>
  )
}

export default App;
