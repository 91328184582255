import React, {useEffect, useState} from 'react';
import DaumPostcode, {AddressData} from 'react-daum-postcode';
import NewWindow from 'react-new-window'
import styled from "styled-components";
import Button from "../style/button/Button";
import FixedWeightText from "../style/text/FixedWeightText";


interface PostCodeComponentProps {
    buttonWidth?: string
    buttonHeight?: string
    handleValue?: (address: string) => void
    showForce?: boolean
}

function PostCodeButton(props: PostCodeComponentProps) {
    const [show, setShow] = useState(false)

    useEffect(() => {
        setShow(props.showForce || false)
    }, [props.showForce])

    function handleComplete(data: AddressData) {
        setShow(false)
        let fullAddress = data.zonecode + ' ' + data.address;
        let extraAddress = '';

        if (data.addressType === 'R') {
            if (data.bname !== '') {
                extraAddress += data.bname;
            }

            if (data.buildingName !== '') {
                extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
            }

            fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');

            if (props.handleValue != null) {
                props.handleValue(fullAddress)
            }
        }
    }

    const features = {
        width: 700,
        height: 500,
        left: 0,
        top: 0
    }

    return (
        <AddressButton
            onClick={() => setShow(true)}
            {...props}
        >
            <AddressButtonText>
                <FixedWeightText
                    fontSize={"0.75rem"}
                    fontWeight={500}
                    color={"var(--white)"}>주소 검색</FixedWeightText>
            </AddressButtonText>
            {show ?
                <NewWindow
                    title={"주소검색"}
                    center={"screen"}
                    features={features}
                    onUnload={() => setShow(false)}
                >
                    <DaumPostcode
                        onComplete={handleComplete}
                        height={features.height}
                        {...props}
                    />
                </NewWindow> : null}
        </AddressButton>
    );
}

const AddressButton = styled(Button)`
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: ${(props: PostCodeComponentProps) => props.buttonWidth};
  height: ${(props: PostCodeComponentProps) => props.buttonHeight || "3rem"};  
  background-color: var(--blue);
`
const AddressButtonText = styled(FixedWeightText)`
  font-size: 0.75rem;
  color: var(--white);
`
export default PostCodeButton