import {BrowserRouter, Redirect, Route, Switch, useLocation} from "react-router-dom";
import React, {useEffect} from "react";
import Index from "../components/layout";
import {
    LINK_HOME,
    LINK_SIGN_IN,
    LINK_SIGN_UP,
    LINK_SITUATIONROOM,
    LINK_VIHECLE,
    LINK_VIHECLE_REGIST,
    LINK_VIHECLE_MODIFY,
    LINK_USER, LINK_TEST, LINK_CHARGE
} from "../resources/LinkConstants";
import HomeScreen from "../pages/home/HomeScreen";
import LoginContainer from "../pages/login/LoginContainer";
import SignUp from "../pages/signup/SignUpContainer";
import SituationRoomContainer from "../pages/situationroom/SituationRoomContainer";
import VihecleContainer from "../pages/vihecle/VihecleContainer";
import VihecleRegistContainer from "../pages/vihecle/regist/VihecleRegistContainer";
import UserContainer from "../pages/user/UserContainer";
import AuthRoute from "../pages/main/AuthRoute";
import axios from "axios";
import getLocalStorageItemOrNull, {getLocalStorageJsonItemOrNull} from "../lib/getLocalStorageItemOrNull";
import {
    LOCAL_STORAGE_KEY_ERP_USER_NAME,
    LOCAL_STORAGE_KEY_IS_LOGGED_IN,
    LOCAL_STORAGE_KEY_TOKEN,
    LOCAL_STORAGE_KEY_ERP_USER_ID
} from "../resources/LocalStorageConstants";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../redux";
import {changeHeaderTitle} from "../redux/HeaderTitleModule";
import VihecleModifyContainer from "../pages/vihecle/regist/VihecleModifyContainer";
import TestContainer from "../pages/test/TestContainer";
import ChargeContainer from "../pages/charge/ChargeContainer";
import getSessionStorageItemOrNull, {getSessionStorageJsonItemOrNull} from "../lib/getSessionStorageItemOrNull";
import { BrowserView, MobileView } from 'react-device-detect'
import LoginScreenMobile from "../pages/login/LoginScreenMobile";
import Routing from "./Routing";

interface RootComponentProps {

}

function Root(props: RootComponentProps) {

    const {data: erpUser} = useSelector((state: RootState) => state.accountReducers.signInReducer.signInResponse)
    const {data: refreshToken} = useSelector((state: RootState) => state.refreshTokenReducer.refreshTokenResponse)
    const dispatch = useDispatch()


    const isLoggedIn = getLocalStorageJsonItemOrNull(LOCAL_STORAGE_KEY_IS_LOGGED_IN) || getSessionStorageJsonItemOrNull(LOCAL_STORAGE_KEY_IS_LOGGED_IN)


    if (isLoggedIn) {
        const userName = getLocalStorageItemOrNull(LOCAL_STORAGE_KEY_ERP_USER_NAME) || getSessionStorageItemOrNull(LOCAL_STORAGE_KEY_ERP_USER_NAME)
        const erpToken = getLocalStorageJsonItemOrNull(LOCAL_STORAGE_KEY_TOKEN) || getSessionStorageJsonItemOrNull(LOCAL_STORAGE_KEY_TOKEN)
        axios.defaults.headers.common['Authorization'] = erpToken && `Bearer ${erpToken.accessToken}`;
        dispatch(changeHeaderTitle({userName: userName}))
    }

    useEffect(() => {

        // 로그인 시
        if (erpUser) {
            axios.defaults.headers.common['Authorization'] = erpUser.token && `Bearer ${erpUser.token.accessToken}`
            if(erpUser.autoLogin) {
                localStorage.setItem(LOCAL_STORAGE_KEY_IS_LOGGED_IN, "true")
                localStorage.setItem(LOCAL_STORAGE_KEY_TOKEN, JSON.stringify(erpUser.token))
                localStorage.setItem(LOCAL_STORAGE_KEY_ERP_USER_NAME, erpUser.companyName)
                localStorage.setItem(LOCAL_STORAGE_KEY_ERP_USER_ID, erpUser.userId)
            } else {
                sessionStorage.setItem(LOCAL_STORAGE_KEY_IS_LOGGED_IN, "true")
                sessionStorage.setItem(LOCAL_STORAGE_KEY_TOKEN, JSON.stringify(erpUser.token))
                sessionStorage.setItem(LOCAL_STORAGE_KEY_ERP_USER_NAME, erpUser.companyName)
                sessionStorage.setItem(LOCAL_STORAGE_KEY_ERP_USER_ID, erpUser.userId)
            }
            dispatch(changeHeaderTitle({userName: erpUser.companyName}))
        }

        // access token 만료되어 다시 받을 시
        if (refreshToken) {
            
            // axios.defaults.headers.common['Authorization'] = `Bearer ${refreshToken.accessToken}`
            // if (erpUser.autoLogin) {
            //     localStorage.setItem(LOCAL_STORAGE_KEY_TOKEN, JSON.stringify(refreshToken))
            // } else {
            //     sessionStorage.setItem(LOCAL_STORAGE_KEY_TOKEN, JSON.stringify(refreshToken))
            // }
            // window.location.reload();
            // erpUser 는 로그인할때만 값이 들어옴.
            // 리프래시 토큰은 자동로그인 안하는 사람은 굳이 받을 필요 없을 듯

            axios.defaults.headers.common['Authorization'] = `Bearer ${refreshToken.accessToken}`
            localStorage.setItem(LOCAL_STORAGE_KEY_TOKEN, JSON.stringify(refreshToken))
            window.location.reload();
            // 리프래시토큰으로 access토큰 받아올때 리로드 되는건데 사용자 경험이 안좋을 수 있으므로 나중에 수정
            
        }
    }, [erpUser, refreshToken])

    // 1109?
    // 자동로그인 해제시 사용할 부분. 체크 해제시에 브라우저 닫을 시 로그아웃 되도록
    // useEffect(() => {
    //     const handleBeforeUnload = (event: BeforeUnloadEvent) => {
    //         localStorage.removeItem(LOCAL_STORAGE_KEY_ERP_USER_NAME)
    //         localStorage.removeItem(LOCAL_STORAGE_KEY_TOKEN)
    //         localStorage.setItem(LOCAL_STORAGE_KEY_IS_LOGGED_IN, "false")
    //     };
    //
    //     window.addEventListener('beforeunload', handleBeforeUnload);
    //
    //     return () => {
    //         window.removeEventListener('beforeunload', handleBeforeUnload);
    //     };
    // }, []);


    return (
        <BrowserRouter>
            <Routing isLoggedIn={isLoggedIn}/>
        </BrowserRouter>
    );

}

export default Root;