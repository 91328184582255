import axios from 'axios';
import {BaseResponse} from "../BaseResponse";

export async function getVihecle(vihecleId : string) {
    const response = await axios.get<BaseResponse<Vihecle>>(`/vihecle/${vihecleId}`)

    return response.data
}

interface TankWeight {
    type: string
    value: number
}

interface gasLeakageAlarm {
    alarm : boolean
    max : number
}

interface tankPressureAlarm {
    alarm : boolean
    max : number
    min : number
}

interface gasLevelAlarm {
    alarm : boolean
    max : number
    min : number
}

interface terminal {
    uid?: string
    serialNo: string
    gasLeakageAlarm?: gasLeakageAlarm
    tankPressureAlarm?: tankPressureAlarm
    gasLevelAlarm?: gasLevelAlarm
    transferCycleTime: string
}

export interface Vihecle {
    uid: string
    vihecleName: string
    vihecleType: string
    tankProductorName: string
    tankGasType: string
    tankWeight: TankWeight
    tankSerialNo: string
    productDate: string
    createdAt: string
    terminalSerialNo?: string
    gasLeakageAlarm?: gasLeakageAlarm
    tankPressureAlarm?: tankPressureAlarm
    gasLevelAlarm?: gasLevelAlarm
    terminal?: terminal
}