import React, {SyntheticEvent, useEffect, useRef, useState} from "react";
import {TerminalRowData} from "../../api/terminal/getTrailerList";
import {SituationRoomVihecle} from "../../api/situationroom/getSituationRoomVihecleList";
import {Switch, useHistory} from 'react-router-dom';
import {LINK_SITUATIONROOM, LINK_VIHECLE} from "../../resources/LinkConstants";
import {useLocation} from "react-router-dom";
import Modal from 'react-modal';
import styled from 'styled-components'
import Button from "../../components/style/button/Button";
import MapModal from "./components/MapModal";
import {SituationRoomModalData} from "../../api/situationroom/getSituationRoomModalDataList";
import ReactModalHeader from "../../components/modal/ReactModalHeader";
import {markerIcon} from "../../components/style/div/MarkerIcon";
import MatchHorizontalSpace from "../../components/MatchHorizontalSpace";
import {BrowserView, isMobile, MobileView} from "react-device-detect";
import {markerIconMobile} from "../../components/style/div/markerIconMobile";
import NavBar, {NavInfoProps} from "../../components/layout/component/NavBar";
import VihecleInfoNavBar, {VihecleInfoNavInfoProps} from "../../components/layout/component/VihecleInfoNavBar";
import SwipeableViews from "react-swipeable-views";
import ModalHeader from "../../components/modal/Header";
import ModalHeaderMobile from "../../components/modal/ModalHeaderMobile";
import MapModalMobile from "./components/MapModalMobile";


interface MapScreenProps {
    isMobile?: boolean
    rowData?: Map<string, SituationRoomVihecle>
    // { [s: string]: SituationRoomVihecle }
    map?: naver.maps.Map
    markers?: Map<string, naver.maps.Marker>
    setMarkers? : React.Dispatch<React.SetStateAction<Map<string, naver.maps.Marker>>>
    vihecleName? : string
    getModalDataList?: (vihecleName: string, modalDate: string) => void
    situationRoomModalDataList?: SituationRoomModalData[]
    modalIsOpen?:boolean
    setModalIsOpen : React.Dispatch<React.SetStateAction<boolean>>
    activeVihecleSerialNo?: string|null
    setActiveVihecleSerialNo?: React.Dispatch<React.SetStateAction<string|null>>
    showInfo: boolean
    setShowInfo : React.Dispatch<React.SetStateAction<boolean>>
}

Modal.setAppElement('#root');

function MapScreen(props: MapScreenProps) {



    const history = useHistory()
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const {markers, setMarkers} = props
//    const [modalIsOpen, setModalIsOpen] = useState(false);
    const {vihecleName} = props
    const {showInfo, setShowInfo} = props
    const myRef = useRef<HTMLDivElement>(null)
    const [showVihecleName, setShowVihecleName] = useState('flex');
    // const [vihecleNames, setVihecleNames] = useState<string[]>([])
    // const [tankWeights, settankWeights] = useState<string[]>([])

    // const [vihecleNames, setVihecleNames] = useState<Map<string, string>>(new Map())
    // const [tankWeights, settankWeights] = useState<Map<string, string>>(new Map())

    const vihecleNames = new Map<string, string>()
    const tankWeights = new Map<string, string>()
    const {modalIsOpen, setModalIsOpen} = props

    useEffect(() => {

        // 히스토리 변경을 감지하는 함수입니다.
        const unlisten = history.listen((location, action) => {
            if (action === 'POP') {
                setModalIsOpen(false); // 모달을 닫습니다.
            }
        });

        return () => {
            unlisten(); // 컴포넌트가 언마운트될 때 이벤트 리스너를 제거합니다.
        };
    }, [modalIsOpen, history]);


    useEffect(() => {
        if(props.rowData && props.map){
            const initMap = () => {
                 console.log("지도 시작 ")

                console.log(typeof props.rowData);
                console.log(props.rowData);
                function getClickHandler(serialNo:string) {
                    return function(e:Event) {
                        if(props.map && markers && vihecleNames && tankWeights){
                    //        var marker = markers[seq]
                            var marker = markers.get(serialNo)
                            var vihecleName = vihecleNames.get(serialNo)
                            var tankWeight = tankWeights.get(serialNo)
                            history.push(LINK_SITUATIONROOM + "?vn=" + vihecleName + "&tw=" + tankWeight)
                            //차량정보 얻으려면 serialNo 이용해서 vihecleName 으로 얻으면 되겠는데
                            //props.setActiveVihecleSerialNo?.(serialNo)
                            setModalIsOpen(true)

                    //        var infoWindow = infoWindows[seq]

                            /*
                            if (infoWindow.getMap()) {
                                infoWindow.close();
                            } else {
                                infoWindow.open(props.map, marker)
                                history.push(LINK_TERMINAL + "?vihecleName=" + marker.get('title'))
                                props.map.morph(marker.get('position'), 13)
                                console.log(markers.find(marker => marker.get('title') === '12345')?.get('position'))
                                setModalIsOpen(true)
                            }
                            */
                        }
                    }
                }


//                 const elHtmlMarker = htmlMarker.getElement()

                const test3 : {} = {"111": "111", "12345": "111", "242424": "111"}
                console.log(test3)

                console.log(Object.entries<{  }>(test3))



                // 단일로 받을것인가, 배열로 받을것인가
                props.rowData &&
//                Object.entries<SituationRoomVihecle>(props.rowData).forEach(([key, situationRoomVihecle])=>{
                props.rowData.forEach((situationRoomVihecle, key)=>{
                    if(props.map && markers && situationRoomVihecle.gpsData){
                        const gpsAry = situationRoomVihecle.gpsData.split(',')
                        const danger = +situationRoomVihecle.gasLeakage >= 50   // gasLeakageAlarm필요
                        const power = situationRoomVihecle.power !== '2'

                        // 모바일 장치에서는 markerIconMobile을, 그렇지 않으면 markerIcon을 사용
                        const iconFunction = isMobile ? markerIconMobile : markerIcon;
                        const marker = new naver.maps.Marker({
                            position: new naver.maps.LatLng(+gpsAry[0], +gpsAry[1]),
                            map: props.map,
                            title: situationRoomVihecle.vihecleName,
                            icon: iconFunction(false, danger, power, situationRoomVihecle.vihecleName, situationRoomVihecle.tankWeight.value+situationRoomVihecle.tankWeight.type)
                        })
                        naver.maps.Event.addListener(marker, 'click', getClickHandler(key))


                        // var bounds = props.map.getBounds()
                        // bounds.extend(marker.getPosition())
                        // props.map.fitBounds(bounds)

                        setMarkers?.(prev => new Map([...prev?.entries(), [key, marker]]))
                        vihecleNames.set(key, situationRoomVihecle.vihecleName)
                        tankWeights.set(key, situationRoomVihecle.tankWeight.value+situationRoomVihecle.tankWeight.type)
                        // setVihecleNames?.(prev => new Map([...prev?.entries(), [key, situationRoomVihecle.vihecleName]]))
                        // settankWeights?.(prev => new Map([...prev?.entries(), [key, situationRoomVihecle.tankWeight.value+situationRoomVihecle.tankWeight.type]]))

                        // markers.push(marker)
                        // vihecleNames.push(situationRoomVihecle.vihecleName)
                        // tankWeights.push(situationRoomVihecle.tankWeight.value+situationRoomVihecle.tankWeight.type)

                    }
                })
                if(props.map && markers){

                    const firstMarker = Array.from(markers.values())[0]
                    if(firstMarker) {
                        //        map.morph(searchMarkers[0].get('position'), 12)
                        props.map?.setCenter(firstMarker.getPosition())
                        props.map?.setZoom(13)
                    }


                    markers.forEach((marker, key)=>{
                        //naver.maps.Event.addListener(marker, 'click', getClickHandler(key))
                        var bounds = props.map?.getBounds()
                        bounds?.extend(marker.getPosition())
                        props.map?.fitBounds(bounds)
                        console.log("ㅋㅋㅋㅋㅋㅋㅋㅋㅋㅋㅋㅋㅋㅋㅋㅋㅋㅋㅋㅋ")
                    })

                    // for (var i=0, ii=markers.length; i<ii; i++) {
                    //     naver.maps.Event.addListener(markers[i], 'click', getClickHandler(i))
                    // }
                }



            }
            if(props.map)
            initMap()
        }
    }, [props.rowData])

    useEffect(() => {
        if(props.map && markers){

            const firstMarker = Array.from(markers.values())[0]
            if(firstMarker) {
                //        map.morph(searchMarkers[0].get('position'), 12)
                props.map?.setCenter(firstMarker.getPosition())
                props.map?.setZoom(13)
            }


            markers.forEach((marker, key)=>{
                var bounds = props.map?.getBounds()
                bounds?.extend(marker.getPosition())
                props.map?.fitBounds(bounds)
                console.log("ㅋㅋㅋㅋㅋㅋㅋㅋㅋㅋㅋㅋㅋㅋㅋㅋㅋㅋㅋㅋ")
            })

            // for (var i=0, ii=markers.length; i<ii; i++) {
            //     naver.maps.Event.addListener(markers[i], 'click', getClickHandler(i))
            // }
        }

    }, [markers])

    useEffect(() => {
        if(props.map && markers && vihecleName!==undefined){

            const tick = setTimeout(() => updateMarkers(props.map!!, markers), 100);

            return () => clearTimeout(tick)
        }
    }, [vihecleName])

    function showMarker(map:naver.maps.Map, marker:naver.maps.Marker) {
        var bounds = map.getBounds()
        bounds.extend(marker.getPosition())
        map.fitBounds(bounds)

        if (marker.getMap()) return;
        marker.setMap(map)
        doDisplayOnSearch(marker.get('title'), showInfo)
    }

    function hideMarker(map:naver.maps.Map, marker:naver.maps.Marker) {
        if (!marker.getMap()) return;
        marker.setMap(null)
    }

    function updateMarkers(map:naver.maps.Map, markers:Map<string, naver.maps.Marker>) {

        var searchMarkers = new Map<string, naver.maps.Marker>(
            [...markers].filter(([key, marker]) => marker.get('title').toLowerCase().indexOf(vihecleName?.toLowerCase()) !== -1)
        )


        // var searchMarkers = markers.filter((marker)=>marker.g et('title').indexOf(vihecleName) !== -1)
        const firstMarker = Array.from(searchMarkers.values())[0]

        if(firstMarker){
    //        map.morph(searchMarkers[0].get('position'), 12)
            map.setCenter(firstMarker.getPosition())
            map.setZoom(13)
        }
//        markers.filter((marker)=>data.vihecleName.indexOf(vihecleName) !== -1)
//        console.log(markers.find(marker => marker.get('title') === '12345')?.get('position'))

        markers.forEach((marker, key)=>{
            (marker.get('title').toLowerCase().indexOf(vihecleName?.toLowerCase()) !== -1) ? showMarker(map, marker) : hideMarker(map, marker)
        })
    }

//     function updateMarkers(map:naver.maps.Map, markers:naver.maps.Marker[]) {
//
//
//         var searchMarkers = markers.filter((marker)=>marker.get('title').indexOf(vihecleName) !== -1)
//         if(searchMarkers[0]){
//             //        map.morph(searchMarkers[0].get('position'), 12)
//             map.setCenter(searchMarkers[0].getPosition())
//             map.setZoom(13)
//         }
// //        markers.filter((marker)=>data.vihecleName.indexOf(vihecleName) !== -1)
// //        console.log(markers.find(marker => marker.get('title') === '12345')?.get('position'))
//
//         for (var i = 0; i < markers.length; i++) {
//             (markers[i].get('title').indexOf(vihecleName) !== -1) ? showMarker(map, markers[i]) : hideMarker(map, markers[i])
//         }
//     }

    function onShowInfoButtonClick(){
        if(props.map && markers && props.rowData){
            // markers.forEach((marker, key)=>{
            //     //doDisplay(marker.get('title'), showInfo)
            //     const showHideIcon = markerIcon(key===marker., +(props?.situationRoomVihecleList?.get(props?.activeVihecleSerialNo ?? "")?.gasLeakage ?? "0") >= 50, props?.situationRoomVihecleList?.get(props?.activeVihecleSerialNo ?? "")?.vihecleName ?? "없음", (props?.situationRoomVihecleList?.get(props?.activeVihecleSerialNo ?? "")?.tankWeight.value ?? "?") + (props?.situationRoomVihecleList?.get(props?.activeVihecleSerialNo ?? "")?.tankWeight.type ?? "?")) // gasLeakageAlarm필요
            //     props?.markers?.get(data.serialNo)?.setIcon(newIcon)
            //
            // })
            props.rowData.forEach((vihecle, key)=>{
                // 모바일 장치에서는 markerIconMobile을, 그렇지 않으면 markerIcon을 사용
                const iconFunction = isMobile ? markerIconMobile : markerIcon;
                const showHideIcon = iconFunction(key===props.activeVihecleSerialNo, +(vihecle.gasLeakage ?? "0") >= 50, vihecle.power!=='2',vihecle.vihecleName ?? "없음", (vihecle.tankWeight.value ?? "?") + (vihecle.tankWeight.type ?? "?"), key===props.activeVihecleSerialNo ? true : !showInfo) // gasLeakageAlarm필요
                markers?.get(key)?.setIcon(showHideIcon)
            })


//             for (var i = 0; i < markers.length; i++) {
// //                infoWindows[i].open(props.map, markers[i])
//                 //        console.log(i,markers[i].get('title'))
//                 //        console.log(i,"오픈")
//                 doDisplay(markers[i].get('title'), showInfo)
//             }
        }

        !showInfo ? setShowInfo(true) : setShowInfo(false)

    }
    function doDisplay(vihecleName:string, showInfo:string){
    	var con = document.getElementById(vihecleName);
    	if(con && showInfo=='flex'){
    		con.style.display = 'none';
    	}else{
    	    if(con){
    		    con.style.display = 'flex';
    		}
    	}
    }

    function doDisplayOnSearch(serialNumber:string, showInfo:boolean){
        	// var con = document.getElementById(serialNumber);
        	// if(con && showInfo){
        	// 	con.style.display = 'flex';
        	// }else{
        	//     if(con){
        	// 	    con.style.display = 'none';
        	// 	}
        	// }
        }

    function markerContent(showInfo:string, vihecleName:string, tankWeight:string){
        var markerContent = [
            '<div style="position:relative; display: flex; flex-direction: column; align-items: center; width: 8.688rem;">',
                '<div id=',vihecleName,' style="display: flex; flex-direction: column; align-items: center;">',
                    '<div style="display:',
                    showInfo,
                    ';',
                    'color: #000000;',
                    'width:8.688rem;',
                    'height:1.75rem;',
                    'box-sizing: border-box;',
                    'background-color:#4a90e2;',
                    'border-radius: 100px;',
                    'gap:0.1rem;',
                    'align-items: center;',
                    'text-align: center;',
                    'vertical-align: middle;',
                    'padding: 0 0.725rem 0 0.875rem;',
                    'z-index:4;',

                    'margin:0;">',

                        '<div style="overflow:hidden;',
                            'text-overflow: ellipsis;',
                            'white-space: nowrap;',
                            'color: #FFFFFF;',
                            'font-size:0.75rem;',
                            'float: left;',
                            'font-stretch:normal;',
                            'width: 10.938rem;',

                            'letter-spacing: normal;',
                            'text-align: left;',
                            'font-weight: 400;',
                            'vertical-align: middle;">',
                            vihecleName,
                        '</div>',

                        '<div style="',




                            'color: #ffeb39;',
                            'font-size:0.75em;',


                            'text-align:center;',
                            'vertical-align: middle;',
                            'float:right;">',
                            tankWeight,
                        '</div>',
                    '</div>',

                    '<div style="display:',showInfo,'; position: absolute; width: 0.063rem; height:1.375rem; margin: 1.375rem 0 -0.25rem 0; background-color: #4a90e2; z-index: 3;">',
                    '</div>',
                '</div>',

                '<div style="position: absolute; margin-top: 2.438rem; width: 1.5rem; height: 1.5rem; box-sizing: border-box; box-shadow: 0 1px 1px 0 rgba(0,0,0,0.5); border: solid 4px #fff; border-radius: 50%; background-color: #4a90e2; z-index: 2;"></div>',

//                        '<img src="marker1.png" alt="" style="margin: 0px; padding: 0px; border: 0px solid transparent; display: flex; max-width: none; max-height: none; -webkit-user-select: none; position: absolute; width: 30px; height: 30px; left: 0px; top: 0px;">',

                '</div>',

            '</div>'
        ].join('')

        return markerContent
    }





    //지도 사이즈 관련 스타일 130% 800px
      const mapStyle = !isMobile ? {
        // web
        width: 'calc(100% - 23.75rem)',
        height: 'calc(100vh - 7.35rem)',
        zIndex: "1",
        border: '1px solid var(--gray-4)'
      } : {
        // mobile
        width: 'calc(100%)',
        height: 'calc(58vh)',
        zIndex: "1"
      }

    const modalMapStyle = {
        width: "80%",
        height: "400px",
        zIndex: "1",
        marginLeft: "-407px",
        marginTop: "5px",
    };

    const customStyles : Modal.Styles = {
      overlay: {
          zIndex: 30
      },
        content: isMobile ? {
            // 모바일 환경
            width: '101%',
            height: '101%',
            top: '0px',
            left: '-1px',
            transform: 'translate(0, 0)',
            padding: '0',
            overflow: 'hidden',
            borderRadius: '0',
            border: 'solid 0px'

        } : {
            // 데스크탑 환경
            width: '80%',
            height: '90%',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            padding: '0',
            overflow: 'hidden'
        }
    }



    // interface Location {
    //     lat: number;
    //     lng: number;
    // }

    // const [location2, setLocation] = React.useState<Location>({ lat: 37.3595704, lng: 127.105399 });

    // useEffect(() => {
    //     if (navigator.geolocation) {
    //         navigator.geolocation.getCurrentPosition((position) => {
    //             setLocation({
    //                 lat: position.coords.latitude,
    //                 lng: position.coords.longitude
    //             });
    //         });
    //     }
    // }, []);


    return (
         <>
            <div id="map" style={mapStyle}>
                <div style={{position:"relative", zIndex:"2", float:"right"}}>
                    <ShowInfoButton onClick={onShowInfoButtonClick}>
                        {!showInfo ? <ButtonText>차량명 보기</ButtonText> : <ButtonText>차량명 끄기</ButtonText>}
                    </ShowInfoButton>
                </div>
            </div>

            {/*<div style={{position:"relative", zIndex:"3", overflow:"visible"}}>*/}

             {/*SituationRoomContainer 로 옮기자*/}
            <Modal style={customStyles} isOpen={modalIsOpen ?? false} onRequestClose={()=>setModalIsOpen(false)}>
                <BrowserView>
                    <ReactModalHeader title={ params.get("vn") } onCancel={()=>setModalIsOpen(false)}></ReactModalHeader>
                    <Divider/>
                    <MapModal
                        getModalDataList={props.getModalDataList}
                        situationRoomModalDataList={props.situationRoomModalDataList}
                        vihecleList={props.rowData}
                        activeVihecleSerialNo={props.activeVihecleSerialNo}
                    />
                </BrowserView>
                <MobileView>
                    <ModalHeaderMobile title={ params.get("vn") } onCancel={()=>setModalIsOpen(false)}></ModalHeaderMobile>
                    {/*<Switch>*/}
                    {/*    <Route*/}
                    {/*        path="/option"*/}
                    {/*        render={(routeProps) => (*/}
                    {/*            <MapModalMobile*/}
                    {/*                {...routeProps}*/}
                    {/*                getModalDataList={props.getModalDataList}*/}
                    {/*                situationRoomModalDataList={props.situationRoomModalDataList}*/}
                    {/*            />*/}
                    {/*        )}*/}
                    {/*    />*/}
                    {/*</Switch>*/}
                    <MapModalMobile
                        getModalDataList={props.getModalDataList}
                        situationRoomModalDataList={props.situationRoomModalDataList}

                    />

                </MobileView>
            </Modal>
            {/*</div>*/}

         </>
    )
}

const Divider = styled(MatchHorizontalSpace)`
  height: 1px;
 
  background-color: #dbdbde;

`

const ShowInfoButton = styled(Button)`
  width: 5.688rem;
  height: 2.5rem;
  margin-top : 0.938rem;
  margin-right : 0.938rem;
  color: #ffffff;
  background: #333333;
  ${isMobile && `
    width: 4.688rem;
    height: 2.0rem;
  `}

`

const ButtonText = styled.text`
  font-size: 0.85rem;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  ${isMobile && `
    font-size: 0.7rem;
  `}
`

const BigCircle = styled.div`  
    width: 1.5rem;
    height: 1.5rem;
    flex-grow: 0;    
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
    border: solid 4px #fff;
    background-color: #4a90e2;  
`

const Test1 = styled.div`
  background-color: #eb5757;
`

const Test2 = styled.div`
  background-color: #00ff19;
`

export default React.memo(MapScreen);