import React from 'react';
import styled from "styled-components";

interface HorizontalSpaceProps {
    height?: string
    marginTop?: string
    marginBottom?: string
}

function MatchHorizontalSpace(props: HorizontalSpaceProps) {
    return (
        <StyledDiv {...props}/>
    );
}

export default MatchHorizontalSpace

const StyledDiv = styled.div`
  height: ${(props: HorizontalSpaceProps) => props.height || "1px"};
  margin-top: ${(props: HorizontalSpaceProps) => props.marginTop || "0"};
  margin-bottom: ${(props: HorizontalSpaceProps) => props.marginBottom || "0"};
  
  //width: 1080px;
  
  background-color: var(--gray-7);
`