import React from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import {isMobile} from "react-device-detect";

interface GaugeBarProps{
    gasLevel? : string

}

function GaugeBar({gasLevel}: GaugeBarProps) {
    return (
        <div style={{ width: isMobile ? "2.5rem" : "3.625rem"}}>
            <CircularProgressbar
                value={+(gasLevel ?? 0)}
                text={`${gasLevel ?? ''}%`}
                strokeWidth={10}
                styles={{
                    root: {stroke: `#14bdbd`,},
                    path: {
                        stroke: `#14bdbd`,
                        strokeLinecap: "butt",
                        transition: "stroke-dashoffset 0.5s ease 0s",
                    },
                    trail: {
                        stroke: "#d6d6d6",
                        strokeLinecap: "round",
                    },
                    text: {
                        fill: "#14bdbd",
                        fontSize: "1.4rem",
                        fontWeight: "500",
                    },

                }}
                counterClockwise

            />
        </div>
    );
};

export default GaugeBar;
