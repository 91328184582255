import axios from 'axios';
import {BaseResponse} from "../BaseResponse";

export async function checkDuplicateVihecleName(vihecleName: string) {
    const response = await axios.get<BaseResponse<boolean>>("/vihecle/checkDuplicate?", {
        params: {
            vihecleName: vihecleName
        }
    })

    return response.data
}