import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {LINK_HOME, LINK_SIGN_IN, LINK_SITUATIONROOM, LINK_USER} from "../../resources/LinkConstants";
import Sidebar from "./components/SideBar";
import {BrowserRouter, Switch, Route, Redirect, RouteComponentProps, useHistory} from "react-router-dom";
import UserUpdateScreen from "./UserUpdateScreen";
import PwUpdateScreen from "./PwUpdateScreen";
import ChargeScreen from "../charge/ChargeScreen";
import AlarmUpdateScreen from "./AlarmUpdateScreen";
import SignUp from "../signup/SignUpContainer";
import {useAsync2} from "../../hooks/useAsync2";
import {updateMemberPw} from "../../api/member/updateMemberPw";
import {useAsync} from "../../hooks/useAsync";
import {getSignUpForm} from "../../api/signup/getSignUpForm";
import AlarmUpdateContainer from "./AlarmUpdateContainer";
import {getMember} from "../../api/member/Member";
import {updateMember} from "../../api/member/updateMember";
import {updateMemberMobile} from "../../api/member/updateMemberMobile";
import SignUpScreen from "../signup/SignUpScreen";



interface UserContainerProps{

}

function UserContainer(props: UserContainerProps & RouteComponentProps){

    const {execute: updateMemberPwExecute, status: updateMemberStatus} = useAsync2(updateMemberPw)
    const {data: form} = useAsync(getSignUpForm, true)
    const {data: getMemberData} = useAsync(getMember, true)
    const {execute: updateMobileExecute, status: updateMobileStatus} = useAsync(updateMemberMobile)
    const {execute: updateMemberExecute, status: updateStatus} = useAsync(updateMember)

    const history = useHistory();

    useEffect(() => {
        if (updateMemberStatus === 'success') {
            alert("회원정보가 변경되었습니다")
            history.replace(LINK_SITUATIONROOM)
        }
    }, [updateMemberStatus])

    useEffect(() => {
        if (updateMobileStatus === 'success') {
            alert("핸드폰 번호가 변경되었습니다")
            history.replace(LINK_SITUATIONROOM)
        }
    }, [updateMobileStatus])

    useEffect(() => {
        if (updateStatus === 'success') {

            alert("수정되었습니다")
            history.replace(LINK_SITUATIONROOM)
        }
    }, [updateStatus])



    return(
        <>

                <Center>
                    <Sidebar/>

                    <Route exact path={`${props.match.path}`} render={()=><UserUpdateScreen form={form}
                                                                                            isUpdate={getMemberData}
                                                                                            updateMember={updateMemberExecute}/>}/>
                    <Route exact path={`${props.match.path}/pwUpdate`} render={()=><PwUpdateScreen updateMemberPw={updateMemberPwExecute}/>} />
                    <Route exact path={`${props.match.path}/alarmUpdate`} render={()=><AlarmUpdateScreen
                        mobile1={getMemberData?.mobile1}
                        mobile2={getMemberData?.mobile2}
                        mobile3={getMemberData?.mobile3}
                        form={form}
                        updateMobile={updateMobileExecute}
                    />}/>
                    {/*<Route exact path={`${props.match.path}/charge`} component={ChargeScreen}/>*/}




                </Center>

        </>




    )

}

const Center = styled.div`
  min-height: 92vh;
  display: flex;
  flex-direction: row;
  
`

export default UserContainer;