import axios from 'axios';
import {BaseResponse} from "../BaseResponse";
import {MemberPhoneNumber} from "./Member";


export async function updateMemberMobile(request: UpdateMobileRequest) {
    const response = await axios.put<BaseResponse<boolean>>(`/member/updateMobile`, request)

    return response.data
}

export interface UpdateMobileRequest {
    mobile1: MemberPhoneNumber
    mobile2?: MemberPhoneNumber
    mobile3?: MemberPhoneNumber
}
