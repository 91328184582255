import React from 'react'
import OptionScreen from "./OptionScreen";

interface OptionContainerProps {


}

const OptionContainer = (props : OptionContainerProps) => {

    return (
        <>
            <OptionScreen/>
        </>
    )

}

export default OptionContainer;