import React, {useEffect, useState} from 'react';
import VihecleHeaderScreen from './VihecleHeaderScreen';
import VihecleListScreen from './VihecleListScreen';
import {useAsync} from "../../hooks/useAsync";
import {getVihecleList, Vihecle} from "../../api/vihecle/getVihecleList";
import {getVihecleForm} from "../../api/vihecle/getVihecleForm";
import {FormProvider, useForm} from "react-hook-form";

interface VihecleContainerProps {

}

// 차량 관리 컨테이너
function VihecleContainer(props: VihecleContainerProps) {

    const {data: vihecleList} = useAsync(getVihecleList, true)
    const {data: form} = useAsync(getVihecleForm, true)
    const [searchVihecleList, setSearchVihecleList] = useState<Vihecle[]>([])

    type VihecleSearchRequest = {
        searchType : number
        searchQuery : string
        allCheck : boolean
        LH2Check : boolean
        LNGCheck : boolean
        LPGCheck : boolean
    }

    const methods = useForm<VihecleSearchRequest>({
        mode: "onBlur",
        criteriaMode: 'all'
    })

    const {watch} = methods

    // 헤더 검색 버튼
    function headerSearchButton(){
        if(vihecleList && watch()?.searchQuery!==undefined){
            switch(watch()?.searchType){
                case 1:
                    const querySearchData = vihecleList?.filter((data)=>data.vihecleName?.toLowerCase().indexOf(watch()?.searchQuery.toLowerCase()) !== -1)
                    const gasSearchData = querySearchData.filter((data)=>(watch()?.LH2Check && data.tankGasType.indexOf('1') !== -1) || (watch()?.LNGCheck && data.tankGasType.indexOf('2') !== -1) || (watch()?.LPGCheck && data.tankGasType.indexOf('3') !== -1))
                    setSearchVihecleList(gasSearchData)
                    break
                case 2:
                    // 시리얼번호 검색 시 serialNo 가 없으면 검색 안 됨. but 공백으로 검색시 검색 됨.
                    const querySearchData2 = vihecleList?.filter((data)=>(watch()?.searchQuery ? (data.terminal?.serialNo && data.terminal?.serialNo?.indexOf(watch()?.searchQuery) !== -1) : data.terminal?.serialNo?.indexOf(watch()?.searchQuery) !== -1))
                    const gasSearchData2 = querySearchData2.filter((data)=>(watch()?.LH2Check && data.tankGasType.indexOf('1') !== -1) || (watch()?.LNGCheck && data.tankGasType.indexOf('2') !== -1) || (watch()?.LPGCheck && data.tankGasType.indexOf('3') !== -1))
                    setSearchVihecleList(gasSearchData2)
                    break
            }
        }
    }

    // 등록일 뒷 부분 삭제
    vihecleList &&
        vihecleList.map((vihecleList,index) => {
           vihecleList.createdAt = vihecleList.createdAt.substr(0,10)
        })

    // 서버에서 받아온 vihecleList 처음에 setSearchVihecleList 해서 사용
    useEffect(() => {
        if(vihecleList){
            setSearchVihecleList(vihecleList)
        }
    }, [vihecleList])

    return (
    <>
        <FormProvider {...methods} >
            <form>
                <VihecleHeaderScreen
                    onClickSearchButton={headerSearchButton}
                />
                <VihecleListScreen
                    vihecleList = {searchVihecleList}
                    form = {form}
                />
            </form>
        </FormProvider>
    </>
    )
}

export default VihecleContainer