export function getSessionStorageItemOrNull(key: string) {
    return sessionStorage.getItem(key)
}

export function getSessionStorageJsonItemOrNull(key: string) {
    const row = sessionStorage.getItem(key)
    if (row && row.length > 0) {
        return JSON.parse(row)
    }
    return null
}

export default getSessionStorageItemOrNull