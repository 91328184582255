import React, {useEffect, useRef, useState} from 'react';
import styled, {ThemeProvider} from "styled-components";
import Input from "../../components/style/input/Input";
import {useForm, useFormContext} from "react-hook-form";
import {SituationRoomVihecle} from "../../api/situationroom/getSituationRoomVihecleList";
import BaseInput from "../../components/style/input/BaseInput";
import {ErrorInput} from "../../components/style/input/ErrorInput";
import {InputWithVihecleCount} from "../../components/style/input/InputWithVihecleCount";

import {Card, Chip, Typography} from '@material-ui/core';
import FixedWeightText from "../../components/style/text/FixedWeightText";
import {LINK_SITUATIONROOM} from "../../resources/LinkConstants";
import {useHistory} from "react-router-dom";
import Point = naver.maps.Point;
import LatLng = naver.maps.LatLng;
import {Client} from "@stomp/stompjs";
import MemoText from "../../components/style/text/MemoText";
import GaugeChart from "../../components/style/bar/GaugeBar";
import {VehicleCard} from "../../components/style/div/VihecleCard";
import {DangerText} from "../../components/style/text/DangerText";
import {markerIcon} from "../../components/style/div/MarkerIcon";
import {isMobile} from "react-device-detect";
import {markerIconMobile} from "../../components/style/div/markerIconMobile";




interface VihecleListScreenProps {
    isMobile?: boolean
    situationRoomVihecleList?: Map<string, SituationRoomVihecle>
    setModalIsOpen : React.Dispatch<React.SetStateAction<boolean>>
    map?: naver.maps.Map
    markers?: Map<string, naver.maps.Marker>
    activeVihecleSerialNo?: string|null
    setActiveVihecleSerialNo?: React.Dispatch<React.SetStateAction<string|null>>
    showInfo: boolean
}

function MapVihecleListScreen(props: VihecleListScreenProps){

    const {register, errors, watch} = useFormContext()
    const {vihecleName} = watch()
    const {setModalIsOpen} = props
    const history = useHistory()

    const [searchName, setSearchName]  = useState<string>(vihecleName)

    useEffect(() => {
        const tick = setTimeout(() => setSearchName(vihecleName), 100);

        return () => clearTimeout(tick)
    },[vihecleName])

    function ShowModal(data:SituationRoomVihecle){
        history.push(LINK_SITUATIONROOM + "?vn=" + data.vihecleName + "&tw=" + data.tankWeight.value+data.tankWeight.type)
        setModalIsOpen(true)
    }

    function MoveToCenter(data:SituationRoomVihecle, index:string){
        if(data.gpsData) {
            const gpsAry = data.gpsData?.split(',')
            props.map?.panTo(new LatLng(+gpsAry[0], +gpsAry[1]),{easing:"easeOutCubic"})
        }
//        props.map?.morph(new Point(+gpsAry[0], +gpsAry[1]))

        // 모바일 장치에서는 markerIconMobile을, 그렇지 않으면 markerIcon을 사용
        const iconFunction = isMobile ? markerIconMobile : markerIcon;

        const danger = +data.gasLeakage >= 50   // gasLeakageAlarm필요
        const newIcon = iconFunction(true, +data.gasLeakage >= 50, data.power!=='2', data.vihecleName, data.tankWeight.value+data.tankWeight.type, true) // gasLeakageAlarm필요
        const returnIcon = iconFunction(false, +(props?.situationRoomVihecleList?.get(props?.activeVihecleSerialNo ?? "")?.gasLeakage ?? "0") >= 50, (props?.situationRoomVihecleList?.get(props?.activeVihecleSerialNo ?? "")?.power ?? "1") !== '2',props?.situationRoomVihecleList?.get(props?.activeVihecleSerialNo ?? "")?.vihecleName ?? "없음", (props?.situationRoomVihecleList?.get(props?.activeVihecleSerialNo ?? "")?.tankWeight.value ?? "?") + (props?.situationRoomVihecleList?.get(props?.activeVihecleSerialNo ?? "")?.tankWeight.type ?? "?"), props.showInfo) // gasLeakageAlarm필요
        props?.markers?.get(data.serialNo)?.setIcon(newIcon)

        // 현재 선택되어 있는 아이콘은 원래 아이콘으로 복구
        props?.markers?.get(props.activeVihecleSerialNo ?? "")?.setIcon(returnIcon)

        // 선택한 아이콘
        props.setActiveVihecleSerialNo?.(index === props.activeVihecleSerialNo ? null : index);


    }

    function mapFilter() {
        if(props.situationRoomVihecleList) {
            // console.log("SearchName : " + searchName)
            // props.situationRoomVihecleList.forEach((value,key) => {
            //     console.log(`Key: ${key}, VihecleName: ${value.vihecleName}`)
            // })
            const filterData = new Map<string, SituationRoomVihecle>(
                [...props.situationRoomVihecleList].filter(([k, v]) => v.vihecleName.toLowerCase().indexOf(searchName?.toLowerCase()) !== -1)
            )

            return filterData
        }
    }

    const [cc, setCc] = useState<string>("var(--white)")
    const [ccb, setCcb] = useState<string>("a")

    useEffect(()=>{
        setCc("var(--blue)")
        tick = setTimeout(()=>setCc("var(--white)"), 1000);
    }, [ccb])

    let tick : NodeJS.Timeout
    const cardRef = useRef<Map<string, HTMLDivElement>>(new Map<string, HTMLDivElement>()) as React.MutableRefObject<Map<string, HTMLDivElement>>
    function clicktest(){
        // const ok153 = cardRef.current.get("111")
        // if(cardRef!==undefined && cardRef.current.get("111")!==undefined) {
        //     clearTimeout(tick)
        //     cardRef.current.get("111")!!.style.backgroundColor = "var(--blue)"
        //     //tick = setTimeout(()=>cardRef.current.get("111")!!.style.backgroundColor = "var(--white)", 1000);
        //
        //
        //
        // }
        // clearTimeout(tick)  // 이거 말고 useEffect 로 해야겠네..
        setCcb("b")

        const ok1 = { ...cardRef.current?.get("111")?.style!!, backgroundColor:"2414"}
        console.log(ok1)
        const hi = {...cardRef.current?.get("111")!!, ok1}
        console.log(hi)
        console.log(cardRef)
        cardRef.current.set("111", hi)

    }

    const ok = "124-1241"
    console.log(ok)
    console.log(typeof ok)
    console.log(+ok)
    console.log(typeof +ok)


    // const cardRef = useRef<HTMLDivElement[]>([]) as React.MutableRefObject<HTMLDivElement[]>
    // function clicktest(){
    //     cardRef.current[111].style.backgroundColor = "var(--gray-5)"
    //
    // }

        //"var(--gray-5)"
    const cardRefMap = new Map<string, React.MutableRefObject<HTMLDivElement>>()


    function vihecleCardList(){



        const result: JSX.Element[] = []
        const filterVihecleCardList = mapFilter()

        filterVihecleCardList?.forEach((data, key) => {
            // const cardRef = useRef() as React.MutableRefObject<HTMLDivElement>

            let ok = "2142-1242"
            result.push(
                <VehicleCard key={key} active={key === props.activeVihecleSerialNo} danger={+data.gasLeakage >= 50} power={data.power!=="2"} ref={(el : HTMLDivElement) => cardRef.current.set(key, el)} onClick={()=>{ MoveToCenter(data, key) }
                }>
                    <GaugeDiv>
                        <GaugeChart gasLevel={data.gasLevel}/>
                    </GaugeDiv>

                    <DivideDiv/>

                    <ContentDiv>
                        <ItemDiv>
                            {/*
                            {

                                (data.power === "1" || data.power === "BAT") &&
                                <Chip label={"ON"} style={{
                                    backgroundColor: 'var(--green)',
                                    color: 'var(--white)',
                                    fontSize: '5px',
                                    marginLeft: '5px',
                                    width: '31px'
                                }} size={'small'}/>
                            }

                            {
                                (data.power === "2" || data.power === "DC") &&
                                <Chip label={"Off"} style={{
                                    backgroundColor: 'var(--gray-3)',
                                    color: 'var(--white)',
                                    fontSize: '5px',
                                    marginLeft: '5px',
                                    width: '31px'
                                }} size={'small'}/>
                            }

                            {
                                !data.power &&
                                <Chip label={"???"} style={{
                                    backgroundColor: 'var(--red)',
                                    color: 'var(--white)',
                                    fontSize: '5px',
                                    marginLeft: '5px',
                                    width: '31px'
                                }} size={'small'}/>
                            }
                            */}

                            {/*<VihecleNameText onClick={()=>ShowModal(data)}>*/}
                            {/*    {data.vihecleName}*/}
                            {/*</VihecleNameText>*/}
                            <VihecleNameDiv>
                                <MemoText textType={"vihecleName"} data={`${data.vihecleName}`} onClick={()=>ShowModal(data)}/>
                            </VihecleNameDiv>
                            <WeightChip>
                                <InputTitle>
                                    {data.tankWeight?.value}{data.tankWeight?.type}
                                </InputTitle>
                            </WeightChip>
                            {
                                !data.gpsData &&
                                <InputTitle style={{color: 'var(--red)'}}>위치없음</InputTitle>
                            }
                        </ItemDiv>
                        <ItemDiv>
                            {/*<MemoText textType={"dataText"} data={`잔량 ${data.gasLevel}`}/>*/}
                            {/*gasLeakageAlarm필요*/}
                            <DangerText danger={+data.gasLeakage >= 50}>
                                {+data.gasLeakage >= 50 ? '위험' : '안전'}
                            </DangerText>
                            {/*<MemoText textType={"dataText"} data={`가스 ${data.gasLeakage}`}/>*/}
                            <DivideSmallDiv/>
                            <MemoText textType={"dataText"} data={`압력 ${data.tankPressure}`}/>

                        </ItemDiv>
                    </ContentDiv>
                </VehicleCard>

            )
        })

        return result

    }




    return(
        <>
            {/*<button onClick={clicktest}>버튼테스트트트트트</button>*/}
            <ListDiv>
                <InputCount
                    name="vihecleName"
                    reference={register({required: true, minLength: 1})}
                    placeholder={"검색어를 입력하세요"}
                     alertText={`
                         ${ mapFilter()?.size ?? "0" } /
                         ${ props?.situationRoomVihecleList?.size ?? "0" } 개
                     `}
                />

                <CardDiv>

                    {
                        vihecleCardList()


                    }




                </CardDiv>


            </ListDiv>


        </>
    )
}

const VihecleNameText = styled(FixedWeightText)`
  width: 40%;
  font-size: 16px;
  font-weight: 400;
  margin : 10px;
  cursor: pointer;
  text-overflow: ellipsis;
  &:hover {
    text-decoration: underline; 
   
  }
`
const WeightChip = styled.div`
  
    width: 2.563rem;
    height: 0.94rem;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    
    border-radius: 13px;
    background-color: #333;
    
`

const InputTitle = styled.text`
  
  
  height: 0.75rem;
  flex-grow: 0;  
  font-size: 0.688rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  ${isMobile && `
    font-size: 0.625rem;
  `}
  
  
`

const CardDiv = styled.div`
  box-sizing: border-box;
  width: ${isMobile ? '100%' : '23.75rem'};
  height: calc(100% - 2.375rem);
  overflow-y: scroll;
  position: relative;
  
  padding: 0.625rem 1.25rem 0.625rem 1.25rem;

  ${isMobile && `
    padding: 0.125rem 0.5rem 0em 0.5rem;
  `}
  
  /* 스크롤바의 넓이를 구하여 padding-right에 적용합니다. */
  &::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--blue-2);
    border-radius: 0.3rem;
  }
  
`

const VihecleNameDiv = styled.div`
  max-width: 10.438rem;      
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  
`

const ItemDiv = styled.div`
  height: 1rem;
  gap: 5px;
  margin-bottom: 0.5rem;
  display: flex;  
  align-items: center;      
  
`

const GaugeDiv = styled.div`
  
`

const ContentDiv = styled.div`
  
  
  width: 13.313rem;
  height: 2.375rem;
  margin: 0.625rem 0 0.625rem 0;
  ${isMobile && `
    margin: 0 0 0 0;
  `}
`

const DivideDiv = styled.div`
  width: 0.063rem;
  height: 2.375rem;
  margin: 0.625rem;
  opacity: 0.5;
  background-color: #d9d9d9;
  ${isMobile && `    
    height: 1.875rem;
    margin: 0.375rem 0.625rem 0rem;
  `}
  
`

const DivideSmallDiv = styled.div`
  width: 0.063rem;
  height: 0.688rem;  
  background-color: #ccc;
  
`

const InputCount = styled(InputWithVihecleCount)`
  display: flex;
  box-sizing: border-box;
  width: 23.75rem;
  height: 3.375rem;
  border-radius: 0;
  border-left: white;
  border-top: white;
  border-right: white;
  border-bottom: 1px solid #dbdbde;;
  background-color: white;
  ${isMobile && `
    width: 100%;
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;
    height: 2.375rem;
    border-bottom: 0px solid #dbdbde;;
  `}
`


const EditText = styled(Input)`
    width: 300px;
    height: 48px;
    font-size: 14px;
    padding: 14px 16px 14px 16px;
    align-self: center;
    margin-top: 5px;
`

const ListDiv = styled.div`
  
  height: calc(100vh - 7.35rem);
  width: 23.75rem;
  border : 1px solid #dbdbde;
  
  ${isMobile && `
    height: calc(42vh - 3.2rem);
    width: 100%;
    border : 1px solid #dbdbde;  
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin-top: -8px;
    z-index: 3;
  `}
  
`

const test = styled.div`
  width: 300px;
  height: 300px;
  background-color: #eb5757;
`




const SearchResult = styled.div`
    display: flex;
    flex-direction: row-reverse;  
`


export default React.memo(MapVihecleListScreen);
