import React from "react";
import styled from 'styled-components';
import Title from "../../title/Title";
import {Link} from "react-router-dom";
import MatchHorizontalSpace from "../../MatchHorizontalSpace";

interface ComponentProps {

}

function Header(props: ComponentProps) {
    return (
        <>
            <Div>
                {/*<Link to={LINK_TANK} style={{textDecoration: 'none'}}>*/}
                    <Title/>
                {/*</Link>*/}
            </Div>
            <MatchHorizontalSpace height={"1px"}/>
        </>
    );
}

const Div = styled.div`
  display: flex;
//  background-image: linear-gradient(to top, #1450a5, #14469b);
  height: 3.75rem;
  align-content: center;
  align-items: center;
  justify-content: center;
`
export default Header;