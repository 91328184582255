import React from 'react';
import styled from "styled-components";
import logo from "../../assets/images/header/img_logo@3x.png";
import invoice_picture from "../../assets/images/header/invoice.png";
import invoice_picture2x from "../../assets/images/header/invoice@2x.png";
import invoice_picture3x from "../../assets/images/header/invoice@3x.png";
import DefaultWrapperDiv from "../style/div/DefaultWrapperDiv";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {LINK_CHARGE, LINK_HOME, LINK_SIGN_IN, LINK_SITUATIONROOM, LINK_USER} from "../../resources/LinkConstants";
import {logOut} from "../../redux/account/logoutModule";
import {RootState} from "../../redux";
import Chip from '@material-ui/core/Chip';

interface TitleComponentProps {
}


function Title(props: TitleComponentProps) {
    const userName = useSelector((state: RootState) => state.headerTitleReducer.userName)
    const dispatch = useDispatch()

    function onClickLogOut() {
        dispatch(logOut())
    }

    return (
        <DefaultWrapperDiv direction={"row"} justifyContent={"space-between"}>
            <TitleLeftDiv>
                {/*<LogoImage src={logo}/>*/}
                <Link to={LINK_SITUATIONROOM} style={{textDecoration: 'none'}}>
                <TitleText>TONGSORI</TitleText>
                </Link>
            </TitleLeftDiv>
            {!userName ?
                <TitleRightDiv>
                    <Link to="/signIn" style={{textDecoration: 'none'}}>
                        <LoginChip
                            label={<LoginText>로그인</LoginText>}
                            variant="outlined"
                            clickable
                        />
                    </Link>
                    <Link to="/signUp" style={{textDecoration: 'none'}}>
                        <SignUpChip
                            label={<SignUpText>회원가입</SignUpText>}
                            color="primary"
                            clickable
                        />
                    </Link>
                </TitleRightDiv> :
                <TitleRightDiv>
                    <Link to={LINK_CHARGE} style={{textDecoration: 'none', justifyContent: 'center'}}>
                        <PictureImage src={invoice_picture} srcSet={`${invoice_picture2x} 2x,${invoice_picture3x} 3x`}/>
                    </Link>
                    <Link to={LINK_USER} style={{textDecoration: 'none', justifyContent: 'center'}}>
                        <NameChip
                            label={<CompanyNameText>{userName && `${userName}`}</CompanyNameText>}
                            variant="outlined"
                            clickable
                        />
                        {/*<NameText>{userName && `${userName}님`}</NameText>*/}
                    </Link>
                    <Link to={LINK_HOME}
                          onClick={onClickLogOut}
                          style={{textDecoration: 'none', justifyContent: 'center'}}>
                        <LogoutChip
                            label={<LogoutText>로그아웃</LogoutText>}
                            clickable
                        />

                    </Link>
                </TitleRightDiv>
            }
        </DefaultWrapperDiv>
    )
}

const SignUpChip = styled(Chip)`        
  width: 5.063rem;
  height: 2.25rem;
  border-radius: 50px;  
  background-color: var(--blue-2);
  color: #ffffff;  
  margin-left: 0.5rem;
`

const LoginChip = styled(Chip)`        
  width: 4.313rem;
  height: 2.25rem;
  border-radius: 50px;  
  background-color: #f8f9fd;  
  border: solid 1px rgba(74, 144, 226, 0.1);
`

const LogoutChip = styled(Chip)`        
  width: 5.063rem;
  height: 2.25rem;
  border-radius: 50px;  
  background-color: #f3f4f4;    
`

const NameChip = styled(Chip)`        
  width: auto;
  height: 2.25rem;
  border-radius: 50px;  
  background-color: #fff;
  border: solid 1px #333;
  margin: 0 0.625rem;
`

const CenterDiv = styled.div`
    display: flex;
    align-items: center;
`

const TitleLeftDiv = styled.div`
    display: flex;
    align-items: center;
  margin-left: 1.25rem;
`

const TitleRightDiv = styled.div`
    display: flex;
    align-items: center;
  margin-right: 1.25rem;
`

const LogoImage = styled.img`
    width: 24px;
    height : 24px;
`

const PictureImage = styled.img`
  width: 2.25rem;
  height: 2.25rem;
  flex-grow: 0;
`

const TitleText = styled.h1`
   
    font-size: 1.25rem;
    font-weight: 800;
    font-family: Pretendard, sans-serif;
//    color: white;
    color: #333333;
    line-height: 1.25rem;
    letter-spacing: 2px;
    margin-left : 8px
`

const LoginText = styled.span`
  width: 2.313rem;
  height: 1.25rem;
  flex-grow: 0;
  font-family: Pretendard, sens-serif;
  font-size: 0.875rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #4a90e2;  
`

const SignUpText = styled.span`
  width: 3.063rem;
  height: 1.25rem;
  flex-grow: 0;
  font-family: Pretendard, sens-serif;
  font-size: 0.875rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
`

const CompanyNameText = styled.span`  
  height: 1.25rem;
  flex-grow: 0;
  font-family: Pretendard, sens-serif;
  font-size: 0.875rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #333;
`

const LogoutText = styled.span`
  width: 3.063rem;
  height: 1.25rem;
  flex-grow: 0;
  font-family: Pretendard, sens-serif;
  font-size: 0.875rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #999;
`

const NameText = styled.button`
    font-family: Pretendard, sens-serif;    
    width: fit-content;
    min-width: 64px;
    height: fit-content;
    border-width : 0;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    align-items : center;
    background: transparent;
    color: var(--black);
`
export default Title;