import axios from 'axios';
import {BaseResponse} from "../BaseResponse";

export async function checkDuplicateId(userId: string) {
    const response = await axios.get<BaseResponse<boolean>>("/signUp/checkDuplicate?", {
        params: {
            userId: userId
        }
    })

    return response.data
}