import React from 'react';
import {Vihecle} from "../../../api/vihecle/getVihecleList";
import {useHistory} from 'react-router-dom';
// import Button from "../../../components/style/button/Button";
import styled from "styled-components";
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import BorderColorOutlinedIcon from '@material-ui/icons/BorderColorOutlined';

interface ModifyButtonRendererProps{
    data? : Vihecle
}

// 수정 버튼
export default function ModifyButtonRenderer(props:ModifyButtonRendererProps){
    const history = useHistory()

    // 수정 페이지로 이동
    function buttonClicked(){
        history.push("/vihecle/modify/" + props.data?.uid)
    }

    return (
        <div>
          <RegistrationButton

              startIcon={<BorderColorOutlinedIcon style={{ fontSize: '0.875rem', marginRight: '-0.35rem' }} />}
              onClick={buttonClicked}
          >
              수정
          </RegistrationButton>
        </div>
    )
}

const RegistrationButton = styled(Button)`
  height: 2.2rem;
  width: 4rem;
  font-size: 0.875rem;
  border-radius: 4px;
  border: 1px solid var(--gray-4);
  color : var(--gray-2);
  
`