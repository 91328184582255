import React from 'react';
import {Redirect, Route, Switch, useLocation} from "react-router-dom";
import {
    LINK_CHARGE,
    LINK_HOME, LINK_OPTION,
    LINK_SIGN_IN, LINK_SIGN_UP,
    LINK_SITUATIONROOM, LINK_TEST, LINK_USER,
    LINK_VIHECLE, LINK_VIHECLE_MODIFY,
    LINK_VIHECLE_REGIST
} from "../resources/LinkConstants";
import Index from "../components/layout";
import HomeScreen from "../pages/home/HomeScreen";
import LoginContainer from "../pages/login/LoginContainer";
import AuthRoute from "../pages/main/AuthRoute";
import SituationRoomContainer from "../pages/situationroom/SituationRoomContainer";
import VihecleContainer from "../pages/vihecle/VihecleContainer";
import VihecleRegistContainer from "../pages/vihecle/regist/VihecleRegistContainer";
import VihecleModifyContainer from "../pages/vihecle/regist/VihecleModifyContainer";
import ChargeContainer from "../pages/charge/ChargeContainer";
import TestContainer from "../pages/test/TestContainer";
import UserContainer from "../pages/user/UserContainer";
import SignUp from "../pages/signup/SignUpContainer";
import {BrowserView, MobileView} from "react-device-detect";
import AppBarMobile from "../components/layout/component/AppBarMobile";
import IndexMobile from "../components/layout/IndexMobile";
import OptionContainer from "../pages/option/OptionContainer";

interface RoutingProps {
    isLoggedIn: boolean
}

function Routing(props: RoutingProps) {
    const location = useLocation();

    // 로그인 페이지
    if (location.pathname === LINK_SIGN_IN) {
        return (
            <Switch>
                <Route path={LINK_SIGN_IN + "/:adminToken?/:memberUid?"} component={LoginContainer}/>
            </Switch>
        );
    }

    // 그 외 페이지
    return (
        <>
            <BrowserView>
                <Index>
                    <Switch>
                        <Route exact path={LINK_HOME} component={HomeScreen}/>
                        <Route exact path={LINK_SIGN_UP} component={SignUp}/>
                        <AuthRoute path={LINK_USER} render={(routeProps) => <UserContainer {...routeProps} />} />
                        <AuthRoute exact path={LINK_SITUATIONROOM} component={SituationRoomContainer}/>
                        <AuthRoute exact path={LINK_VIHECLE} component={VihecleContainer}/>
                        <AuthRoute exact path={LINK_VIHECLE_REGIST} component={VihecleRegistContainer}/>
                        <AuthRoute exact path={LINK_VIHECLE_MODIFY} component={VihecleModifyContainer}/>

                        <AuthRoute exact path={LINK_CHARGE} component={ChargeContainer}/>



                        <AuthRoute exact path={LINK_TEST} component={TestContainer}/>
                        <Redirect path="*" to={LINK_HOME} />
                    </Switch>
                </Index>
            </BrowserView>

            <MobileView>
                <IndexMobile>
                    <Switch>
                        <Route exact path={LINK_HOME} render={(routeProps) => <HomeScreen {...routeProps} isLoggedIn={props.isLoggedIn}/>} />
                        {/*<Route path={LINK_SIGN_IN + "/:adminToken?/:memberUid?"} component={LoginContainer}/>*/}
                        <Route exact path={LINK_SIGN_UP} component={SignUp}/>

                        <AuthRoute path={LINK_USER} render={(routeProps)=><UserContainer {...routeProps}/>}/>
                        <AuthRoute exact path={LINK_SITUATIONROOM} component={SituationRoomContainer}/>
                        <AuthRoute exact path={LINK_VIHECLE} component={VihecleContainer}/>
                        <AuthRoute exact path={LINK_VIHECLE_REGIST} component={VihecleRegistContainer}/>
                        <AuthRoute exact path={LINK_VIHECLE_MODIFY} component={VihecleModifyContainer}/>

                        <AuthRoute exact path={LINK_CHARGE} component={ChargeContainer}/>

                        <AuthRoute exact path={LINK_OPTION} component={OptionContainer}/>

                        <AuthRoute exact path={LINK_TEST} component={TestContainer}/>


                        <Redirect path="*" to={LINK_HOME}/>
                    </Switch>
                </IndexMobile>
            </MobileView>
        </>
    );
}

export default Routing