import React, {useEffect, useState} from 'react';
import {FormProvider, useForm} from "react-hook-form";
import {UpdatePwRequest} from "../../api/member/updateMemberPw";
import FixedWeightText from "../../components/style/text/FixedWeightText";
import styled from "styled-components";
import RedDot from "../../components/style/input/RedDot";
import {RequiredRulesAndValidationTelNumber2, RequiredRulesWithoutValidation} from "../../components/form/BaseFormType";
import Input from "../../components/style/input/Input";
import Select from "../../components/style/select/Select";
import {SignUpForm} from "../../api/signup/getSignUpForm";
import {UpdateMobileRequest} from "../../api/member/updateMemberMobile";
import {MemberPhoneNumber} from "../../api/member/Member";
import Button from "../../components/style/button/Button";
import {InfoHeadText} from "../../components/style/text/InfoHeadText";
import MatchHorizontalSpace from "../../components/MatchHorizontalSpace";
import {InputTitle} from "../../components/style/text/InputTitle";

interface AlarmUpdateScreenProps {
    form? : SignUpForm
    mobile1? : MemberPhoneNumber
    mobile2? : MemberPhoneNumber
    mobile3? : MemberPhoneNumber
    updateMobile: (...payload1: any[]) => Promise<boolean | undefined>

}

function AlarmUpdateScreen(props: AlarmUpdateScreenProps){

    const methods = useForm<UpdateMobileRequest>({
        mode: "onBlur",
        criteriaMode: 'all',
    })
    const {watch} = methods
    const {isValid} = methods.formState

    function onSubmit(data: UpdateMobileRequest){
        props.updateMobile(data)
    }

    useEffect(() => {
        if(props.mobile1){
            methods.setValue("mobile1.name",props.mobile1.name,{shouldValidate: true})
            methods.setValue("mobile1.carrier",props.mobile1.carrier,{shouldValidate: true})
            methods.setValue("mobile1.number1",props.mobile1.number1,{shouldValidate: true})
            methods.setValue("mobile1.number2",props.mobile1.number2,{shouldValidate: true})
            methods.setValue("mobile2.name",props.mobile2?.name)
            methods.setValue("mobile2.carrier",props.mobile2?.carrier ? props.mobile2?.carrier : "")
            methods.setValue("mobile2.number1",props.mobile2?.number1)
            methods.setValue("mobile2.number2",props.mobile2?.number2)
            methods.setValue("mobile3.name",props.mobile3?.name,{shouldValidate: true})
            methods.setValue("mobile3.carrier",props.mobile3?.carrier ? props.mobile3?.carrier : "")
            methods.setValue("mobile3.number1",props.mobile3?.number1)
            methods.setValue("mobile3.number2",props.mobile3?.number2)

        }
    }, [props.mobile1])

    return(
        <>
            <FormProvider {...methods} >
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <CenterDiv>

                        <InfoHeadText>알람 설정</InfoHeadText>
                        <MatchHorizontalSpace marginBottom={"1.875rem"}/>

                        <InputTitle><RedDot/>핸드폰</InputTitle>

                        <Mobile>
                            <NameAndBodyInputField
                                name="mobile1.name"
                                ref={methods.register(RequiredRulesWithoutValidation)}
                                placeholder={"담당자명"}
                            />
                            <MobileSelectBox
                                menuItems={props.form?.memberPhoneCarrierType}
                                defaultValue={1}
                                name={"mobile1.carrier"}
                                reference={methods.register}
                            />
                            <MobileField
                                name={"mobile1.number1"}
                                ref={methods.register(RequiredRulesAndValidationTelNumber2)}
                                maxLength={4}
                            />
                            <MobileField
                                name={"mobile1.number2"}
                                ref={methods.register(RequiredRulesAndValidationTelNumber2)}
                                maxLength={4}
                            />
                        </Mobile>

                        <Mobile>
                            <NameAndBodyInputField
                                name={"mobile2.name"}
                                ref={methods.register}
                                placeholder={"담당자명"}
                            />
                            <MobileSelectBox
                                menuItems={props.form?.memberPhoneCarrierType}
                                defaultValue={1}
                                name={"mobile2.carrier"}
                                reference={methods.register}
                            />
                            <MobileField
                                name={"mobile2.number1"}
                                ref={methods.register}
                                maxLength={4}
                            />
                            <MobileField
                                name={"mobile2.number2"}
                                ref={methods.register}
                                maxLength={4}
                            />
                        </Mobile>

                        <Mobile>
                            <NameAndBodyInputField
                                name={"mobile3.name"}
                                ref={methods.register}
                                placeholder={"담당자명"}

                            />
                            <MobileSelectBox
                                menuItems={props.form?.memberPhoneCarrierType}
                                defaultValue={1}
                                name={"mobile3.carrier"}
                                reference={methods.register}
                            />

                            <MobileField
                                name="mobile3.number1"
                                ref={methods.register}
                                maxLength={4}
                            />
                            <MobileField
                                name="mobile3.number2"
                                ref={methods.register}
                                maxLength={4}
                            />
                        </Mobile>

                        <GuideText>
                            카카오톡 알람 받을번호(최소 1개 입력)
                        </GuideText>

                        <NextButton
                            disabled={!isValid}
                            type={"submit"}
                        >
                            <FixedWeightText fontSize={"12px"} color={"var(--white)"}>변경하기</FixedWeightText>
                        </NextButton>


                    </CenterDiv>
                </form>
            </FormProvider>

        </>




    )

}

const CenterDiv = styled.div`
  display: flex;
  flex-direction : column;
  justify-content: center;


  margin-top: 2.5rem;
  width: 35rem;
`

const AccountInfoDiv = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 484px;
  height: 470px;
  border-radius: 4px;
  padding: 32px;
  border: solid 1px var(--gray-4);
  background-color: var(--white);
  margin-top: 52px;
`

const MobileTitle = styled(FixedWeightText).attrs({
    fontSize: "14px",
    fontWeight: 500
})`
  margin-top: 24px;
  height: 20px;
`

const Mobile = styled.div`
  display: flex;
  margin-top : 8px;
  justify-content: space-between;
`

const NameAndBodyInputField = styled(Input)`
  display: flex;
  width: 8.2rem;
  height: 2.5rem;
  
`

const MobileSelectBox = styled(Select).attrs({
    width: "8.2rem",
    height: "2.5rem",
})``

const ErrorInputFieldWithGuide = styled(Input).attrs((props: InputFieldProps) => ({
    focusBorderColor: (props.error && "var(--red)") || "var(--blue)"
}))<InputFieldProps>`
  border-color: ${(props) => (props.error && "var(--red)") || "var(--gray-4)"};
`

const InputField = styled(ErrorInputFieldWithGuide)`
  width : 420px;
  height: 48px;
`

const MobileField = styled(InputField)`
  width: 8.2rem;
  height: 2.5rem;
  
`

type GuideTextProps = {
    error?: boolean
}

const GuideText = styled(FixedWeightText).attrs({
    fontSize: "12px",
    fontWeight: "normal"
})<GuideTextProps>`
  margin-top: 8px;
  margin-left: 2px;
  height: 18px;
  color: ${(props) => (props.error && "var(--red)") || "var(--gray-4)"};
`

type InputFieldProps = {
    error?: boolean
}

const NextButton = styled(Button)`
  width: 35rem;
  height: 3rem;
  margin-top : 3.125rem;
  color: var(--white);  
`


export default AlarmUpdateScreen;