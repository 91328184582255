import React, {useEffect} from 'react';
import {useFormContext} from "react-hook-form";
import styled from "styled-components"
import Button from "../../../../components/style/button/Button";
import {ErrorInput} from "../../../../components/style/input/ErrorInput";
import FixedWeightText from "../../../../components/style/text/FixedWeightText";
import RedDot from "../../../../components/style/input/RedDot";
import {useAsync} from "../../../../hooks/useAsync";
import {checkDuplicateVihecleName} from "../../../../api/vihecle/checkDuplicateVihecleName";

interface VihecleNameCheckProps {
    setVihecleNameChecked : (isChecked : boolean) => void
    myName? : string
}

function VihecleNameCheck(props: VihecleNameCheckProps) {
    const {register, errors, watch} = useFormContext()
    const {vihecleName} = watch()

    const {execute, data, reset} = useAsync(checkDuplicateVihecleName)

    function handleClick() {
        execute(vihecleName)
    }

    useEffect(()=>{
        if(data !== undefined){
            const message = !data || vihecleName===props.myName ? "사용 할 수 있는 차명입니다." : "이미 사용중인 차명 입니다."
            alert(message)
            props.setVihecleNameChecked(!data || vihecleName===props.myName)
            reset()
        }
    },[data])

    useEffect(()=>{
        props.setVihecleNameChecked(false)
        if(vihecleName===props.myName){
            props.setVihecleNameChecked(true)
        }
    }, [vihecleName])

    return (
        <VihecleNameCheckDiv>

            <InputTitle>
                차명<RedDot/>
            </InputTitle>

            <ItemDiv>
                <Contents>
                    <IdInput
                        name="vihecleName"
                        reference={register({
                            required: true,
                            pattern: /^([A-Za-z0-9가-힣 ]){1,20}$/
                        })}
                        error={errors.vihecleName?.type == "pattern"}
                        errorMessage={"차명을 확인해주세요"}
                        placeholder={"20자 이내"}
                        maxLength={20}

                    />

                    <VihecleNameCheckButton
                        onClick={handleClick}>
                        <ButtonText>중복확인</ButtonText>
                    </VihecleNameCheckButton>
                </Contents>
            </ItemDiv>
        </VihecleNameCheckDiv>
    );
}

const VihecleNameCheckDiv = styled.div`
  display: flex;
  flex-direction: column;
`

const VihecleNameCheckButton = styled(Button)`
  width: 4.813rem;
  height: 2.5rem;
  border-radius: 4px;
  background-color: #f3f4f4;
  margin-left: 0.625rem;
  
`

const Contents = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;  
  justify-content: space-between;
`
const IdInput = styled(ErrorInput)`
  display: flex;
  box-sizing: border-box;
  width: 29.563rem;
  height: 2.5rem;
`
const ItemDiv = styled.div`
  display: flex;
  flex : 1;
  align-items: center;
  
  
`
const InputTitle = styled.text`
  font-size: 0.875rem;
  font-weight: bold;  
  margin-bottom: 0.25rem;
`

const ButtonText = styled.text`
  font-size: 0.813rem;
  font-weight: bold;
  color: #333;
`


export default VihecleNameCheck