import styled from "styled-components";
import {Card} from "@material-ui/core";
import {isMobile} from "react-device-detect";

interface VehicleCardProps {
    active: boolean;
    danger: boolean;
    power : boolean;
}

export const VehicleCard = styled(Card)<VehicleCardProps>`
  box-sizing: border-box;
  margin-bottom: 0.625rem;
  height: 4.875rem;
  box-shadow: none;
  border-radius: 4px;
  border: solid ${(props) => (props.active ? '2px #333' : props.danger ? '1px rgba(246, 99, 80, 0.7)' : '1px #eaeaea')};
  display: flex;
  padding: 0.625rem;
  background-color: ${(props) => (props.danger ? 'rgba(246,99,80,0.1)' : 'white')};  
  filter: ${(props) => (props.power ? '' : props.danger ? 'grayscale(20%)' : 'grayscale(100%)')};
  opacity: ${(props) => (props.power ? 1 : 0.5)};
  ${isMobile && `
    margin-bottom: 0.25rem;
    height: 3.875rem;    
  `}
`