import {combineReducers} from "redux";
import {all} from "redux-saga/effects";
import {signInReducer, signInSaga} from "./signInModule";
import {signUpReducer, signUpSaga} from "./signUpModule";
import {logOutReducer, logOutSaga} from "./logoutModule";
import {signInFromAdminReducer, signInFromAdminSaga} from "./signInFromAdminModule";

export const accountReducers = combineReducers({
    signInReducer,
    signInFromAdminReducer,
    signUpReducer,
    logOutReducer
})

export function* accountSaga() {
    yield all([
        signInSaga(),
        signInFromAdminSaga(),
        signUpSaga(),
        logOutSaga(),
    ]);
}