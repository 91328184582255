import axios from "axios";
import {BaseResponse} from "../BaseResponse";

export async function getSituationRoomVihecleList() {
    const response = await axios.get<BaseResponse<Record<string, SituationRoomVihecle>>>(`/situationRoom`)
    // map <serialNo, data> 형태로 받아오는 듯
    console.log("getSituationRoomVihecleList", response.data)
    return response.data
}

interface TankWeight {
    type: string
    value: string
}

export interface SituationRoomVihecle {
    uid: string,
    vihecleName: string,
    tankGasType: string,
    tankWeight: TankWeight,
    tankPressure: string,
    gasLevel: string,
    gasLeakage: string,
    gpsData: string,
    power: string,
    powerValue: string,
    serialNo: string,
    sendDateTime: string
}
