import React from "react";
import { AgChartsReact } from 'ag-charts-react';
import {SituationRoomModalData} from "../../../api/situationroom/getSituationRoomModalDataList";
import * as agCharts from 'ag-charts-community';
import EChartsReact , { EChartsReactProps } from "echarts-for-react";
import * as echarts from 'echarts';

// interface ChartData {
//     gasLevel: number,
//     sendDateTime: Date
// }

interface ChartData {
    value: [string, number]
}

interface TankPressureChartProps {
    data?: SituationRoomModalData[]
    title?: string
    today?: string
}

function TankPressureChart(props: TankPressureChartProps) {




    const selectedDate = new Date(props.today ?? '2000-01-01');
    selectedDate.setHours(0, 0, 0, 0);
    const minTimestamp = selectedDate.getTime();

// 선택된 날짜의 24시(다음 날의 0시)를 나타내는 타임스탬프
    const maxTimestamp = minTimestamp + (24 * 60 * 60 * 1000); // 하루를 밀리초로 환산

    // props?.data?.map((data)=>{
    //     chartData.push({gasLevel:+data.gasLevel, sendDateTime:new Date(data.sendDateTime)})
    //
    // })

    const data = props?.data?.map((data)=>({
        value: [data.sendDateTime, data.tankPressure]
    }))



    // const options = {
    //     grid: { top: 8, right: 8, bottom: 24, left: 36 },
    //     xAxis: {
    //         type: 'time',
    //
    //     },
    //     yAxis: {
    //         type: 'value',
    //     },
    //     series: [
    //         {
    //             data: data,
    //             type: 'line',
    //             smooth: true,
    //         },
    //     ],
    //     tooltip: {
    //         trigger: 'axis',
    //     },
    // };

    const option = {
        grid: { top: '25%', right: '5%', bottom: '5%', left: '3%', containLabel: true },
        title: {
            text: props.title,
            top: '4%',
            left: '2%',
            textStyle: {
                fontFamily: 'Pretendard-Bold', // 글꼴 설정
                fontSize: 0.875 * parseFloat(getComputedStyle(document.documentElement).fontSize),                   // 글꼴 크기 설정

            }
        },
        xAxis: {
            type: 'time',
            min: minTimestamp, // 선택된 날짜의 0시
            max: maxTimestamp, // 선택된 날짜의 24시(다음 날의 0시)
            axisLabel: {
                formatter: function (value:string) {
                    var date = new Date(value);
                    var hours = date.getHours();
                    if (hours === 0) return '';
                    return hours.toString(); // '22'와 같은 형식으로 시간만 반환
                }
            },
            splitLine: {
                show: true
            }
        },
        yAxis: {
            type: 'value',
            min: 0,
            max: 1.2,
            boundaryGap: [0, '100%'],
            splitLine: {
                show: true
            }
        },
        series: [
            {
                name: '압력(MPa)',
                type: 'line',
                showSymbol: false,
                data: data,
                areaStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                            offset: 0,
                            color: 'rgba(58,77,233,0.8)'
                        },
                        {
                            offset: 1,
                            color: 'rgba(58,77,233,0.3)'
                        }
                    ])

                }
            }
        ],
        tooltip: {
            trigger: 'axis',
        },
    };

    // const state = {
    //     options: {
    //         autoSize: true,
    //         title: {
    //             text: props.title
    //         },
    //         data: chartData,
    //         series: [
    //             {
    //                 xKey: 'sendDateTime',
    //                 yKey: 'gasLevel',
    //                 stroke: '#1c3392',
    //                 marker: {
    //                     fill: '#1c3392',
    //                     stroke: '#1c3392',
    //                 },
    //             },
    //         ],
    //         legend: {
    //             enabled: false,
    //         },
    //         axes: [
    //             {
    //                 type: 'time',
    //                 position: 'bottom',
    //                 tick: {
    //                     count: agCharts.time.hour.every(2),
    //                 },
    //                 label: {
    //                     format: '%H',
    //                 },
    //             },
    //             {
    //                 type: 'number',
    //                 position: 'left',
    //             },
    //         ],
    //     },
    // }

    return(
        <>
            <div style={{ height: '49%', width: '100%', border: '1px solid var(--gray-4)', backgroundColor: 'white' }}>
                {/*<AgChartsReact options={state.options} />*/}
                <EChartsReact
                    option={option}
                    opts={{ renderer: 'svg', width: 'auto', height: 'auto' }}
                    style={{ height: '100%', width: '100%', margin: 0 }}
                />


            </div>

        </>
    )

}

export default TankPressureChart
