import React from "react";
import ChargeScreen from "./ChargeScreen";

interface ChargeContainerProps {

}

function ChargeContainer({} : ChargeContainerProps){

    return(
        <>
            <ChargeScreen/>
        </>
    )
}

export default ChargeContainer;