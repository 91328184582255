import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux";
import {signUpAsync} from "../../redux/account/signUpModule";
import {SignUpRequest} from "../../api/signup/signUp";
import SignUpScreen from "./SignUpScreen";
import {RouteComponentProps, useHistory} from "react-router-dom";
import {LINK_HOME, LINK_SITUATIONROOM} from "../../resources/LinkConstants";
import {useAsync} from "../../hooks/useAsync";
import {getSignUpForm} from "../../api/signup/getSignUpForm";
import {getMember} from "../../api/member/Member";
import {updateMember} from "../../api/member/updateMember";



interface SignUpContainerProps {
    text : string
}

function SignUpContainer(props: RouteComponentProps & SignUpContainerProps) {


    const {data, loading, error} = useSelector((state: RootState) => state.accountReducers.signUpReducer.signUpResponse)
    const {data: form} = useAsync(getSignUpForm, true)
    // const {data: getMemberData} = useAsync(getMember, props.match.path==="/user")
    // const {execute: updateMemberExecute, status: updateStatus} = useAsync(updateMember)


    const dispatch = useDispatch()
    const history = useHistory();

    function onConfirm(request: SignUpRequest) {
        dispatch(signUpAsync.request(request))
    }

    useEffect(() => {
        if (data != null) {
            alert("관리자의 승인을 대기해주세요")
            history.replace(LINK_HOME)
        }
    })

    // useEffect(() => {
    //     if (updateStatus === 'success') {
    //
    //         alert("수정되었습니다")
    //         history.replace(LINK_SITUATIONROOM)
    //     }
    // }, [updateStatus])

    return (
    <>

        {/* {props.text} */}

        <SignUpScreen
            onConfirm={onConfirm}
            loading={loading}
            form={form}
            // isUpdate={getMemberData}
            // updateMember={updateMemberExecute}

        />
    </>
    );
}

export default SignUpContainer