import {ActionType, createAsyncAction, createReducer} from "typesafe-actions";
import {AxiosError} from "axios";
import {takeEvery} from "redux-saga/effects";
import {ErpToken, refreshToken} from "../api/refreshToken";
import {asyncState, AsyncState, createAsyncReducer, transformToArray} from "../lib/reducerUtils";
import createAsyncSaga from "../lib/createAsyncSaga";
import {clearStorageSaga} from "./account/logoutModule";

const REFRESH_TOKEN = 'refreshToken/REFRESH_TOKEN'
export const REFRESH_TOKEN_SUCCESS = 'refreshToken/REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_ERROR = 'refreshToken/REFRESH_TOKEN_ERROR';

export const refreshTokenAsync = createAsyncAction(
    REFRESH_TOKEN,
    REFRESH_TOKEN_SUCCESS,
    REFRESH_TOKEN_ERROR
)<void, ErpToken, AxiosError>();

type RefreshTokenAction = ActionType<typeof refreshTokenAsync>

type RefreshTokenState = {
    refreshTokenResponse: AsyncState<ErpToken>
};

const getAsyncRefreshTokenSaga = createAsyncSaga(refreshTokenAsync, refreshToken)

const initialState: RefreshTokenState = {
    refreshTokenResponse: asyncState.initial()
};

export const refreshTokenReducer = createReducer<RefreshTokenState, RefreshTokenAction>(initialState).handleAction(
    transformToArray(refreshTokenAsync),
    createAsyncReducer(refreshTokenAsync, "refreshTokenResponse")
)

export function* refreshTokenSaga() {
    yield takeEvery(REFRESH_TOKEN, getAsyncRefreshTokenSaga);
    yield takeEvery(REFRESH_TOKEN_ERROR, clearStorageSaga);
}