import React, {useEffect, useRef, useState} from 'react'
import {FormProvider, useForm} from "react-hook-form"
import styled from 'styled-components'
import FixedWeightText from "../../../components/style/text/FixedWeightText";
import RedDot from "../../../components/style/input/RedDot";
import VihecleNameCheck from "./component/VihecleNameCheck"
import {VihecleRequest} from "../../../api/vihecle/postVihecle"
import {Vihecle} from "../../../api/vihecle/getVihecle";
import Select from "../../../components/style/select/Select";
import {VihecleForm} from "../../../api/vihecle/getVihecleForm";
import Input from "../../../components/style/input/Input";
import BasicCheckBox from "../../../components/style/button/BasicCheckBox";
import Button from "../../../components/style/button/Button";
import {
    RequiredRulesAndValidationTank,
    RequiredRulesAndValidationTankProductDate,
    RequiredRulesAndValidationTerminalSerialNo
} from "../../../components/form/BaseFormType"
import useNumber from "../../../hooks/useNumber";
import Datepicker from "../../../components/style/datepicker/Datepicker";
import Moment from 'moment';
import {useHistory} from "react-router-dom";
import SerialNoListSelect, {serialNoMenuItem} from "../../../components/style/select/SerialNoListSelect";
import Slider from "../UiSlider";
import Nouislider from "nouislider-react";
import UiSlider from "../UiSlider";
import SimpleModal, {CancelButton, SubmitButton} from "../../../components/modal/SimpleModal";
import SimpleConfirmModal from "../../../components/modal/SimpleConfirmModal";
import Modal from "react-modal";
import MapModal from "../../situationroom/components/MapModal";
import ReactModalHeader from "../../../components/modal/ReactModalHeader";
import regist_picture from "../../../assets/images/vihecleregist/pc_background3.png";
import regist_picture2x from "../../../assets/images/vihecleregist/pc_background3@2x.png";
import regist_picture3x from "../../../assets/images/vihecleregist/pc_background3@3x.png";
import MatchHorizontalSpace from "../../../components/MatchHorizontalSpace";


interface VihecleModifyScreenProps {
    form? : VihecleForm
    postVihecle: (...payload1: any[]) => Promise<boolean | undefined>
    updateVihecle: (...payload1: any[]) => Promise<boolean | undefined>
    deleteVihecle: (...payload1: any[]) => Promise<boolean | undefined>
    isUpdate? : Vihecle
    SerialNoList? : Array<serialNoMenuItem>
}

function VihecleModifyScreen(props: VihecleModifyScreenProps) {
    const methods = useForm<VihecleRequest>({
        mode: "onBlur",
        criteriaMode: 'all',
    })

    const {isValid} = methods.formState
    const history = useHistory()

    const [showModal, setShowModal] = useState<boolean>(false)
    const [vihecleNameChecked, setVihecleNameChecked] = useState(false)
    const [gasLeakageAlarmCheck, setGasLeakageAlarmCheck] = useState(false)
    const [tankPressureAlarmCheck, setTankPressureAlarmCheck] = useState(false)
    const [gasLevelAlarmCheck, setGasLevelAlarmCheck] = useState(false)

    const datePickerRef =  useRef<HTMLInputElement & { showPicker?: () => void }>();

    const gasLeakageAlarmHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setGasLeakageAlarmCheck(event.target.checked)
    }

    const tankPressureAlarmHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTankPressureAlarmCheck(event.target.checked)
    }

    const gasLevelAlarmHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setGasLevelAlarmCheck(event.target.checked)
    }


    useNumber(methods, "tankSerialNo")
    useNumber(methods, "tankWeight.value")

    methods.register("gasLeakageAlarm.max")
    methods.register("tankPressureAlarm.min")
    methods.register("tankPressureAlarm.max")
    methods.register("gasLevelAlarm.min")
    methods.register("gasLevelAlarm.max")

    useEffect(() => {
        if(props.isUpdate){
            methods.setValue("vihecleName",props.isUpdate.vihecleName,{shouldValidate: true})
            methods.setValue("vihecleType",props.isUpdate.vihecleType)
            methods.setValue("tankProductorName",props.isUpdate.tankProductorName)
            methods.setValue("tankWeight.type",props.isUpdate.tankWeight.type)
            methods.setValue("tankWeight.value",props.isUpdate.tankWeight.value,{shouldValidate: true})
            methods.setValue("tankSerialNo",props.isUpdate.tankSerialNo,{shouldValidate: true})
            methods.setValue("productDate",props.isUpdate.productDate)
            methods.setValue("terminalSerialNo",props.isUpdate.terminal?.uid,{shouldValidate: true})
            methods.setValue("transferCycleTime",props.isUpdate.terminal?.transferCycleTime)
//            methods.setValue("gasLeakageAlarm.alarm",props?.isUpdate?.terminal?.gasLeakageAlarm?.alarm ? true : false)
            setGasLeakageAlarmCheck(props?.isUpdate?.terminal?.gasLeakageAlarm?.alarm || true)
            setTankPressureAlarmCheck(props?.isUpdate?.terminal?.tankPressureAlarm?.alarm || true)
            setGasLevelAlarmCheck(props?.isUpdate?.terminal?.gasLevelAlarm?.alarm || true)
        }
    }, [props.isUpdate])


    function onSubmit(data: VihecleRequest){
        props.updateVihecle(props.isUpdate?.uid, data)
    }

    function handleCancelButton(){
        history.go(-1)
    }

    function deleteButton(){
        props.deleteVihecle(props.isUpdate?.uid)
    }



    return (
        <ScreenDiv>

            {console.log("랜더링?") }


            <FormProvider {...methods} >
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <CenterDiv>
                        <ImageDiv>
                            <PictureImage src={regist_picture} srcSet={`${regist_picture2x} 2x,${regist_picture3x} 3x`}/>
                        </ImageDiv>
                        <RegistDiv>
                            <HeaderText>차량 수정하기</HeaderText>
                            <InfoDiv>
                                <InfoHeadText>차량 정보</InfoHeadText>
                                <MatchHorizontalSpace marginBottom={"1.875rem"}/>
                                <ItemDiv>
                                    <VihecleNameCheck
                                        setVihecleNameChecked={setVihecleNameChecked}
                                        myName={props.isUpdate?.vihecleName}
                                    />
                                </ItemDiv>
                                <ItemDiv>
                                    <InputTitle>종류<RedDot/></InputTitle>
                                    <VihecleTypeSelectBox
                                        menuItems={props.form?.vihecleType}
                                        defaultValue={1}
                                        name={"vihecleType"}
                                        reference={methods.register}
                                    />
                                </ItemDiv>
                            </InfoDiv>

                            <InfoDiv>
                                <InfoHeadText>탱크 정보</InfoHeadText>
                                <MatchHorizontalSpace marginBottom={"1.875rem"}/>
                                <ItemDiv>
                                    <InputTitle>제조회사<RedDot/></InputTitle>
                                    <VihecleTypeSelectBox
                                        menuItems={props.form?.vihecleTankProductorNameType}
                                        defaultValue={1}
                                        name={"tankProductorName"}
                                        reference={methods.register}
                                    />
                                </ItemDiv>
                                <ItemDiv>
                                    <InputTitle>가스<RedDot/></InputTitle>
                                    <VihecleTypeSelectBox
                                        menuItems={props.form?.vihecleTankGasType}
                                        defaultValue={1}
                                        name={"tankGasType"}
                                        reference={methods.register}
                                    />
                                </ItemDiv>
                                <ItemDiv>
                                    <InputTitle>탱크용량<RedDot/></InputTitle>
                                    <RowDiv>
                                        <VihecleGasTypeSelectBox
                                            menuItems={props.form?.vihecleTankWeightType}
                                            defaultValue={1}
                                            name={"tankWeight.type"}
                                            reference={methods.register}
                                        />
                                        <GasInputField
                                            name={"tankWeight.value"}
                                            ref={methods.register(RequiredRulesAndValidationTank)}
                                            maxLength={20}
                                        />
                                    </RowDiv>
                                </ItemDiv>
                                <ItemDiv>
                                    <InputTitle>탱크시리얼번호<RedDot/></InputTitle>
                                    <InputField
                                        name="tankSerialNo"
                                        ref={methods.register(RequiredRulesAndValidationTank)}
                                        maxLength={20}
                                    />
                                </ItemDiv>
                                <ItemDiv>
                                    <InputTitle>제조년월<RedDot/></InputTitle>

                                        <VihecleTankProductPicker
                                            defaultValue={Moment().format('YYYY-MM-DD')}
                                            reference={(el : HTMLInputElement)=>{
                                                datePickerRef.current = el
                                                methods.register(el, RequiredRulesAndValidationTankProductDate)
                                            }}
                                            name={"productDate"}
                                            onClick={() => {
                                                datePickerRef.current?.showPicker?.();
                                            }}

                                        />
                                        {/*    <VihecleDateTypeSelectBox
                                        menuItems={props.form?.vihecleTankProductDateYearType}
                                        defaultValue={43}
                                        name={"productDate.year"}
                                        reference={methods.register}
                                    />
                                    <VihecleDateTypeSelectBox
                                        menuItems={props.form?.vihecleTankProductDateMonthType}
                                        defaultValue={1}
                                        name={"productDate.month"}
                                        reference={methods.register}
                                    />                                      */}

                                </ItemDiv>

                            </InfoDiv>



                            <TerminalInfoDiv>
                                <InfoHeadText>단말기 정보</InfoHeadText>
                                <MatchHorizontalSpace marginBottom={"1.875rem"}/>

                                <ItemDiv>
                                    <InputTitle>단말시리얼번호<RedDot/></InputTitle>
                                    <SerialNoSelectBox
                                        menuItems={props?.SerialNoList}
                                        name={"terminalSerialNo"}
                                        reference={methods.register}
                                        rules={RequiredRulesAndValidationTerminalSerialNo}

                                    />

                                </ItemDiv>
                                <ItemDiv>
                                    <InputTitle>경보알람<RedDot/></InputTitle>
                                    <div>
                                        <SmallItemDiv>
                                            <AlarmCheckBox
                                                inputRef={methods.register}
                                                name={"gasLeakageAlarm.alarm"}
                                                handleChange={gasLeakageAlarmHandleChange}
                                                checked={gasLeakageAlarmCheck}
                                            />
                                            <AlarmInputTitle>가스누출 알람</AlarmInputTitle>
                                            <SmallItemDiv>

                                                <UiSlider
                                                    start={props.isUpdate?.terminal?.gasLeakageAlarm?.max ? props.isUpdate?.terminal?.gasLeakageAlarm?.max : 80}
                                                    range={{
                                                        min: 0,
                                                        max: 100
                                                    }}
                                                    connect={[false,true]}
                                                    tooltips={true}
                                                    step={1}
                                                    onUpdate={(data:any)=>methods.setValue("gasLeakageAlarm.max",data[0])}
                                                />

                                            </SmallItemDiv>
                                        </SmallItemDiv>
                                        <SmallItemDiv>
                                            <AlarmCheckBox
                                                inputRef={methods.register}
                                                name={"tankPressureAlarm.alarm"}
                                                handleChange={tankPressureAlarmHandleChange}
                                                checked={tankPressureAlarmCheck}
                                            />
                                            <AlarmInputTitle>탱크압력 알람</AlarmInputTitle>
                                            <SmallItemDiv>
                                                <UiSlider
                                                    start={[props.isUpdate?.terminal?.tankPressureAlarm?.min ? props.isUpdate?.terminal?.tankPressureAlarm?.min : 20, props.isUpdate?.terminal?.tankPressureAlarm?.max ? props.isUpdate?.terminal?.tankPressureAlarm?.max : 80]}
                                                    range={{
                                                        min: 0,
                                                        max: 100
                                                    }}
                                                    connect={[true,false,true]}
                                                    tooltips={true}
                                                    step={1}
                                                    onUpdate={(data:any)=>{
                                                        methods.setValue("tankPressureAlarm.min",data[0])
                                                        methods.setValue("tankPressureAlarm.max",data[1])
                                                    }}
                                                />
                                                {/*
                                        <VihecleAlarmValueSelectBox
                                    //        menuItems={props.form?.VihecleType}
                                            defaultValue={0}
                                            name={"tankPressureAlarm.max"}
                                            reference={methods.register}
                                        />
                                        <AlarmInfo>이상</AlarmInfo>

                                        <VihecleAlarmValueSelectBox
                                    //        menuItems={props.form?.VihecleType}
                                            defaultValue={0}
                                            name={"tankPressureAlarm.min"}
                                            reference={methods.register}
                                        />
                                        <AlarmInfo>이하</AlarmInfo>
                                        */}
                                            </SmallItemDiv>
                                        </SmallItemDiv>
                                        <SmallItemDiv>
                                            <AlarmCheckBox
                                                inputRef={methods.register}
                                                name={"gasLevelAlarm.alarm"}
                                                handleChange={gasLevelAlarmHandleChange}
                                                checked={gasLevelAlarmCheck}
                                            />
                                            <AlarmInputTitle>탱크잔량 알람</AlarmInputTitle>

                                            <UiSlider
                                                start={[props.isUpdate?.terminal?.gasLevelAlarm?.min ? props.isUpdate?.terminal?.gasLevelAlarm?.min : 20, props.isUpdate?.terminal?.gasLevelAlarm?.max ? props.isUpdate?.terminal?.gasLevelAlarm?.max : 80]}
                                                range={{
                                                    min: 0,
                                                    max: 100
                                                }}
                                                connect={[true,false,true]}
                                                tooltips={true}
                                                step={1}
                                                onUpdate={(data:any)=>{
                                                    methods.setValue("gasLevelAlarm.min",data[0])
                                                    methods.setValue("gasLevelAlarm.max",data[1])
                                                }}
                                            />
                                            {/*
                                        <VihecleAlarmValueSelectBox
                                  //          menuItems={props.form?.VihecleType}
                                            defaultValue={0}
                                            name={"gasLevelAlarm.max"}
                                            reference={methods.register}
                                        />
                                        <AlarmInfo>이상</AlarmInfo>

                                        <VihecleAlarmValueSelectBox
                                  //          menuItems={props.form?.VihecleType}
                                            defaultValue={0}
                                            name={"gasLevelAlarm.min"}
                                            reference={methods.register}
                                        />
                                        <AlarmInfo>이하</AlarmInfo>
                                        */}
                                        </SmallItemDiv>
                                    </div>
                                </ItemDiv>
                                <ItemDiv>
                                    <InputTitle>통신주기설정<RedDot/></InputTitle>
                                    <InputField
                                        name="transferCycleTime"
                                        ref={methods.register(RequiredRulesAndValidationTank)}
                                        maxLength={3}
                                        defaultValue={"1"}
                                    />
                                </ItemDiv>
                                <ButtonDiv>
                                    <NextButton
                                        disabled={!isValid || !vihecleNameChecked}
                                        type={"submit"}
                                    >수정</NextButton>

                                    <DeleteButton onClick={() => setShowModal(true)}>삭제</DeleteButton>
                                    <NextButton onClick={handleCancelButton}>취소</NextButton>

                                </ButtonDiv>
                            </TerminalInfoDiv>

                        </RegistDiv>
                    </CenterDiv>

                    <Modal
                        style={{
                            overlay: {zIndex: 3},
                            content:{
                                width: '20%',
                                height: '18%',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                padding: 0,
                                overflow: 'hidden'
                            }
                        }}
                        isOpen={showModal}
                        onRequestClose={() => setShowModal(false)}
                        contentLabel="차량 삭제"
                    >

                        <ModalTextDiv style={{marginTop:"2rem"}}>
                            차량을 삭제하시겠습니까?
                        </ModalTextDiv>
                        <ButtonDiv>
                            <NextModalButton onClick={() => setShowModal(false)}>취소</NextModalButton>
                            <DeleteModalButton onClick={deleteButton}>삭제</DeleteModalButton>
                        </ButtonDiv>

                    </Modal>


                </form>
            </FormProvider>
        </ScreenDiv>
    )
}

const ScreenDiv = styled.div`
  width:100%;

`

const RegistDiv = styled.div`

  display: flex;
  flex-direction : column;

  align-items: center;
  position: absolute;
  box-sizing: border-box;
  background-color: white;
  margin-top: 6.25rem;
  border-radius: 7px;
  width: 41.25rem;
  height: 89.938rem;
  padding: 3.125rem;


`

const ImageDiv = styled.div`
  position: relative;
  width:100%;
`

const PictureImage = styled.img`
  width: 100%;
`

const InfoHeadText = styled.text`
  margin-bottom: 0.625rem;
  font-size: 0.875rem;
  font-weight: bold;
  color: #6e6d7a;
  text-align: left;
`


const HeaderText = styled.text`
  width: 100%;
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 3.125rem;
  text-align: left;
`



const CenterDiv = styled.div`
  height: 103rem;
  display: flex;
  flex-direction : column;

  align-items: center;
`

const ModalTextDiv = styled.div`
  
  display: flex;
  flex-direction : column;

  align-items: center;
`

const InfoDiv = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 35rem;
  height: auto;

  margin-bottom: 2.5rem;
  


`

const TerminalInfoDiv = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 35rem;
  height: auto;

  margin-bottom: 2.5rem;
`

const InputTitle = styled.text`
  font-size: 0.875rem;
  font-weight: bold;
  margin-bottom: 0.25rem;
`

const AlarmInputTitle = styled(FixedWeightText)`
  font-size: 0.875rem;
  font-weight: bold;
  margin-right: 2.625rem;
  margin-bottom: 0.125rem;
  margin-left: 0.375rem;

`

const AlarmInfo = styled(FixedWeightText)`
  font-size: 12px;
  font-weight: 400;
  margin: 10px;
  margin-top: 6px;
  margin-left: 5px;
  margin-right: 10px;
  margin-bottom: 8px;
  height: 20px;
`

const VihecleTypeDiv = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

`

const ItemDiv = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  margin-bottom: 1.25rem;
`

const ButtonDiv = styled.div`
  display: flex;
  flex : 1;
  align-items: center;
  margin-top: 1.875rem;
  justify-content: space-between;
`

const SmallItemDiv = styled.div`
  height: 60px;
//  background-color: #85b8ff;
  display: flex;
  flex : 1;
  align-items: center;
//  height: "5000px";



`

const VihecleTypeSelectBox = styled(Select).attrs({
    width: "35rem",
    height: "2.5rem",
    fontSize: "0.875rem",
})`

`

const SerialNoSelectBox = styled(SerialNoListSelect).attrs({
    width: "35rem",
    height: "2.5rem",
    fontSize: "0.875rem"
})``

const VihecleGasTypeSelectBox = styled(Select).attrs({
    width: "8.375rem",
    height: "2.5rem",
    fontSize: "0.875rem",
    marginRight: "0.625rem"
})``

const VihecleDateTypeSelectBox = styled(Select).attrs({
    width: "166px",
    height: "48px",
    fontSize: "12px"
})``

const VihecleAlarmValueSelectBox = styled(Select).attrs({
    width: "80px",
    height: "48px",
    fontSize: "12px"
})``



export const RowDiv = styled.div`

  display: flex;
  flex-direction: row;
  align-items: center;
`

type InputFieldProps = {
    error?: boolean
}

const ErrorInputFieldWithGuide = styled(Input).attrs((props: InputFieldProps) => ({
    focusBorderColor: (props.error && "var(--red)") || "var(--blue)"
}))<InputFieldProps>`
  border-color: ${(props) => (props.error && "var(--red)") || "var(--gray-4)"};
`

const InputField = styled(ErrorInputFieldWithGuide)`
  width : 35rem;
  height: 2.5rem;
`
const GasInputField = styled(ErrorInputFieldWithGuide)`
  width : 26rem;
  height: 2.5rem;
`
const AlarmCheckBox = styled(BasicCheckBox)`
  padding : 0px;
  margin-left: -2px;
  border-width: 1px;
`

const NextButton = styled(Button)`
  width: 11.25rem;
  height: 3rem;

  color: var(--white);
`

const VihecleTankProductPicker = styled(Datepicker)`
  width: 35rem;
  height: 2.5rem;
`

const NextModalButton = styled(Button)`
  width: 10rem;
  height: 3rem;
  margin: 1.5rem;
  color: var(--white);
`

const DeleteButton = styled(Button)`
  width: 11.25rem;
  height: 3rem;
  color: #f66350;
  background-color: #ffffff; 
  border: solid 1px #f66350;
`

const DeleteModalButton = styled(Button)`
  width: 10rem;
  height: 3rem;
  margin: 1.5rem 1.5rem 1.5rem 0;
  color: var(--white);
  background-color: #eb5757;
`

const ButtonModalDiv = styled.div`
  display: flex;
  justify-content: flex-end;
`

const CancelModalButton = styled(Button)`
  width: 120px;
  height: 48px;
  border: solid 1px var(--gray-4);
  background-color: var(--white);
`

const SubmitModalButton = styled(CancelButton)`
  margin-left: 8px;
  background-color: var(blue);
`


export default VihecleModifyScreen