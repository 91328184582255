import React from 'react';
import styled from 'styled-components';

interface DividerComponentProps {

}

function Divider(props: DividerComponentProps) {
    return (
        <DividerDiv/>
    );
}

export const DividerDiv = styled.div`
  
  height: 0.063rem;  
  background-color: #f6f6f6;
`

export default Divider