import React from 'react';
import styled from 'styled-components';
import { AppBar, Toolbar, IconButton } from '@material-ui/core';
import BarChartIcon from '@material-ui/icons/BarChart';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { useHistory, useLocation } from 'react-router-dom';
import {LINK_OPTION, LINK_SITUATIONROOM} from "../../../resources/LinkConstants";

function AppBarMobile() {
    const history = useHistory();
    const location = useLocation();

    // 현재 경로와 버튼의 경로를 비교하여 스타일을 조정하는 함수
    function isActive(path: string): boolean {
        return location.pathname === path;
    }

    return (
        <AppBarContainer position="fixed">
            <StyledToolbar>

                <LinkButton
                    edge="start"
                    isActive={isActive('/')}
                    disabled={true}
                    onClick={() => history.push('/')}
                >
                    <StyledBarChartIcon />
                </LinkButton>


                <LinkButton
                    isActive={isActive(LINK_SITUATIONROOM)}
                    onClick={() => history.push(LINK_SITUATIONROOM)}
                >
                    <StyledDesktopWindowsIcon />
                </LinkButton>


                <LinkButton
                    edge="end"
                    isActive={isActive(LINK_OPTION)}
                    onClick={() => history.push(LINK_OPTION, { previousData: 'data' })}
                >
                    <StyledMoreHorizIcon />
                </LinkButton>

            </StyledToolbar>
        </AppBarContainer>
    );
};

const AppBarContainer = styled(AppBar)`
  top: auto;
  bottom: 0;
  background-color: #123456; // AppBar 색상을 설정합니다.
  height: 3.5rem;
  
  
`

const StyledToolbar = styled(Toolbar)`
  justify-content: space-between; // 아이콘을 양 끝과 가운데에 배치합니다.   
  padding-left: 2rem; // 왼쪽 여백 추가
  padding-right: 2rem; // 오른쪽 여백 추가
  
  
  
`

// isActive prop에 따라 스타일을 조정하는 LinkButton을 정의합니다.
const LinkButton = styled(IconButton)<{ isActive: boolean }>`
  color: ${(props) => (props.isActive ? '#4caf50' : 'white')};
  
  
`

const StyledDesktopWindowsIcon = styled(DesktopWindowsIcon)`

`;

const StyledBarChartIcon = styled(BarChartIcon)`

`;

const StyledMoreHorizIcon = styled(MoreHorizIcon)`
 
`;

export default AppBarMobile;
