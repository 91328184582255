import styled from "styled-components";

export const InfoDiv = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 35rem;
  height: auto;

  margin-bottom: 2.5rem;
`
