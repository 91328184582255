import React from 'react';
import styled from 'styled-components';
import FixedWeightText from "../../style/text/FixedWeightText";
interface RedDotComponentProps {
}

function RedDot (props : RedDotComponentProps){
    return (
        <Dot>*</Dot>
    );
}

const Dot = styled(FixedWeightText)`
  color : var(--red);
  margin-left: 0.125rem;
`

export default RedDot