import React from 'react'
import BasicTableComponent from "../../../components/table/BasicTable";
import {Vihecle} from "../../../api/vihecle/getVihecleList";
import {VihecleForm} from "../../../api/vihecle/getVihecleForm";
import ModifyButtonRenderer from "../components/ModifyButtonRenderer";

interface VihecleListTableComponentProps {
    data?: Array<Vihecle>
    form? : VihecleForm
    isEditable?: boolean
}

function VihecleListTable(props: VihecleListTableComponentProps) {

    // DB 데이터 변환
    function VihecleTypeGetter(params:any){
        return props.form?.vihecleType.find(type => type.id === parseInt(params.data.vihecleType))?.title
    }

    function VihecleTankProductorNameTypeGetter(params:any){
        return props.form?.vihecleTankProductorNameType.find(type => type.id === parseInt(params.data.tankProductorName))?.title
    }

    function VihecleTankGasTypeGetter(params:any){
        const value = props.form?.vihecleTankGasType.find(type => type.id === parseInt(params.data.tankGasType))?.title

        let textColor = "black"; // 기본 색상은 검정색으로 설정

        switch (value) {
            case "LPG":
                textColor = "gray";
                break;
            case "LNG":
                textColor = "#14b3bd";
                break;
            case "LH2":
                textColor = "#4a90e2";
                break;
        }

        return <span style={{ color: textColor, fontWeight: 600 }}>{value}</span>;
        // return props.form?.vihecleTankGasType.find(type => type.id === parseInt(params.data.tankGasType))?.title
    }

    function VihecleTankWeightTypeGetter(params:any){
        return params.data.tankWeight.value.concat(props.form?.vihecleTankWeightType.find(type => type.id === parseInt(params.data.tankWeight.type))?.title)
    }

    return (
    <>
    {
        props.form &&
        <BasicTableComponent
            tableWidth={"100%"}
            tableHeight={40.1}
            rowHeight={3.75}
            headerHeight={2.5}
            rowData={props.data}
            stayScrollEnd={true}
            columns={[
               {
                    id: "No",
                    title: "No",
                    accessor: "No",
                    sortable: true,
                    width: 4.375 * parseFloat(getComputedStyle(document.documentElement).fontSize),
                    valueGetter: (params) => { return params.node.rowIndex + 1 }
               }, {
                    id: "modifyButton",
                    title: "수정",
                    accessor: "modifyButton",
                    sortable: true,
                    width: 5.125 * parseFloat(getComputedStyle(document.documentElement).fontSize),
                    cellRendererFramework: ModifyButtonRenderer,
               }, {
                    id: "vihecleName",
                    title: "차명",
                    accessor: "vihecleName",
                    sortable: true,
                    width: 14.813 * parseFloat(getComputedStyle(document.documentElement).fontSize),
               }, {
                    id: "vihecleType",
                    title: "종류",
                    accessor: "vihecleType",
                    sortable: true,
                    width: 7.5 * parseFloat(getComputedStyle(document.documentElement).fontSize),
                    valueGetter: VihecleTypeGetter
               }, {
                    id: "tankProductorName",
                    title: "제조사",
                    accessor: "tankProductorName",
                    sortable: true,
                    width: 10.563 * parseFloat(getComputedStyle(document.documentElement).fontSize),
                    valueGetter: VihecleTankProductorNameTypeGetter
               }, {
                    id: "tankGasType",
                    title: "가스",
                    accessor: "tankGasType",
                    sortable: true,
                    width: 5 * parseFloat(getComputedStyle(document.documentElement).fontSize),
                    valueGetter: VihecleTankGasTypeGetter
               }, {
                    id: "tankWeight",
                    title: "용량",
                    accessor: "tankWeight.value,tankWeight.type",
                    sortable: true,
                    width: 6.25 * parseFloat(getComputedStyle(document.documentElement).fontSize),
                    editable: props.isEditable,
                    valueGetter: VihecleTankWeightTypeGetter
               }, {
                    id: "tankSerialNo",
                    title: "탱크시리얼번호",
                    accessor: "tankSerialNo",
                    sortable: true,
                    width: 9.375 * parseFloat(getComputedStyle(document.documentElement).fontSize),
                    editable: props.isEditable
               }, {
                    id: "productDate",
                    title: "제조년월일",
                    accessor: "productDate",
                    sortable: true,
                    width: 7.5 * parseFloat(getComputedStyle(document.documentElement).fontSize),
                    editable: props.isEditable
               }, {
                    id: "terminal.serialNo",
                    title: "단말시리얼번호",
                    accessor: "terminal.serialNo",
                    sortable: true,
                    width: 8.875 * parseFloat(getComputedStyle(document.documentElement).fontSize)
               }, {
                    id: "createdAt",
                    title: "등록일",
                    accessor: "createdAt",
                    sortable: true,
                    width: 8 * parseFloat(getComputedStyle(document.documentElement).fontSize),
                    editable: props.isEditable
               }]
            }
            onCellClicked={() => {
            }}
        />
    }
    </>
    );
}

export default React.memo(VihecleListTable)