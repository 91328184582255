import {ActionType, createAction, createReducer} from "typesafe-actions";
import {takeEvery} from "redux-saga/effects";
import {
    LOCAL_STORAGE_KEY_ERP_USER_NAME,
    LOCAL_STORAGE_KEY_IS_LOGGED_IN,
    LOCAL_STORAGE_KEY_TOKEN
} from "../../resources/LocalStorageConstants";

export const LOG_OUT = 'logOut/LOG_OUT'

export const logOut = createAction(LOG_OUT)()

type LogOutAction = ActionType<typeof logOut>

type LogOustState = {
};

const initialState: LogOustState = {
};

export function* clearStorageSaga(){
    console.log("clearStorageSaga !!! ")
    localStorage.removeItem(LOCAL_STORAGE_KEY_ERP_USER_NAME)
    localStorage.removeItem(LOCAL_STORAGE_KEY_TOKEN)
    localStorage.setItem(LOCAL_STORAGE_KEY_IS_LOGGED_IN, "false")
    sessionStorage.removeItem(LOCAL_STORAGE_KEY_ERP_USER_NAME)
    sessionStorage.removeItem(LOCAL_STORAGE_KEY_TOKEN)
    sessionStorage.setItem(LOCAL_STORAGE_KEY_IS_LOGGED_IN, "false")
}

export const logOutReducer = createReducer<LogOustState, LogOutAction>(initialState)

export function* logOutSaga() {
    yield takeEvery(LOG_OUT, clearStorageSaga);
}