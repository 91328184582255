import axios from "axios";
import {BaseResponse} from "../BaseResponse";
import {MenuItem} from "../../components/style/select/Select";

export async function getVihecleForm() {
    const response = await axios.get<BaseResponse<VihecleForm>>("/vihecle/form")

    return response.data
}

export type VihecleForm = {
    vihecleType: Array<MenuItem>,
    vihecleTankProductorNameType: Array<MenuItem>,
    vihecleTankGasType: Array<MenuItem>,
    vihecleTankWeightType: Array<MenuItem>,
    vihecleTankProductDateYearType: Array<MenuItem>,
    vihecleTankProductDateMonthType: Array<MenuItem>
}
