import React from "react";
import Nouislider from "nouislider-react";
//import 'nouislider/distribute/nouislider.css'
import "./nouislider.css";
import styled from "styled-components";
import VihecleListScreen from "./VihecleListScreen";
import firebase from "firebase/compat";

interface Formatter {
    to(val: number): string | number;
    from(val: string | number): number;
}

const FormatterInt = {
    // 'to' the formatted value. Receives a number.
    to: function (value:any) {
        return value.toFixed(0)
    },
    // 'from' the formatted value.
    // Receives a string, should return a number.
    from: function (value:any) {
        return Number(value)
    }
};

interface SliderProps{
    range? : object
    start? : string | number | string[] | number[]
    connect? : boolean | boolean[] | undefined
    tooltips?: boolean | (boolean | Formatter)[] | undefined
    margin? : number
    limit? : number
    padding? : number | number[]
    step?: number;
    onSlide?: any
    onEnd?: any
    onStart?: any
    onUpdate?: any
    onChange?: any
    instanceRef? : any
    className? : string | undefined


}

function UiSlider(props: SliderProps){
    return(
        <NouisliderRound
            range={props.range || { min: 0, max: 100 }}
            start={props.start || 50}
            connect={props.connect || false}
            tooltips={props.tooltips || false}
            margin={props.margin || undefined}
            limit={props.limit || undefined}
            padding={props.padding || 0}
            step={props.step || undefined}
            onSlide={props.onSlide || undefined}
            onEnd={props.onEnd || undefined}
            onChange={props.onChange || undefined}
            onStart={props.onStart || undefined}
            onUpdate={props.onUpdate || undefined}
            instanceRef={props.instanceRef || undefined}
            format={FormatterInt}
        />
    )
}

const NouisliderRound = styled(Nouislider)`
    width: 25rem;
    height: 0.688rem;
  
    & .noUi-connect{
        background: var(--blue);
    }

    & .noUi-handle{
        height: 1.125rem;
        width: 1.125rem;
        top: -0.3125rem;
        right: -0.5625rem; /* half the width */
        border-radius: 0.5625rem;    
    }
   
`

export default UiSlider