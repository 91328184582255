import React from 'react';
import styled from 'styled-components'
import Checkbox from '@material-ui/core/Checkbox';

interface BasicCheckBoxProps {
    handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
    checked: boolean
}

const BasicCheckBox = styled(Checkbox).attrs((props: BasicCheckBoxProps) => ({
    checked: props.checked,
    onChange: props.handleChange
}))<BasicCheckBoxProps>`

  //padding: 6px;
  

  &.root{
    color: var(--gray-4);
  }
  &.Mui-checked{
    color: var(--blue-2);
  },
`

export default BasicCheckBox