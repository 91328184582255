import axios from "axios";
import {BaseResponse} from "../BaseResponse";
import {serialNoMenuItem} from "../../components/style/select/SerialNoListSelect";

export async function getSerialNoList(vihecleId : string = "0") {
    const response = await axios.get<BaseResponse<Array<serialNoMenuItem>>>(`/terminal/serialNoList/${vihecleId}`)
    console.log("시리얼번호리스트", vihecleId)
    return response.data
}

