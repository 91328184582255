import React , { useRef } from 'react'
import TextField from '@material-ui/core/TextField'

interface TestScreenProps {


}

const Test5 = (props : TestScreenProps) => {
    const inputRef = useRef<HTMLInputElement & { showPicker?: () => void }>(null);
    return (
        <TextField
            {...props}
            type={'date'}
            inputRef={inputRef}
            onClick={() => {
                inputRef.current?.showPicker?.();
            }}
        />
    )}

export default Test5;