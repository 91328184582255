export const LINK_HOME = "/"
export const LINK_BASIC = "/basic"

/* 회원가입 + 로그인 + 수정 */
export const LINK_SIGN_UP = "/signUp"
export const LINK_SIGN_IN = "/signIn"
export const LINK_USER = "/user"

/* 요금 */
export const LINK_CHARGE = "/charge"

/* 단말 관리 페이지 */
export const LINK_SITUATIONROOM = "/situationroom"
export const LINK_VIHECLE = "/vihecle"
export const LINK_VIHECLE_REGIST = "/vihecle/regist"
export const LINK_VIHECLE_MODIFY = "/vihecle/modify/:vihecleId"

/* 모바일 옵션 페이지 */
export const LINK_OPTION = "/option"

export const LINK_TEST = "/test"

