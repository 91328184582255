export function markerIconMobile(active:boolean, danger:boolean, power:boolean, vihecleName:string, tankWeight:string, showInfo:boolean=true){
    return {
        content:
            `
            ${ /* MarkerIconMobile */'' }
            <div style="position:relative; display: flex; flex-direction: column; align-items: center; width: 8.688rem; filter: ${ active ? '' : danger ? '' : power? '' : 'grayscale(100%)'}; z-index: ${ active ? '4' : ''}">
                
                ${ /* 윗 내용 부분 + 세로 선. 차량 명 끄기/보기에 영향 받는 부분 */'' }
                <div style="display: ${showInfo ? 'flex' : 'none'}; flex-direction: column; align-items: center;">
                    
                    ${ /* 윗 내용 부분 */'' }
                    <div style="position:relative;
                                display: flex;                                
                                color: #000000;
                                max-width:8.688rem;
                                height: 1.5rem;
                                box-sizing: border-box;
                                background-color: ${ active ? '#05264c' : danger ? '#f4523d' : '#4a90e2' };
                                border-radius: 100px;
                                gap:0.1rem;
                                align-items: center;
                                text-align: center;
                                vertical-align: middle;
                                padding: 0.438rem 0.625rem 0.438rem 0.75rem;
                                z-index:3;
                                margin:0;">
                                
                        ${ /* 차량 이름 */'' }        
                        <div style="overflow:hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    color: #FFFFFF;
                                    font-size:0.6rem;
                                    float: left;
                                    font-stretch:normal;
                                    margin-right: 0.25rem;
                                    letter-spacing: normal;
                                    text-align: left;
                                    font-weight: 400;
                                    vertical-align: middle;">
                        ${vihecleName}
                        </div>
                        
                        ${ /* 용량 */'' }
                        <div style="color: #ffeb39;
                                    font-size:0.6rem;
                                    text-align:center;
                                    vertical-align: middle;
                                    float:right;">
                        ${tankWeight}
                        </div>
                    </div>

                    ${ /* 세로 선 */'' }
                    <div style="display: flex; 
                                position: absolute; 
                                width: 0.063rem; 
                                height:1.375rem; 
                                margin: 1.375rem 0 -0.25rem 0; 
                                background-color: ${ active ? '#05264c' : danger ? '#f4523d' : '#4a90e2' }; 
                                z-index: 2;">
                    </div>
                </div>

                ${ /* 아래 동그란 부분  */'' }
                <div style="position: absolute; 
                            margin-top: 2.438rem; 
                            width: 0.313rem;
                            height: 0.313rem;
                            box-sizing: border-box; 
                            box-shadow: 0 1px 1px 0 rgba(0,0,0,0.5); 
                            
                            border-radius: 50%; 
                            background-color: ${ active ? '#05264c' : danger ? '#f4523d' : '#4a90e2' }; 
                            z-index: 2;">                            
                </div>
            </div>
            `,
        anchor: new naver.maps.Point(73, 45)
    }
};