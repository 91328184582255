import {Action, combineReducers} from 'redux';
import {all} from 'redux-saga/effects';
import {accountReducers, accountSaga} from "./account";
import {REFRESH_TOKEN_ERROR, refreshTokenReducer, refreshTokenSaga} from "./refreshTokenModule";
import {LOG_OUT} from "./account/logoutModule";
import {
    LOCAL_STORAGE_KEY_ERP_USER_NAME,
    LOCAL_STORAGE_KEY_IS_LOGGED_IN,
    LOCAL_STORAGE_KEY_TOKEN
} from "../resources/LocalStorageConstants";
import {headerTitleReducer} from "./HeaderTitleModule";

const appReducer = combineReducers({
    accountReducers,
    refreshTokenReducer,
    headerTitleReducer

});

const rootReducer = (state: any, action: Action) => {
    switch (action.type) {
        case REFRESH_TOKEN_ERROR:
        case LOG_OUT:
            // localStorage.removeItem(LOCAL_STORAGE_KEY_ERP_USER_NAME)
            // localStorage.removeItem(LOCAL_STORAGE_KEY_TOKEN)
            // localStorage.setItem(LOCAL_STORAGE_KEY_IS_LOGGED_IN, "false")
            // sessionStorage.removeItem(LOCAL_STORAGE_KEY_ERP_USER_NAME)
            // sessionStorage.removeItem(LOCAL_STORAGE_KEY_TOKEN)
            // sessionStorage.setItem(LOCAL_STORAGE_KEY_IS_LOGGED_IN, "false")
            state = undefined
            break;
    }

    return appReducer(state, action)
}

// 루트 리듀서를 내보내주세요.
export default rootReducer;

// 루트 리듀서의 반환값를 유추해줍니다
// 추후 이 타입을 컨테이너 컴포넌트에서 불러와서 사용해야 하므로 내보내줍니다.
export type RootState = ReturnType<typeof rootReducer>;

// 루트 사가를 만들어서 내보내주세요.
export function* rootSaga() {
    yield all([accountSaga(), refreshTokenSaga()]);
}

