import React, {useState} from 'react';
import styled from 'styled-components';
import {TerminalRowData} from "../../api/terminal/getTerminal";
import TerminalTable from "../../components/table/TerminalTable";
import TestScreen from "./TestScreen";
import TestScreen2 from "./TestScreen2";
import Map from "./TestScreen2";
import MyMap from "./TestScreen2";
import TestScreen3 from "./TestScreen3";
import Test4 from "./Test4";
import Test5 from "./Test5";

interface TestContainerProps {


}

const markers = [
    {
        position: { lat: 37.5665, lng: 126.978 },
        content: 'Marker 1',
    },
    {
        position: { lat: 37.5675, lng: 126.979 },
        content: 'Marker 2',
    },
];

function TestContainer({} : TestContainerProps){

    return(
        <>
            <Test5/>
        </>
    )
}

export default TestContainer;