import {ActionType, createAsyncAction, createReducer} from "typesafe-actions";
import {signInFromAdmin, SignInFromAdminRequest} from "../../api/SignInFromAdmin";
import {TerminalControlUser} from "../../api/signIn";
import {AxiosError} from "axios";
import createAsyncSaga from "../../lib/createAsyncSaga";
import {signInAsync} from "./signInModule";
import {asyncState, AsyncState, createAsyncReducer, transformToArray} from "../../lib/reducerUtils";
import {takeEvery} from "redux-saga/effects";

const SIGN_IN = 'signIn/from/admin/SIGN_IN'
const SIGN_IN_SUCCESS = 'signIn/from/admin/SIGN_IN_SUCCESS';
const SIGN_IN_ERROR = 'signIn/from/admin/SIGN_IN_ERROR';

export const signInFromAdminAsync = createAsyncAction(
    SIGN_IN,
    SIGN_IN_SUCCESS,
    SIGN_IN_ERROR
)<SignInFromAdminRequest, TerminalControlUser, AxiosError>();

type SignInFromAdminAction = ActionType<typeof signInFromAdminAsync>

type SignInFromAdminState = {
    signInFromAdmResponse: AsyncState<TerminalControlUser>
};

const getAsyncSignInFromAdminSaga = createAsyncSaga(signInAsync, signInFromAdmin)

const initialState: SignInFromAdminState = {
    signInFromAdmResponse: asyncState.initial()
};

export const signInFromAdminReducer = createReducer<SignInFromAdminState, SignInFromAdminAction>(initialState).handleAction(
    transformToArray(signInFromAdminAsync),
    createAsyncReducer(signInFromAdminAsync, "signInFromAdmResponse")
)

export function* signInFromAdminSaga() {
    yield takeEvery(SIGN_IN, getAsyncSignInFromAdminSaga);
}