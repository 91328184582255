import styled from "styled-components";
import {ReactNode} from "react";

type ButtonProps = {
    children: ReactNode
}

const Button = styled.button.attrs((props) => ({
    type: props.type || "button"
}))<ButtonProps>`
  border-radius: 4px;
  border-width: 0;
  background: var(--blue-2);
  opacity: 0.9;

  transition: 0.3s;
  &:disabled {
     background-color: var(--gray-4);
  }
  &:hover {
    opacity: 1;
//    box-shadow: 0 3px 16px 0 rgba(0,0,0,0.24),0 6px 50px 0 rgba(0,0,0,0.19);
  }
`

export default Button