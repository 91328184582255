import styled from "styled-components";
import {Card} from "@material-ui/core";

interface ModalVihecleCardProps {
    active: boolean;
    danger: boolean;
    power : boolean;
}

export const ModalVihecleCard = styled(Card)<ModalVihecleCardProps>`
  box-sizing: border-box;
  margin-bottom: 0.625rem;
  height: 3.125rem;
  box-shadow: none;
  border-radius: 4px;
  border: solid ${(props) => (props.active ? '2px #333' : '1px #eaeaea')};
  display: flex;    
  filter: ${(props) => (props.power ? '' : props.danger ? 'grayscale(20%)' : 'grayscale(100%)')};
  opacity: ${(props) => (props.power ? 1 : 0.5)};
  ;
`