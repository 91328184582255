import React, {useEffect, useState} from 'react';
import styled, {css} from "styled-components";
import FixedWeightText from "./FixedWeightText";
import {isMobile} from "react-device-detect";

interface MemoTextProps{
    data : string
    textType? : string
    onClick? : ((event: React.MouseEvent<SVGTextElement, MouseEvent>) => void) | undefined
}

function MemoText({data, textType, onClick}: MemoTextProps) {

    // const [color, setColor] = useState<string>("var(--white)")
    // const [colorState, setColorState] = useState(false)

    // 실시간으로 데이터 받으면 나타나게 해주려고 했는데 일단 보류
    // useEffect(()=>{
    //     if(colorState) {
    //         setColor("var(--blue)")
    //         setTimeout(() => setColor("var(--white)"), 1000);
    //     } else {
    //         setColorState(true)
    //     }
    // }, [data])

    return(
        <>
            {
                textType === "vihecleName" &&
                <VihecleNameText onClick={onClick}>{data}</VihecleNameText>
            }

            {
                textType === "dataText" &&
                <InputTitle style={{/*backgroundColor: color,*/ marginTop:"0px"}}>{data}</InputTitle>
            }
        </>
    )
}

const InputTitle = styled.text`
  font-size: 0.875rem;
  font-weight: bold;
  ${isMobile && `
    font-size: 0.813rem;
  `}
`

const VihecleNameText = styled(FixedWeightText)`
  
    font-size: 0.875rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: left;
    color: #333;
    
  cursor: pointer;
  text-overflow: ellipsis;
  ${isMobile && `
    font-size: 0.813rem;
  `}
  
  &:hover {
    text-decoration: underline; 
   
  }
`


export default React.memo(MemoText)