import React, {useEffect, useState} from "react";
import {SituationRoomModalData} from "../../../api/situationroom/getSituationRoomModalDataList";
import {useHistory, useLocation} from "react-router-dom";
import {useForm} from "react-hook-form";
import {SituationRoomVihecle} from "../../../api/situationroom/getSituationRoomVihecleList";
import {LINK_SITUATIONROOM} from "../../../resources/LinkConstants";
import {markerIcon} from "../../../components/style/div/MarkerIcon";
import {ModalVihecleCard} from "../../../components/style/div/ModalVihecleCard";
import {StateCircle} from "../../../components/style/div/StateCircle";
import Moment from "moment";
import TerminalTable from "../../../components/table/TerminalTable";
import GasLevelChart from "./GasLevelChart";
import TankPressureChart from "./TankPressureChart";
import styled from "styled-components";
import FixedWeightText from "../../../components/style/text/FixedWeightText";
import Datepicker from "../../../components/style/datepicker/Datepicker";
import VihecleInfoNavBar, {VihecleInfoNavInfoProps} from "../../../components/layout/component/VihecleInfoNavBar";
import SwipeableViews from "react-swipeable-views";
import {InputTitle} from "../../../components/style/text/InputTitle";
import MatchHorizontalSpace from "../../../components/MatchHorizontalSpace";



interface MapModalMobileProps {
    getModalDataList?: (vihecleName: string, modalDate: string) => void
    situationRoomModalDataList?: SituationRoomModalData[]
}

type SearchModalDataByDate = {
    ModalDate: string
}

function MapModalMobile(props: MapModalMobileProps){
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const history = useHistory()

//    const chartData : ChartData[] = []

    const mapStyle = {

        width: '100%',
        height: 'calc(40vh)',

        zIndex: '1',



    };

    const [modalMap, setModalMap] = useState<naver.maps.Map>()
    const [viheclePath, setViheclePath] = useState<naver.maps.LatLng[]>([])
    const [polyLine, setPolyLine] = useState<naver.maps.Polyline>()
    const [modalMarker, setmodalMarker] = useState<naver.maps.Marker>()

    const methods = useForm<SearchModalDataByDate>({
        mode: "onBlur",
        criteriaMode: 'all',
    })
    const {watch} = methods
    const {ModalDate} = watch()

    function VihecleCardClick(data: SituationRoomVihecle) {
        history.push(LINK_SITUATIONROOM + "?vn=" + data.vihecleName + "&tw=" + data.tankWeight.value + data.tankWeight.type)
    }

    useEffect(() => {
        if (location.search !== "" && props.getModalDataList && ModalDate !== undefined) {
            props.getModalDataList(params.get("vn")!!, ModalDate)
        }
    }, [location.search, ModalDate])

    useEffect(() => {
        setModalMap(new naver.maps.Map("modalmap", {
            center: new naver.maps.LatLng(37.3187965288894, 126.833797790159),
            zoom: 13
        }))
    }, [])

    useEffect(() => {
        setViheclePath([])
        polyLine?.setMap(null)
        modalMarker?.setMap(null)
        if (props.situationRoomModalDataList) {
            if (props?.situationRoomModalDataList[0]?.gpsData) {
                const startGps = props?.situationRoomModalDataList[0]?.gpsData?.split(',')
                //    modalMap?.morph(new naver.maps.LatLng(+startGps[0], +startGps[1]), 13)
                modalMap?.setCenter(new naver.maps.LatLng(+startGps[0], +startGps[1]))
                modalMap?.setZoom(13)
                setmodalMarker(new naver.maps.Marker({
                    position: new naver.maps.LatLng(+startGps[0], +startGps[1]),
                    map: modalMap,
                    title: params?.get("vn")!!,
                    icon: markerIcon(true, false, true, params?.get("vn")!!, params.get("tw")!!),

                }))
            }
            props.situationRoomModalDataList.map((SituationRoomModalData) => {
                if (SituationRoomModalData.gpsData) {
                    const gpsAry = SituationRoomModalData.gpsData.split(',')
                    viheclePath.push(new naver.maps.LatLng(+gpsAry[0], +gpsAry[1]))
                    var bounds = modalMap?.getBounds()
                    bounds?.extend(new naver.maps.LatLng(+gpsAry[0], +gpsAry[1]))
                    modalMap?.fitBounds(bounds)
                }


            })
        }

        setPolyLine(new naver.maps.Polyline({
            path: viheclePath,      //선 위치 변수배열
            strokeColor: '#05264c', //선 색 빨강 #빨강,초록,파랑
            strokeOpacity: 1, //선 투명도 0 ~ 1
            strokeWeight: 3,   //선 두께
            map: modalMap           //오버레이할 지도
        }))
    }, [props.situationRoomModalDataList])

    function markerContent(vihecleName: string, tankWeight: string) {
        var markerContent = [
            '<div style="position:absolute;">',
            '<div id=',
            vihecleName,
            ' style="display:',
            'block',
            ';position:absolute;',
            'color: #000000;',
            'width:110px;',
            'height:17px;',
            'top:-26px;',
            'left:-46px;',
            'background-color:rgba(0,0,0,0.8);',
            'border-radius: 0.2em;',

            'text-align: center;',
            'vertical-align: middle;',
            'z-index:1;',
            'border:1px solid black;',
            'margin:0;padding:3px;">',

            '<div style="width:25%;',
            'height:13px;',
            'width: 27px;',
            'top:-26px;',
            'left:-40px;',
            'color: #000000;',
            'font-size:0.7em;',
            'background-color:#FFFFFF;',
            'border-radius: 0.2em;',
            'text-align:center;',
            'vertical-align: middle;',
            'float:left;padding:1.5px;">',
            tankWeight,
            '</div>',

            '<div style="overflow:hidden;',
            'text-overflow: ellipsis;',
            'height:13px;',
            'width: 68%;',
            'white-space: nowrap;',
            'color: #FFFFFF;',
            'font-size:0.8em;',
            'float: right;padding:1.5px;',
            'vertical-align: middle;">',
            vihecleName,
            '</div>',
            '</div>',
            '<div class="pin_s" style="cursor: pointer; width: 22px; height: 30px;">',

            '<img src="marker1.png" alt="" style="margin: 0px; padding: 0px; border: 0px solid transparent; display: block; max-width: none; max-height: none; -webkit-user-select: none; position: absolute; width: 30px; height: 30px; left: 0px; top: 0px;">',

            '</div>',
            '</div>',
            '</div>'
        ].join('')

        return markerContent
    }

    const navProps: VihecleInfoNavInfoProps[] = [
        {
            text: "운행이력"
        },
        {
            text: "차량정보"
        }
    ]

    const [tabIndex, setTabIndex] = useState<number>(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabIndex(newValue);
    };

    const handleChangeIndex = (index: number) => {
        setTabIndex(index);
    };



    return (
        <>
            <ModalDiv>
                <VihecleInfoNavBar navProps={navProps} activePoint={-1} value={tabIndex} onChange={handleChange}/>

                <ContentDiv>
                    <div id="modalmap" style={mapStyle}>
                        <div style={{position: "relative", zIndex: "2", float: "left"}}>
                            <DatePicker
                                defaultValue={Moment().format('YYYY-MM-DD')}
                                name={"ModalDate"}
                                reference={methods.register({required: true})}
                            />
                        </div>
                    </div>
                </ContentDiv>

                <SwipeableViews index={tabIndex} onChangeIndex={handleChangeIndex} containerStyle={{
                    transition: 'transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s'
                }}>
                    <DataDiv>
                        <TerminalTable
                            rowData={props.situationRoomModalDataList}
                        />
                    </DataDiv>
                    <VihecleInfoDiv>
                        <InfoDiv>
                            <InfoTitle>차량정보</InfoTitle>

                            <RowDiv>
                                <InfoHeaddiv>
                                    <InfoHeadText>차명</InfoHeadText>
                                </InfoHeaddiv>
                                <InfoTextdiv>
                                    <InfoText>액화수소트레일러</InfoText>
                                </InfoTextdiv>
                            </RowDiv>

                            <RowDiv>
                                <InfoHeaddiv>
                                    <InfoHeadText>종류</InfoHeadText>
                                </InfoHeaddiv>
                                <InfoTextdiv>
                                    <InfoText>트레일러</InfoText>
                                </InfoTextdiv>
                            </RowDiv>

                        </InfoDiv>
                        <MatchHorizontalSpace/>
                        <InfoDiv>
                            <InfoTitle>탱크정보</InfoTitle>

                            <RowDiv>
                                <InfoHeaddiv>
                                    <InfoHeadText>제조회사</InfoHeadText>
                                </InfoHeaddiv>
                                <InfoTextdiv>
                                    <InfoText>디앨㈜</InfoText>
                                </InfoTextdiv>
                            </RowDiv>

                            <RowDiv>
                                <InfoHeaddiv>
                                    <InfoHeadText>가스</InfoHeadText>
                                </InfoHeaddiv>
                                <InfoTextdiv>
                                    <InfoText>LH2</InfoText>
                                </InfoTextdiv>
                            </RowDiv>

                            <RowDiv>
                                <InfoHeaddiv>
                                    <InfoHeadText>탱크용량</InfoHeadText>
                                </InfoHeaddiv>
                               <InfoTextdiv>
                                    <InfoText>47000 L</InfoText>
                                </InfoTextdiv>
                            </RowDiv>

                            <RowDiv>
                                <InfoHeaddiv>
                                    <InfoHeadText>탱크 시리얼번호</InfoHeadText>
                                </InfoHeaddiv>
                                <InfoTextdiv>
                                    <InfoText>3329</InfoText>
                                </InfoTextdiv>
                            </RowDiv>

                            <RowDiv>
                                <InfoHeaddiv>
                                    <InfoHeadText>제조년월</InfoHeadText>
                                </InfoHeaddiv>
                                <InfoTextdiv>
                                    <InfoText>2024-01-23</InfoText>
                                </InfoTextdiv>
                            </RowDiv>

                        </InfoDiv>

                        <InfoDiv>
                            <InfoTitle>단말기정보</InfoTitle>
                            <RowDiv>
                                <InfoHeaddiv>
                                    <InfoHeadText>단말 시리얼번호</InfoHeadText>
                                </InfoHeaddiv>
                                <InfoTextdiv>
                                    <InfoText>10811-002c</InfoText>
                                </InfoTextdiv>
                            </RowDiv>

                            <RowDiv>
                                <InfoHeaddiv>
                                    <InfoHeadText>가스누출알람</InfoHeadText>
                                </InfoHeaddiv>
                                <InfoTextdiv>
                                    <InfoText>수신</InfoText>
                                </InfoTextdiv>
                            </RowDiv>

                            <RowDiv>
                                <InfoHeaddiv>
                                    <InfoHeadText>탱크압력알람</InfoHeadText>
                                </InfoHeaddiv>
                                <InfoTextdiv>
                                    <InfoText>수신: 7bar 이상, 1bar 이하</InfoText>
                                </InfoTextdiv>
                            </RowDiv>

                            <RowDiv>
                                <InfoHeaddiv>
                                    <InfoHeadText>탱크잔량알람</InfoHeadText>
                                </InfoHeaddiv>
                                <InfoTextdiv>
                                    <InfoText>수신: 90% 이상, 5% 이하</InfoText>
                                </InfoTextdiv>
                            </RowDiv>

                            <RowDiv>
                                <InfoHeaddiv>
                                    <InfoHeadText>통신주기설정</InfoHeadText>
                                </InfoHeaddiv>
                                <InfoTextdiv>
                                    <InfoText>40초</InfoText>
                                </InfoTextdiv>
                            </RowDiv>

                            <ModifyDiv>

                            </ModifyDiv>
                        </InfoDiv>




                    </VihecleInfoDiv>
                </SwipeableViews>





            </ModalDiv>




        </>
    )

}

const ModalDiv = styled.div`
  
  
  box-sizing: border-box;
  width: 100%;
  z-index: 5;
  
  
  

  

 
  
`

const VihecleNameText = styled(FixedWeightText)`
  
  
  flex-grow: 0;  
  font-size: 0.875rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;  
  color: #333;
  
  

`

const ItemDiv = styled.div`
  display: flex;
  flex : 1;
  align-items: center;
  cursor: pointer;
`

const ContentDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #cfd6e0;
  
  width: calc(100%);
  
`




const DataDiv = styled.div`
  
  align-items: center;
  width: 100%;  
  height: calc(60vh - 5.7rem);  


`

const ChartDiv = styled.div`

  width: 45%;
  height: 50%;
  box-sizing: border-box;
  
  
`


const DatePicker = styled(Datepicker)`
  margin-top : 12px;
  margin-left : 12px;      
  width: 150px;
  height: 38px;
  background-color: white;
`

export const InfoDiv = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;  
  height: auto;
  padding: 1.25rem 1.875rem;  
  
  
`

export const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  
`

export const InfoHeadText = styled.text`
  font-size: 0.813rem;
  margin-bottom: 0.25rem;
  line-height: 2.31;
  color: #999;
`

export const InfoText = styled.text`
  font-size: 0.813rem;
  margin-bottom: 0.25rem;
  line-height: 2.31;
  color: #333333;
`

export const InfoHeaddiv = styled.div`
  width: 7.5rem;
  margin-right: 0.625rem;
  
  
`

export const InfoTextdiv = styled.div`
  width: 10.625rem;  
  
  
`

export const VihecleInfoDiv = styled.div`
  align-items: center;
  width: 100%;
  height: calc(60vh - 5.7rem);
  

`

export const InfoTitle = styled.text`
  font-size: 0.875rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  line-height: 1.43;
  
`

export const ModifyDiv = styled.div`
 //바 수정하고 지워야함
  height: 5rem;   

`



export default React.memo(MapModalMobile);