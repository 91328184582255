import React from "react";
import styled from 'styled-components';

interface SidebarItemProps {
    name? : string
}

function SidebarItem( props: SidebarItemProps ) {
    return (
        <ItemDiv className="sidebar-item">
            {props.name}
        </ItemDiv>
    );
}

const ItemDiv = styled.div`
  font-size: 1.125rem;
  margin-bottom: 2.125rem;
`

export default SidebarItem;