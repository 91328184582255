import React from 'react';
import styled from 'styled-components';
import Close from "../../assets/images/signup/ic-48-close-black2@3x.png";
import DefaultImage from "../style/image/DefaultImage";
import FixedWeightText from "../style/text/FixedWeightText";

interface ReactModalHeaderHeaderComponentProps {
    title?: string | null
    onCancel?: () => void
}

function ReactModalHeader (props : ReactModalHeaderHeaderComponentProps) {
    function handleClick() {
        if (props.onCancel) {
            props.onCancel()
        }
    }

    return (
        <Header>
            <HeaderText>
                {props.title}
            </HeaderText>
            <DefaultImage src={Close} width={"1.74rem"} height={"1.74rem"} onClick={handleClick}/>
        </Header>
    );
}

const Header = styled.div`
    display: flex;
    height: 3.313rem;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;    
    background-color: #fff;
    justify-content: space-between;
    align-items: center;   
    
  padding-left : 1.25rem;
  padding-right : 1rem;  
  
  
  
`

const HeaderText = styled(FixedWeightText)`
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: bold;  
  color : #333333;`
export default ReactModalHeader