import axios from 'axios';
import {BaseResponse} from "../BaseResponse";
export async function postVihecle(request: VihecleRequest) {
    const response = await axios.post<BaseResponse<boolean>>("/vihecle/test", request)
    return response.data
}

interface TankWeight {
    type: string
    value: number
}

interface gasLeakageAlarm {
    alarm : boolean
    max : number
}

interface tankPressureAlarm {
    alarm : boolean
    max : number
    min : number
}

interface gasLevelAlarm {
    alarm : boolean
    max : number
    min : number
}

export type VihecleRequest = {
    vihecleName: string,
    vihecleType: string,
    tankProductorName: string,
    tankGasType: string,
    tankWeight: TankWeight,
    tankSerialNo: string,
    productDate: string,
    terminalSerialNo?: string,
    gasLeakageAlarm?: gasLeakageAlarm,
    tankPressureAlarm?: tankPressureAlarm,
    gasLevelAlarm?: gasLevelAlarm
}