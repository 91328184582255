import {ActionType, createAsyncAction, createReducer} from "typesafe-actions";
import {AxiosError} from "axios";
import {AsyncState, asyncState, createAsyncReducer, transformToArray} from "../../lib/reducerUtils";
import createAsyncSaga from "../../lib/createAsyncSaga";
import {takeEvery} from "redux-saga/effects";
import {TerminalControlUser} from "../../api/signIn";
import {signUp, SignUpRequest} from "../../api/signup/signUp";

const SIGN_UP = 'signUp/SIGN_UP'
const SIGN_UP_SUCCESS = 'signUp/SIGN_UP_SUCCESS';
const SIGN_UP_ERROR = 'signUp/SIGN_UP_ERROR';

export const signUpAsync = createAsyncAction(
    SIGN_UP,
    SIGN_UP_SUCCESS,
    SIGN_UP_ERROR
)<SignUpRequest, string, AxiosError>();

type SignUpAction = ActionType<typeof signUpAsync>

type SignUpState = {
    signUpResponse: AsyncState<TerminalControlUser>
};

const getAsyncSignUpSaga = createAsyncSaga(signUpAsync, signUp)

const initialState: SignUpState = {
    signUpResponse: asyncState.initial()
};

export const signUpReducer = createReducer<SignUpState, SignUpAction>(initialState).handleAction(
    transformToArray(signUpAsync),
    createAsyncReducer(signUpAsync, "signUpResponse")
)

export function* signUpSaga() {
    yield takeEvery(SIGN_UP, getAsyncSignUpSaga);
}