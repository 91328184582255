import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import React, {useEffect} from "react";
import {FormControl, MenuItem, SvgIcon, SvgIconProps} from "@material-ui/core";
import MaterialSelect from "@material-ui/core/Select"
import {Controller, useFormContext} from "react-hook-form";
import {BaseFormType} from "../../form/BaseFormType";
import { Property } from 'csstype'
import FixedWeightText from "../text/FixedWeightText";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            //margin: theme.spacing(1),
            minWidth: '80px',
            fontSize: '13px',
            '& .MuiOutlinedInput-root': {
                backgroundColor: 'transparent',
                '& fieldset': {
                    borderColor: 'var(--gray-4)',
                },
                '&:hover fieldset': {
                    borderColor: 'var(--blue)',
                },
                '&.Mui-focused fieldset': {
                    borderColor: 'var(--blue)',
                },
            },
            '& .MuiSelect-outlined.MuiSelect-outlined': {
                paddingRight: '42px'
            },
            "& .MuiMenu-paper": {
                minWidth: '120px'
            }
        },
        iconStyle: {
            position: 'absolute',
            top: '0.5',
            right: '0',
            display: 'inline-block',
            padding: '12px',
            pointerEvents: 'none'
        }
    }),
);

function HomeIcon(props: SvgIconProps) {
    return (
        <SvgIcon viewBox={"0 0 24 24"} {...props}>
            <rect width="24" height="24" fill="#4191FF" rx="2"/>
            <path fill="#fff" fillRule="evenodd"
                  d="M6.293 10.707l1.414-1.414L12 13.586l4.293-4.293 1.414 1.414L12 16.414l-5.707-5.707z"
                  clipRule="evenodd"/>
        </SvgIcon>
    );
}

export type MenuItem = {
    id: number,
    title: string,
}

type SelectProps = BaseFormType & {
    menuItems?: Array<MenuItem>
    width?: string,
    height?: string,
    marginLeft?: string,
    marginRight?: string,
    initialMenuKey?: number
    reference?: any
    value? : number
    defaultValue? : number
    onChange? : () => void
    defaultText? : string
    disabled? : boolean
    fontSize? : string
    backgroundColor? : string
    textAlign? : Property.TextAlign
}

export default function Select(props: SelectProps) {
    const {control, watch} = useFormContext()
    const classes = useStyles();

    useEffect(() => {
        if(props.name && props.value)
        {
            control.setValue(props.name , props.value)
        }
    },[props.value])

    return (
        <FormControl
            variant="outlined"
            className={classes.formControl}>
            <Controller
                as={
                    <MaterialSelect
                        style={{boxSizing : "border-box", width: props.width || "fit-contents", height: props.height || "fit-contents", marginLeft: props.marginLeft, marginRight: props.marginRight, fontSize : "0.875rem", fontWeight : "normal", backgroundColor : props.backgroundColor || "transparent", textAlign : props.textAlign || "left"}}
                        displayEmpty
                        disabled={props.disabled}
                        onChange={props.onChange}
                        // IconComponent={() => <HomeIcon className={classes.iconStyle}/>}
                        >
                        <MenuItem value={0} disabled>
                            <text style={{fontSize: props.fontSize || '0.875rem'}}>{props.defaultText ? props.defaultText :"선택하세요"}</text>
                        </MenuItem>
                        {props.menuItems &&
                        props.menuItems.map(item => <MenuItem value={item.id}>
                            <text style={{fontSize: props.fontSize || '0.875rem'}}>{item.title}</text>
                        </MenuItem>)}
                    </MaterialSelect>
                }
                defaultValue={props.defaultValue || 0}
                name={props.name || ""}
                rules={props.rules}
                control={control}
                onChange={props.onChange}
            />
        </FormControl>
    );
}