import React, {useState, useEffect} from 'react';
import FixedWeightText from "../../components/style/text/FixedWeightText";
import styled from "styled-components";
import Input from "../../components/style/input/Input";
import Button from "../../components/style/button/Button";
import Select, {MenuItem} from "../../components/style/select/Select";
import {useFormContext} from "react-hook-form";
import {useLocation} from 'react-router-dom';
import BasicCheckBox from "../../components/style/button/BasicCheckBox";
// import {LINK_VIHECLE} from "../../resources/LinkConstants";


interface VihecleHeaderScreenProps {
    onClickSearchButton?: (...args: any) => void
}

function VihecleHeaderScreen(props: VihecleHeaderScreenProps) {


    const {register, handleSubmit} = useFormContext();

    const location = useLocation()
    const params = new URLSearchParams(location.search);

    // 검색어 타입 선택
    const selectSearchItem: Array<MenuItem> = [{id: 1, title: "차명"}, {id: 2, title: "단말시리얼번호"}]

    function onSubmit() {
        if (props.onClickSearchButton) {
            // 현재는 웹에서 검색하는 방식이라 주석은 url 을 통해 DB에서 쿼리문으로 받아오는 방식 쓸 때 사용
            // props.onClickSearchButton(methods.watch().startDate, methods.watch().endDate, methods.watch().searchType, methods.watch().searchQuery)
            props.onClickSearchButton()
            // const query = watch().searchQuery == undefined ? "" : watch().searchQuery
            // history.push(LINK_VIHECLE + "?searchType=" + watch().searchType + "&searchQuery=" + query)
        }
    }

    const [isAllChecked, setIsAllChecked] = useState(true)
    const [checkedItems, setCheckedItems] = useState<string[]>(['LH2Check', 'LNGCheck', 'LPGCheck'])

    // 검색 - 가스 체크박스 선택 연동 부분
    useEffect(() => {
      if (checkedItems.length >= 3) {
        setIsAllChecked(true)
      } else {
        setIsAllChecked(false)
      }
    }, [checkedItems])

    const allAgreeHandler = (checked: boolean) => {
      setIsAllChecked(!isAllChecked)
      if (checked) {
        setCheckedItems([...checkedItems, 'LH2Check', 'LNGCheck', 'LPGCheck'])
      } else if ((!checked && checkedItems.includes('LH2Check')) || (!checked && checkedItems.includes('LNGCheck')) || (!checked && checkedItems.includes('LPGCheck'))) {
        setCheckedItems([])
      }
    }

    const agreeHandler = (checked: boolean, name: string) => {
      if (checked) {
        setCheckedItems([...checkedItems, name])
      } else if (!checked && checkedItems.includes(name)) {
        setCheckedItems(checkedItems.filter((el) => el !== name))
      }
    }



    return (
    <>
        <HeaderScreen>
            {/*<SubTitle>*/}
            {/*    <SubtitleText>검색</SubtitleText>*/}
            {/*</SubTitle>*/}
            <ItemDiv>
                <ItemBoxDiv>
                    가스
                </ItemBoxDiv>
                <SearchCheckBox
                    inputRef={register}
                    name={"allCheck"}
                    defaultChecked={true}
                    checked={isAllChecked}
                    handleChange={(e) => allAgreeHandler(e.currentTarget.checked)}
                />
                <SearchTextDiv>
                    전체
                </SearchTextDiv>
                <SearchCheckBox
                    inputRef={register}
                    name={"LH2Check"}
                    defaultChecked={true}
                    handleChange={(e) => agreeHandler(e.currentTarget.checked, e.target.name)}
                    checked={checkedItems.includes('LH2Check') ? true : false}
                />
                <SearchTextDiv>
                    LH2
                </SearchTextDiv>
                <SearchCheckBox
                    inputRef={register}
                    name={"LNGCheck"}
                    defaultChecked={true}
                    handleChange={(e) => agreeHandler(e.currentTarget.checked, e.target.name)}
                    checked={checkedItems.includes('LNGCheck') ? true : false}
                />
                <SearchTextDiv>
                    LNG
                </SearchTextDiv>
                <SearchCheckBox
                    inputRef={register}
                    name={"LPGCheck"}
                    defaultChecked={true}
                    handleChange={(e) => agreeHandler(e.currentTarget.checked, e.target.name)}
                    checked={checkedItems.includes('LPGCheck') ? true : false}
                />
                <SearchTextDiv>
                    LPG
                </SearchTextDiv>
            </ItemDiv>

            <ItemDiv>
                <ItemBoxDiv>
                    검색어
                </ItemBoxDiv>
                <PurchaseSelect menuItems={selectSearchItem}
                                name={"searchType"}
                                defaultValue={1}
                                reference={register}/>
                <ItemInput
                    defaultValue={params.get("searchQuery") || ""}
                    name={"searchQuery"}
                    ref={register}
                    placeholder={"검색어 입력"}
                />
                <SearchButton
                    onClick={handleSubmit(onSubmit)}
                    type={"submit"}
                >
                    검색
                </SearchButton>
            </ItemDiv>
        </HeaderScreen>
    </>
    )
}

const HeaderScreen = styled.div`
  width: 87.5rem;      
  height: 7.25rem;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  margin-bottom: 2rem;  
  text-align: center;
  justify-content: space-between;
  background-color: #f8f8f8;  
  padding: 0.75rem 1.25rem 1.25rem 1.25rem;
  
`


const ItemBoxDiv = styled.div`
  display: flex;
  box-sizing: border-box;
  width: 6.063rem; 
  align-items: center;  
  font-size: 0.875rem;
  font-weight: bold;
  color: #333333;  
`

const SearchTextDiv = styled.div`
  display: flex;
  box-sizing: border-box;  
  margin-right: 0.5rem;
  align-items: center;
  font-size: 0.875rem;
  
  

`

const ItemDiv = styled.div`
  display: flex;  
  align-items: center; 
  margin-bottom: 0.5rem;
`

const ItemInput = styled(Input)`
  display: flex;
  box-sizing: border-box;
  width: 58.875rem;
  height: 2.5rem;
  margin-left: 0.625rem;
  font-size: 0.875rem;
`

const SearchButton = styled(Button)`
  width: 7.5rem;
  height: 2.5rem;
  margin-left: 0.625rem;
  background-color: var(--blue);
  color: var(--white);
  font-size: 0.875rem;
`

export const PurchaseSelect = styled(Select).attrs({
    width: "11.25rem",
    height: "2.5rem",
    marginLeft: "0.7rem",
    backgroundColor: "white",
    fontSize : "0.875rem",
    textAlign : "left"
})`
  
`

const SubtitleText = styled(FixedWeightText)`
  font-size: 16px;
  font-weight: 400;
  margin-right: 24px;
`

const SubTitle = styled.div`
  display: flex;
  align-items: center;
  width: 1080px;
  height: 60px;
  justify-content: space-between;
`

const SearchCheckBox = styled(BasicCheckBox)`  
  
  
  
`


export default VihecleHeaderScreen