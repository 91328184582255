import React, {ReactNode, useState} from 'react';
import {BaseFormType} from "../../../components/form/BaseFormType";
import PostCodeButton from "../../../components/postcode/PostCodeButton";
import styled from "styled-components";
import Input from "../../../components/style/input/Input";
import {useFormContext} from "react-hook-form";

interface AddressWithButtonComponentProps extends BaseFormType {
    marginTop?: string
    onClick?: () => void
    children?: ReactNode
    name: string
}

function AddressWithButton(props: AddressWithButtonComponentProps) {
    const {register, setValue} = useFormContext()
    const [showForce, setShowForce] = useState(false)

    function handleValue(address: string) {
        setShowForce(false)
        setValue(props.name, address, {
            shouldValidate: true,
            shouldDirty: true
        })
    }

    return (
        <Address>
            <AddressInput
                name={props.name}
                ref={register(props.rules)}
                placeholder={"주소명"}
                readOnly={true}
                onClick={() => setShowForce(true)}
            />
            <PostCodeButton
                buttonWidth={"5rem"}
                buttonHeight={"2.5rem"}
                handleValue={handleValue}
                showForce={showForce}
            />
        </Address>
    );
}

const Address = styled.div`
    display : flex;
    flex-direction : row;
    align-items : center;
`

const AddressInput = styled(Input)`
    box-sizing: border-box;    
    width: 30rem;
    height: 2.5rem;
    margin-right: 0.625rem;
`
export default AddressWithButton