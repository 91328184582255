import React, {useState} from 'react';

interface ChargeScreenProps {


}

function ChargeScreen(props: ChargeScreenProps){


    return(
        <>
            요금 스크린
        </>




    )

}

export default ChargeScreen;