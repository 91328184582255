import React, {CSSProperties, SyntheticEvent, useEffect, useState} from 'react';
import NavButton from "../../style/button/NavButton";
import styled from "styled-components";
import {Link, useLocation} from "react-router-dom";
import DefaultWrapperDiv from "../../style/div/DefaultWrapperDiv";
import {Tab, Tabs, withStyles} from "@material-ui/core";

export interface VihecleInfoNavInfoProps {
    text: string
}

interface VihecleInfoNavBarProps {
    navProps: VihecleInfoNavInfoProps[],
    activePoint: number,
    value: number,
    onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
}

interface NavBarTabsProps {
    value: number;
    onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
    variant?: 'standard' | 'scrollable' | 'fullWidth';
}

const NavBarTabs = withStyles({
    
    indicator: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'white',
        height: '0.188rem',

        '& > span': {
            maxWidth: 'none',
            width: '100%',
            backgroundColor: '#4a90e2',
            marginTop: '-0.7rem',   // indicator 높이 위치 조절
            height: '0.188rem',
        },
    },
})((props: NavBarTabsProps) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

interface StyledTabProps {
    label : string,
    value : number
}

const StyledTab = withStyles({
    root: {
        // Tab 스타일 조정
        fontSize: '0.875rem',
        fontWeight: 'bold',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#333',
        flex: 1, // flex 아이템으로 만들고 각 아이템에 동등한 공간을 할당합니다.
        maxWidth: 'none', // Tab의 최대 너비 제한을 없애서 flex: 1 효과를 제대로 적용
        height: '2.2rem', // 높이를 2rem으로 설정
        minHeight: '2.2rem', // 최소 높이를 설정해야 Material-UI 기본 스타일을 오버라이드할 수 있습니다

    },
})((props: StyledTabProps) => <Tab disableRipple {...props} />);


function VihecleInfoNavBar({navProps, activePoint, value, onChange}: VihecleInfoNavBarProps) {

    const [active, setActive] = useState(activePoint);

    function setMenu(index: number) {
        setActive(index)
    }

    function handleClick(index: number) {
        setMenu(index)
    }

    const location = useLocation();








    return (
        <DivWithStyle>

                {/*{makeLinks(navProps)}*/}

                <NavBarTabs
                    value={value}
                    onChange={onChange}
                    aria-label="disabled tabs example"
                    variant="fullWidth"

                >
                    {navProps.map((prop, index) =>(

                            <StyledTab label={prop.text} value={index} />

                        )
                    )}

                </NavBarTabs>


        </DivWithStyle>
    );
}

const DivWithStyle = styled.div`
  
  align-items: center;
  height: 2.2rem;
  margin-bottom: 0.1rem;
  // border-bottom: solid 1px var(--gray-7);
  
  ;
//  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
//  background-image: linear-gradient(to top, #ffffff, #f5f5f5);
`






export default VihecleInfoNavBar;

