import styled from "styled-components";

interface StateCircleProps {
    danger: boolean;
}

export const StateCircle = styled.div<StateCircleProps>`
  width: 0.75rem;
  height: 0.75rem;
  background-color: ${(props) => (props.danger ? '#f4523d' : '#4a90e2')};
  border-radius: 50%;
  margin-left: 1.25rem;
  margin-right: 0.625rem;
`