import React from 'react';
import styled from 'styled-components';
import FixedWeightText from "../text/FixedWeightText";
import Input from "./Input";

type ErrorInputProps = {
    name?: string
    type?: string
    reference?: any
    maxLength?: any
    error: boolean
    errorMessage?: string
    placeholder?: string
}

export const ErrorInput = styled.div.attrs((props: ErrorInputProps) => ({
    children:
        <>
            <StyledInput ref={props.reference}{...props}/>
            <AlertText>
                {props.errorMessage || "오류입니다."}
            </AlertText>
        </>
}))<ErrorInputProps>`
  display: flex;
  border-radius: 4px;
  border: solid 1px;
  border-color: ${(props: ErrorInputProps) => props.error ? "var(--red)" : "var(--gray-4)"};

  &:focus-within{
    outline: none !important;
    border:1px solid;
    border-color: ${(props: ErrorInputProps) => props.error ? "var(--red)" : "var(--blue)"};
  }

  > :nth-child(2) {
    visibility: ${(props: ErrorInputProps) => props.error ? "visible" : "hidden"}
  }
`
const StyledInput = styled(Input)`
  flex: 1;
  box-sizing: border-box;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 4px;
  font-size: 0.875rem;
  border : transparent;
  outline : none;
  &:focus{
    outline: none !important;
    border: none;
  }
`

const AlertText = styled(FixedWeightText).attrs({
    fontSize: "12px",
    fontWeight: 500
})`
  display: flex;
  align-self: center;
  margin-right: 12px;
  color : var(--red);
`

