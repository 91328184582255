import React from 'react'
import {useDispatch} from "react-redux";
import {logOut} from "../../redux/account/logoutModule";
import {LINK_HOME} from "../../resources/LinkConstants";
import {Link} from "react-router-dom";
import styled from "styled-components";
import Chip from "@material-ui/core/Chip";

interface OptionScreenProps {


}

const OptionScreen = (props : OptionScreenProps) => {

    const dispatch = useDispatch()

    function onClickLogOut() {
        dispatch(logOut())
    }

    return (
        <>
            <Link to={LINK_HOME}
                  onClick={onClickLogOut}
                  style={{textDecoration: 'none', justifyContent: 'center'}}>
                <LogoutChip
                    label={<LogoutText>로그아웃</LogoutText>}
                    clickable
                />

            </Link>

        </>
    )

}

const LogoutChip = styled(Chip)`        
  width: 5.063rem;
  height: 2.25rem;
  border-radius: 50px;  
  background-color: #f3f4f4;    
`

const LogoutText = styled.span`
  width: 3.063rem;
  height: 1.25rem;
  flex-grow: 0;
  font-family: Pretendard, sens-serif;
  font-size: 0.875rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #999;
`

export default OptionScreen;