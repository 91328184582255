import React, { useEffect } from 'react';
import styled from 'styled-components';
import {Link} from "react-router-dom";
import BaseText from "../../components/style/text/BaseText";
import home_picture from "../../assets/images/home/pc_background_addtext.png";
import home_picture2x from "../../assets/images/home/pc_background_addtext@2x.png";
import home_picture3x from "../../assets/images/home/pc_background_addtext@3x.png";
import { useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import {LINK_SIGN_IN, LINK_SITUATIONROOM} from "../../resources/LinkConstants";



interface HomeScreenComponentProps {
    isLoggedIn:boolean
}

function HomeScreen(props: HomeScreenComponentProps) {

    const history = useHistory();

    useEffect(() => {
        if(isMobile){
            if (props.isLoggedIn) {
                history.push(LINK_SITUATIONROOM);
            } else {
                history.push(LINK_SIGN_IN);
            }
        }
    }, [props.isLoggedIn]);

    return (
        <>
            <Cover>

                <PictureImage src={home_picture} srcSet={`${home_picture2x} 2x,${home_picture3x} 3x`}/>
            </Cover>

        </>
    )
}



export const Cover = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; 
  
`

const PictureImage = styled.img`
  width: 100%;
  
  
  
  
`

export default HomeScreen