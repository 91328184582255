import styled from 'styled-components';
import {isMobile} from "react-device-detect";

interface TextProps {
    danger: boolean;
}

export const DangerText = styled.text<TextProps>`
  color: ${(props) => (props.danger ? '#f66350' : '#333333')};
  font-size: 0.875rem;
  font-weight: ${(props) => (props.danger ? '700' : '400')};
  ${isMobile && `
    font-size: 0.813rem;
  `}
`


