import React, {useState, useEffect, useRef} from "react";
import TerminalScreen from "./TerminalScreen";
import {useAsync2} from "../../hooks/useAsync2";
import {getTerminal} from "../../api/terminal/getTerminal";
import {getSituationRoomVihecleList, SituationRoomVihecle} from "../../api/situationroom/getSituationRoomVihecleList";
import {getSituationRoomModalDataList, SituationRoomModalData} from "../../api/situationroom/getSituationRoomModalDataList";
import {getTrailerList, TerminalRowData} from "../../api/terminal/getTrailerList";
import MapScreen from "./MapScreen";
import TerminalTable from "../../components/table/TerminalTable";
import TrailerListScreen from "./TrailerListScreen";
import {useLocation} from "react-router-dom";
import {FormProvider, useForm} from "react-hook-form"
import Input from "../../components/style/input/Input";
import styled from "styled-components"
import MapVihecleListScreen from "./MapVihecleListScreen";
import {Client} from "@stomp/stompjs";
import SockJS from "sockjs-client";
import {useSelector} from "react-redux";
import {RootState} from "../../redux";
import getLocalStorageItemOrNull from "../../lib/getLocalStorageItemOrNull";
import {LOCAL_STORAGE_KEY_ERP_USER_ID} from "../../resources/LocalStorageConstants";
import {SituationRoomWebSocketDto} from "../../api/situationroom/SituationRoomWebSocketDto";
import {updateAwsMqttSubList} from "../../api/situationroom/updateAwsMqttSubList";
import {BrowserView, MobileView} from "react-device-detect";


interface TerminalContainerProps {

}

type TrailerSearchRequest = {
    serialNumber: string
}

function SituationRoomContainer(props: TerminalContainerProps) {
    const {data: situationRoomVihecleList} = useAsync2(getSituationRoomVihecleList, true)
    const {execute: getModalDataList, data: situationRoomModalDataList} = useAsync2(getSituationRoomModalDataList, false)
    const {execute: updateMqttSubList} = useAsync2(updateAwsMqttSubList)
    const userId = getLocalStorageItemOrNull(LOCAL_STORAGE_KEY_ERP_USER_ID) // serialno 식으로 바꾸면 없어도 될듯

    const client = useRef<Client>();

    // console.log("situationRoomVihecleList~~~",situationRoomVihecleList)
    // console.log(typeof situationRoomVihecleList);
    // console.log(userId);

    const [vihecleListForMapScreen, setVihecleListForMapScreen] = useState<Map<string, SituationRoomVihecle>>()
    const [situationRoomVihecleListMap, setSituationRoomVihecleListMap] = useState<Map<string, SituationRoomVihecle>>()
    const [activeVihecleSerialNo, setActiveVihecleSerialNo] = useState<string | null>(null)
    const [showInfo, setShowInfo] = useState(true);


    useEffect(() => {
        if(situationRoomVihecleList!==undefined && situationRoomVihecleList!==null) {

            console.log("testestest~~~11111111", situationRoomVihecleList )
            const sortData = Object.entries(situationRoomVihecleList)
                .sort((a, b) => {
                    // 1. gasLeakage 가 50 이상인 것들이 우선 먼저 오도록 정렬
                    if (+b[1].gasLeakage >= 50 && +a[1].gasLeakage < 50) {
                        return 1;
                    } else if (+a[1].gasLeakage >= 50 && +b[1].gasLeakage < 50) {
                        return -1;
                    }

                    // 2. gasLeakage 가 모두 50 이상이거나 모두 50 미만일 경우 gasLevel 이 낮은 순으로 정렬
                    if (+a[1].gasLeakage >= 50 && +b[1].gasLeakage >= 50) {
                        return +a[1].gasLevel - +b[1].gasLevel;
                    } else if (+a[1].gasLeakage < 50 && +b[1].gasLeakage < 50) {
                        return +a[1].gasLevel - +b[1].gasLevel;
                    }

                    // 나머지 경우는 기존의 정렬 순서를 유지
                    return 0;
                });
            console.log("testestest~~~", sortData )

            //websocket 으로 실시간으로 변경해야 하니까 맵용, 리스트용 나눠놓은듯
            setSituationRoomVihecleListMap(new Map<string, SituationRoomVihecle>(sortData))
            setVihecleListForMapScreen(new Map<string, SituationRoomVihecle>(sortData))

            // let maptest = new Map<string, SituationRoomVihecle>(Object.entries(situationRoomVihecleList))
            // console.log("maptest!!!!", maptest)
            // console.log("maptest!!!!", maptest.get("111")?.vihecleName)

        }
    }, [situationRoomVihecleList])



    useEffect(() => {
        setMap(new naver.maps.Map("map", {
            center: new naver.maps.LatLng(37.3187965288894, 126.833797790159),
            zoom: 13
        }))

        console.log("client.activate() 연결 시작")

        client.current = new Client({
            brokerURL: 'ws://localhost:5000/ws/websocket',
            debug: function (str) {
                console.log("=====debug=====", str)
            },
            reconnectDelay: 5000, //자동 재 연결
            heartbeatIncoming: 4000,
            heartbeatOutgoing: 4000,
        })

        // Fallback code
        if (typeof WebSocket !== 'function') {
            // For SockJS you need to set a factory that creates a new SockJS instance
            // to be used for each (re)connect
            client.current.webSocketFactory = function (): any {
                // Note that the URL is different from the WebSocket URL
                return new SockJS('http://localhost:5000/stomp');
            };
        }

        client.current?.activate()

        return () => {
            console.log("맵 파괴파괴파괴파괴파괴파괴파괴파괴")

            updateMqttSubList()

            // client.current?.publish({
            //     destination: '/pub/unSub',
            //     body: 'Hello world',
            //     headers: { priority: '9' },
            // });

            console.log("client.deactivate(); 연결 종료")

            client.current?.deactivate();
            console.log("웹소켓 파괴파괴파괴파괴파괴파괴파괴파괴")
            map?.destroy()

        }
    }, [])

    const [map, setMap] = useState<naver.maps.Map>()
//    const [markers, setMarkers] = useState<naver.maps.Marker[]>([])
    const [markers, setMarkers] = useState<Map<string, naver.maps.Marker>>(new Map())
    const [searchTrailerList, setSearchTrailerList] = useState<TerminalRowData[]>([])
    const [modalIsOpen, setModalIsOpen] = useState(false)

    const methods = useForm<SituationRoomVihecle>({
        mode: "onBlur",
        criteriaMode: 'all',
    })
    const {watch} = methods
    const {vihecleName} = watch()



    useEffect(() => {
        if(situationRoomVihecleListMap && markers.size!==0 && client.current!==undefined) {

            client.current.onConnect = function (frame) {       // 연결됐을 때 실행
                situationRoomVihecleListMap.forEach((data, key) => {        // 
                    client.current?.subscribe(`/sub/${data.serialNo}`, (msg) => {
                        //const newMessage = JSON.parse(msg.body).message;
                        const newMessage = msg.body
                        console.log("subscribe 받았다 : ", msg.body)
                        const webSocketData: SituationRoomWebSocketDto = JSON.parse(msg.body)
                        console.log("받은 데이터 변환 : ", webSocketData)
                        console.log("변환 데이터 gps : ", webSocketData.gps)
                        console.log("원래 map : ", situationRoomVihecleListMap)
                        // const value : SituationRoomVihecle | undefined = situationRoomVihecleListMap?.get(webSocketData.psn)
                        // console.log("value : " , value)
                        if (situationRoomVihecleListMap?.get(webSocketData.psn)) {
                            // console.log("원래 gps : " , value.gpsData)
                            // value.gpsData = webSocketData.gps
                            const gpsAry = webSocketData.gps.split(',')
                            const lat = gpsAry[3].substr(0, 2) + "." + gpsAry[3].substr(2); // 위도
                            const lng = gpsAry[4].substr(0, 3) + "." + gpsAry[4].substr(3); // 경도
                            console.log("gpsAry : ", gpsAry)
                            console.log("markers : ", markers)
                            markers.get(webSocketData.psn)?.setPosition(new naver.maps.LatLng(+lat, +lng))
                            // setMarkers([...markers, markers[0].setPosition(new naver.maps.LatLng(+gpsAry[0], +gpsAry[1]))])
                            // markers[0].setPosition(new naver.maps.LatLng(+gpsAry[0], +gpsAry[1]))
                            // markers[1].setPosition(new naver.maps.LatLng(+gpsAry[0], +gpsAry[1]))
                            // markers[2].setPosition(new naver.maps.LatLng(+gpsAry[0], +gpsAry[1]))
                            // setMarkers(markers)
                            // situationRoomVihecleListMap!!.get(webSocketData.psn)!!.gasLevel = webSocketData.data.gas.reg1
                            setSituationRoomVihecleListMap(prev => new Map([...prev?.entries(), [webSocketData.psn, {
                                ...prev?.get(webSocketData.psn)!!,
                                tankPressure: webSocketData.data.pressure.pv,
                                gasLevel: webSocketData.data.level.pv,
                                gasLeakage: webSocketData.data.gas.reg1,
                                power: webSocketData.pwr
                            }]]))
                            // setMarkers?.(prev => new Map([...prev?.entries(), [key, marker]]))
                            // setSituationRoomVihecleListMap((prev) => {
                            //     if (prev) {
                            //         console.log("원래 map22 : " , prev)
                            //         return new Map(prev).set(webSocketData.psn, value)
                            //
                            //     }
                            //
                            // })
                        }


                    })
                })

            }






            return () => {
                // console.log("client.deactivate(); 연결 종료")
                // client.current?.deactivate();
                //  console.log("웹소켓 파괴파괴파괴파괴파괴파괴파괴파괴")
                // map?.destroy()

            }

        }
    }, [situationRoomVihecleListMap, markers])

 //   const [pageSize, setPageSize] = useState(20);
    /*
    const {execute: getList, data: terminalRowData} = useAsync2(getTerminal, true)
    const {data: trailerList} = useAsync2(getTrailerList, true, undefined, (data:Array<TerminalRowData>) =>{
        if (data.length> 0) {
            getList(data[0].serialNumber)
        }
    })
    // 트레일러 누르면 그 트레일러에 해당하는 데이터 가져옴? 첫화면에는 첫트레일러 데이터 보여줌
 //   const {execute: getTerminalTable} = useAsync2(getTerminal, true)
//    const data: terminalRowData = getTerminalTable(pageSize)
//    async function get(){
  //      return await getTerminal(pageSize)
 //   }
    const location = useLocation()
    const params = new URLSearchParams(location.search)

    useEffect(() => {
        if (location.search !== "") {
            getList(params.get("serialNumber"))
        }
    }, [location.search])

    useEffect(() => {
        setMap(new naver.maps.Map("map", {
            center: new naver.maps.LatLng(37.3187965288894, 126.833797790159),
            zoom: 13,
        }))
    }, [])



    terminalRowData &&
        terminalRowData.map((terminalRowData,index) => {
           terminalRowData.sendDateTime = terminalRowData.sendDateTime.replace('T'," ")
        })

    const [map, setMap] = useState<naver.maps.Map>()
    const [markers, setMarkers] = useState<naver.maps.Marker[]>([])
    const [infoWindows, setInfoWindows] = useState<naver.maps.InfoWindow[]>([])
    const [searchTrailerList, setSearchTrailerList] = useState<TerminalRowData[]>([])

    const methods = useForm<TerminalRowData>({
              mode: "onBlur",
              criteriaMode: 'all',
          })
    const {watch} = methods
    const {serialNumber} = watch()

     useEffect(() => {
            console.log("시작")
            if(trailerList && serialNumber!==undefined){
                setSearchTrailerList(trailerList.filter((data)=>data.serialNumber.indexOf(serialNumber) !== -1))
                console.log("서치")
            }
        }, [trailerList, serialNumber, situationRoomVihecleList])

*/


    return (
        <>
            <FormProvider {...methods} >
                <SituationDiv>
                    <BrowserView>
                        <CenterWeb>
                            <MapScreen
                                isMobile={false}
                                rowData={vihecleListForMapScreen}
                                map={map}
                                markers={markers}
                                setMarkers={setMarkers}
                                vihecleName={vihecleName}
                                getModalDataList={getModalDataList}
                                situationRoomModalDataList={situationRoomModalDataList}
                                modalIsOpen={modalIsOpen}
                                setModalIsOpen={setModalIsOpen}
                                activeVihecleSerialNo={activeVihecleSerialNo}
                                showInfo={showInfo}
                                setShowInfo={setShowInfo}
                            />
                            <MapVihecleListScreen
                                isMobile={false}
                                situationRoomVihecleList={situationRoomVihecleListMap}
                                setModalIsOpen={setModalIsOpen}
                                map={map}
                                markers={markers}
                                activeVihecleSerialNo={activeVihecleSerialNo}
                                setActiveVihecleSerialNo={setActiveVihecleSerialNo}
                                showInfo={showInfo}
                            />

                        </CenterWeb>
                    </BrowserView>
                    <MobileView>
                        <CenterMobile>
                            <MapScreen

                                rowData={vihecleListForMapScreen}
                                map={map}
                                markers={markers}
                                setMarkers={setMarkers}
                                vihecleName={vihecleName}
                                getModalDataList={getModalDataList}
                                situationRoomModalDataList={situationRoomModalDataList}
                                modalIsOpen={modalIsOpen}
                                setModalIsOpen={setModalIsOpen}
                                activeVihecleSerialNo={activeVihecleSerialNo}
                                setActiveVihecleSerialNo={setActiveVihecleSerialNo}
                                showInfo={showInfo}
                                setShowInfo={setShowInfo}
                            />
                            <MapVihecleListScreen

                                situationRoomVihecleList={situationRoomVihecleListMap}
                                setModalIsOpen={setModalIsOpen}
                                map={map}
                                markers={markers}
                                activeVihecleSerialNo={activeVihecleSerialNo}
                                setActiveVihecleSerialNo={setActiveVihecleSerialNo}
                                showInfo={showInfo}
                            />


                        </CenterMobile>
                    </MobileView>
                </SituationDiv>

            </FormProvider>
                {/*
        {vihecleName!==undefined && situationRoomVihecleList &&
            (situationRoomVihecleList.filter((data)=>data.vihecleName.indexOf(vihecleName) !== -1))?.length}/
        {situationRoomVihecleList?.length} 개


            <EditText
            name="vihecleName"
            ref={methods.register({required: true, minLength: 1})}
            placeholder={"검색어를 입력하세요"}/>

            */}
        {/*
            <MapScreen
                rowData={trailerList}
                map={map}
                markers={markers}
                infoWindows={infoWindows}
                serialNumber={serialNumber}
            />

            {serialNumber!==undefined && trailerList &&
            (trailerList.filter((data)=>data.serialNumber.indexOf(serialNumber) !== -1))?.length}/
            {trailerList?.length} 개

            <EditText
                name="serialNumber"
                ref={methods.register({required: true, minLength: 1})}
                placeholder={"검색어를 입력하세요"}/>

            <TrailerListScreen
                rowData={searchTrailerList}
                map={map}
                markers={markers}
                infoWindows={infoWindows}

            />

            <TerminalTable

                            rowData={terminalRowData}
                        />




            <TerminalScreen
                data={situationRoomVihecleList}

            />
*/}


        </>
    )
}

const EditText = styled(Input)`
    width: 420px;
    height: 48px;
    font-size: 14px;
    padding: 14px 16px 14px 16px;
    align-self: center;
`
const CenterWeb = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: row;  
`

const CenterMobile = styled.div`
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  
  
`

const SituationDiv = styled.div`
  width: 100%;
`

export default SituationRoomContainer
